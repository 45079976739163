import { useEffect } from "react"
import { useHistory } from "react-router"
import { useAnalyticsEvent } from "./api/providers/SegmentProvider/hooks"
import { useWeaverFlags } from "./api/thirdParty/launchDarkly/useWeaverFlags"

export const useInvisiblePageRouterHack = () => {
  const history = useHistory()
  const eBrokenPageInvisible = useAnalyticsEvent("Broken_Page_Invisible")

  const weaverFlags = useWeaverFlags()
  if (!weaverFlags['MW-2141-ionic-shite-router-hack']) return

  /** Heavily inspired by https://forum.ionicframework.com/t/ion-page-invisible-class-not-being-removed-navigating-in-between-pages-video/162114/10 */
  useEffect(() => {
    const unlistener = history.listen(update => {
      setTimeout(() => {

        const invisiblePage = document?.getElementById("main")?.querySelector(".ion-page.ion-page-invisible")
        if (invisiblePage) {
          console.warn('[useInvisiblePageRouterHack] Ion Page remained invisible after route change!', update)

          // Intentionally fire and forget
          eBrokenPageInvisible({
            locationPathname: update.location.pathname,
            locationSearch: update.location.search,
          })

          invisiblePage.classList.remove("ion-page-invisible")
        }

      }, 100)
    })

    return unlistener
  }, [])
}
