import { useFlags } from "launchdarkly-react-client-sdk"
import { useMemo } from "react"
import { z } from "zod"
import { objectKeys } from "../../../common/utils/objects"

/**
 * The schema for the LaunchDarkly flags that might come back.
 */
export const zWeaverFlags = z.object({
  'miscellaneous-menu': z.object({
    enabled: z.boolean(),
  }),
  'minimum-individual-profile': z.object({
    enabled: z.boolean(),
    disableAspect: z.object({
      'require-phone-number': z.boolean().optional(),
    }).optional(),
  }),
  'contractor-profile-budget-preferences': z.object({
    enabled: z.boolean(),
  }),
  'contractor-lead-acceptor': z.object({
    enabled: z.boolean(),
  }),
  'create-project-contractors': z.object({
    // We can't disable this one (as the text is used)
    contractorCount: z.number(),
    title: z.string(),
    description: z.string(),
    originalPrice: z.string(),
    currentPrice: z.string(),
    guaranteeText: z.string(),
  }),
  'project-leads': z.object({
    // We can't disable this one
    showMap: z.boolean(),
  }),
  'tasks': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showProjectList: z.boolean(),
      showChatNextAction: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'files': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showChatMessageFiles: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'contractor-profile-work-history-references': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      buttons: z.object({
        yes: z.object({
          text: z.string(),
          link: z.string().optional(), // Can include the following tokens: ${USER_EMAIL} ${REFERENCE_ID}
          email: z.string().optional(),
        }),
        no: z.object({
          text: z.string(),
          link: z.string().optional(), // Can include the following tokens: ${USER_EMAIL} ${REFERENCE_ID}
          email: z.string().optional(),
        }),
      }),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'contractor-upload-project-photos': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      recommendedPhotosRange: z.string(),
      photosLimit: z.number(),
      confirmationDescription: z.string(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'chat-group': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showChatGroupOptions: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'tab-nav-bar': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'chat-members': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      showChatMembers: z.boolean(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'project-owner-invites': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'task-templates': z.discriminatedUnion("enabled", [
    z.object({
      enabled: z.literal(true),
      createProject: z.array(z.string()).optional(),
    }),
    z.object({
      enabled: z.literal(false),
    }),
  ]),
  'MW-2045-contractor-lead-acceptor-copy-changes': z.object({
    enabled: z.boolean(),
  }),
  'MW-2048-non-weaver-builder-events': z.object({
    enabled: z.boolean(),
  }),
  'MW-2072-include-dynamic-font-on-project-tasks-indicator': z.object({
    enabled: z.boolean(),
  }),
  'MW-2076-change-work-history-modal-on-projects': z.object({
    enabled: z.boolean(),
  }),
  'MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents': z.object({
    enabled: z.boolean(),
  }),
  'MW-2081-project-to-tasks-pages-navigation': z.object({
    enabled: z.boolean(),
  }),
  'MW-2085-ui-updates-to-leads': z.object({
    enabled: z.boolean(),
  }),
  'MW-2085-ui-updates-to-leads-tender-readiness-copy': z.object({
    enabled: z.boolean(),
  }),
  'MW-2085-ui-updates-to-leads-project-lead-by': z.object({
    enabled: z.boolean(),
  }),
  'MW-2023-work-history-reference-preview-doesnt-scroll': z.object({
    enabled: z.boolean(),
  }),
  'MW-2096-multiple-work-histories': z.object({
    enabled: z.boolean(),
  }),
  'MW-2093-construction-dates': z.boolean(),
  'MW-2100-populate-read-at-in-chat-room-message': z.object({
    enabled: z.boolean(),
  }),
  'MW-2105-move-payment-selector': z.boolean(),
  'MW-2105-fix-pay-per-lead-launched-event': z.boolean(),
  'MW-2107-ui-updates-to-lead-list': z.boolean(),
  'MW-2108-update-google-map-zoom': z.boolean(),
  'MW-2114-flip-location-and-budget-prefs': z.boolean(),
  'MW-2115-implement-the-new-messages-line-indicator': z.object({
    enabled: z.boolean(),
  }),
  'MW-2116-copy-updates-to-lead': z.boolean(),
  'MW-1803-ui-update-to-project-list': z.boolean(),
  'MW-2142-push-out-tender-return-date': z.boolean(),
  'MW-2143-add-segment-team-property-budgetRangeBadges': z.boolean(),
  'MW-2122-project-lead-event-updates': z.boolean(),
  'MW-2141-ionic-shite-router-hack': z.boolean(),
  'MW-2131-chat-room-new-messages-not-displayed-on-load': z.boolean(),
  'MW-2155-sort-chat-lists-by-last-message-activity': z.boolean(),
  'MW-2132-create-project-cannot-take-below-minimum': z.boolean(),
  'MW-2159-display-the-unread-messages-indicators': z.boolean(),
  'MW-2161-currency-format-input-field': z.boolean(),
  'MW-2165-styling-changes-to-my-chats-row-element': z.boolean(),
  'MW-2171-new-contractor-profile': z.object({
    enabled: z.boolean(),
  }),
  // Flag Space For ...
  // Tom

  // Chris

  // Dan
  'MW-2215-fix-accept-lead-event-firing': z.boolean(),
  'segment-use-weaver-cdn': z.boolean(),

  // Dermot

  // Diego
  'MW-2168-display-unread-messages-red-dot-on-tab': z.boolean(),
  'MW-2175-basic-empty-state-in-new-chat': z.boolean(),
  // Rowan

})

export type WeaverFlags = z.infer<typeof zWeaverFlags>

export const defaultWeaverFlags: WeaverFlags = {
  'miscellaneous-menu': {
    enabled: false,
  },
  'minimum-individual-profile': {
    enabled: false,
  },
  'contractor-profile-budget-preferences': {
    enabled: false,
  },
  'contractor-lead-acceptor': {
    enabled: false,
  },
  'create-project-contractors': {
    title: "Weaver will match up to 3 Contractors for your project.",
    description: "Weaver recommends a maximum of 3 contractors per tender for the best results.",
    contractorCount: 3,
    originalPrice: "£2,995",
    currentPrice: "FREE!",
    guaranteeText: "We guarantee the quality of our contractor quotes. If they don’t meet your expectations, we will refund your fee.",
  },
  'project-leads': {
    showMap: false,
  },
  'tasks': {
    enabled: false,
  },
  'files': {
    enabled: true,
    showChatMessageFiles: true,
  },
  'contractor-profile-work-history-references': {
    enabled: true,
    buttons: {
      yes: {
        text: 'Complete reference form',
        link: 'https://app.weaver.build/${USER_EMAIL}/${REFERENCE_ID}',
      },
      no: {
        text: "I don't want to provide a reference",
        email: 'support@weaver.build',
      },
    },
  },
  'contractor-upload-project-photos': {
    enabled: false,
  },
  'chat-group': {
    enabled: false,
  },
  'tab-nav-bar': {
    enabled: false,
  },
  'chat-members': {
    enabled: false,
  },
  'project-owner-invites': {
    enabled: false,
  },
  'task-templates': {
    enabled: false,
  },
  'MW-1803-ui-update-to-project-list' : false,
  'MW-2045-contractor-lead-acceptor-copy-changes': {
    enabled: false,
  },
  'MW-2048-non-weaver-builder-events': {
    enabled: false,
  },
  'MW-2072-include-dynamic-font-on-project-tasks-indicator': {
    enabled: false,
  },
  'MW-2076-change-work-history-modal-on-projects': {
    enabled: false,
  },
  'MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents' : {
    enabled: false,
  },
  'MW-2081-project-to-tasks-pages-navigation': {
    enabled: false,
  },
  'MW-2085-ui-updates-to-leads': {
    enabled: false,
  },
  'MW-2085-ui-updates-to-leads-tender-readiness-copy': {
    enabled: false,
  },
  'MW-2085-ui-updates-to-leads-project-lead-by': {
    enabled: false,
  },
  'MW-2023-work-history-reference-preview-doesnt-scroll': {
    enabled: false,
  },
  'MW-2096-multiple-work-histories': {
    enabled: false,
  },
  'MW-2093-construction-dates': false,
  'MW-2100-populate-read-at-in-chat-room-message' : {
    enabled: false,
  },
  'MW-2105-move-payment-selector': false,
  'MW-2105-fix-pay-per-lead-launched-event': false,
  'MW-2107-ui-updates-to-lead-list': false,
  'MW-2108-update-google-map-zoom' : false,
  'MW-2114-flip-location-and-budget-prefs' : false,
  'MW-2115-implement-the-new-messages-line-indicator': {
    enabled: false,
  },
  'MW-2116-copy-updates-to-lead' : false,
  'MW-2142-push-out-tender-return-date' : false,
  'MW-2143-add-segment-team-property-budgetRangeBadges' : false,
  'MW-2122-project-lead-event-updates' : false,
  'MW-2141-ionic-shite-router-hack' : false,
  'MW-2131-chat-room-new-messages-not-displayed-on-load' : false,
  'MW-2155-sort-chat-lists-by-last-message-activity': false,
  'MW-2132-create-project-cannot-take-below-minimum' : false,
  'MW-2159-display-the-unread-messages-indicators': false,
  'MW-2161-currency-format-input-field': false,
  'MW-2165-styling-changes-to-my-chats-row-element': false,
  'MW-2171-new-contractor-profile': { enabled: false },
  // Flag Space For ...
  // Tom

  // Chris

  // Dan
  'MW-2215-fix-accept-lead-event-firing': false,
  'segment-use-weaver-cdn': false,

  // Dermot

  // Diego
  'MW-2168-display-unread-messages-red-dot-on-tab': false,
  'MW-2175-basic-empty-state-in-new-chat': false,
  // Rowan
}

/**
   * A function generator which takes a `zod shape` of an object,
   * along with a `candidate object` that *may* represent that shape,
   * and a `default object` *fully* represents that shape.
   *
   * It then produces an `Array.reduce()` function which iterates over each key in the `zod shape`.
   * If that key's value within the `candidate object` passes that key's zod shape, then it is taken.
   * Otherwise, that key's value from the `default object` is used.
   *
   * The primary use for this is to take the `LaunchDarkly` set of flags and to default only the flags which don't pass validation.
   *
   * @returns `Partial<X>` which should actually be a full `X` (where `X` matches the defaultObject type)
   */
export const defaultInvalidCandidateObjectValues = <T extends z.AnyZodObject, X = z.infer<T>>(zodShape: T, candidateObject: Partial<X>, defaultObject: X) =>
  (acc: Partial<X>, key: keyof X) => {
    const parseResult = zodShape.shape[key].safeParse(candidateObject[key])
    if (parseResult.success) {
      return { ...acc, [key]: candidateObject[key] }
    } else {
      console.error(`[useWeaverFlags.defaultInvalidCandidateObjectValues] Unable to parse the key '${String(key)}' as flags. Defaulting it.`, { error: parseResult.error, candidate: candidateObject[key], default: defaultObject[key] })
      return { ...acc, [key]: defaultObject[key] }
    }
  }

/**
 * Returns a strongly typed set of LaunchDarkly flags, or throws an error.
 * WARNING: Changes to LaunchDarkly config will change this behaviour!
 */
export const useWeaverFlags = (): WeaverFlags => {
  const flags = useFlags()

  const weaverFlags: WeaverFlags = useMemo(() => {
    console.debug('[useWeaverFlags] Processing the LaunchDarkly flags', flags)

    // Cold start support - Default an empty object or undefined state
    if (!flags || Object.keys(flags).length === 0) {
      console.log('[useWeaverFlags] Cold start detected. Using defaultWeaverFlags for now.', flags)
      return defaultWeaverFlags
    }

    // Apply the reducer to the LaunchDarkly flags, evaluating each flag independently
    // It should return a full WeaverFlags object (as we iterate over every potential key and default), but Typescript can't be sure
    const weaverFlagReducer = defaultInvalidCandidateObjectValues(zWeaverFlags, flags, defaultWeaverFlags)
    const defaultedFlags: Partial<WeaverFlags> = objectKeys(zWeaverFlags.shape).reduce(weaverFlagReducer, {})

    // We rerun the parse across the whole object - this really should not fail!
    const parsedFlags = zWeaverFlags.safeParse(defaultedFlags)
    if (!parsedFlags.success) {
      console.error('[useWeaverFlags] CRITICAL! Unable to parse the whole flagset. This should never happen!', parsedFlags.error)
      return defaultWeaverFlags
    }

    return parsedFlags.data
  }, [ flags ])

  console.debug('[useWeaverFlags] Successfully loaded WeaverFlags: ', weaverFlags)
  return {
    ...weaverFlags,
    // Add local flag overrides here
  }
}
