import React, { useEffect, useRef } from 'react'
import ChatMessageBubble from './ChatMessageBubble'
import ChatMessageWithDocumentsBubble from './ChatMessageWithDocumentsBubble'
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso'
import { useChatRoom } from './services/ChatRoomProvider'
import { useMyIndividual } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { useMarkChatRoomMessageAsReadMutation } from '../../../../graphql/generated'

const PACKAGE = 'chatContent'

const ChatContent: React.FC = () => {
  const virtuosoRef = useRef<VirtuosoHandle>(null)
  const { chatRoomMessages, emptyCache } = useChatRoom()
  const individual = useMyIndividual()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const markMessageAsReadMutation = useMarkChatRoomMessageAsReadMutation(gqlDataSource)

  useEffect(() => {
    // The message images cache is clear every time the chat screen is rendered for the first time
    emptyCache()
  }, [])

  useEffect(() => {
    const scrollToLastMessage = () => {
      if (chatRoomMessages !== undefined){
        virtuosoRef.current?.scrollToIndex({ index: chatRoomMessages.length, behavior: 'auto', align: "start" })
      }
    }
    // We need to make sure the last message has rendered on screen before we scroll
    setTimeout(scrollToLastMessage, 1)
  }, [ chatRoomMessages?.length ])

  if (chatRoomMessages === undefined) {
    return <WeaverIonContent>
      <LoadingSpinner name={`${PACKAGE}.undefined`} />
    </WeaverIonContent>
  }

  const onMessageSeen = async (chatRoomMessageId: string | undefined) => {
    if (!chatRoomMessageId) return

    await markMessageAsReadMutation.mutateAsync({ chatRoomMessageId })
  }

  return (
    <WeaverIonContent useFullHeight={true}>
      <Virtuoso
        ref={virtuosoRef}
        style={{ height: "100%" }}
        data={chatRoomMessages}
        itemContent={index => {
          const message = chatRoomMessages[index]
          const wasMessageSentByCurrentUser = message.sentByIndividual.id === individual?.id
          return (
            <>
              {
                message.documentCount
                  ? <ChatMessageWithDocumentsBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} messageHasBeenSeenCallback={onMessageSeen} />
                  : <ChatMessageBubble key={message.idFromClient} {...message} sentByCurrentUser={wasMessageSentByCurrentUser} messageHasBeenSeenCallback={onMessageSeen} />
              }
            </>
          )
        }}
      >
      </Virtuoso>
    </WeaverIonContent>
  )
}

export default ChatContent
