import { IonButton, IonCard, IonCardContent, IonCardTitle, IonIcon, isPlatform } from '@ionic/react'
import React, { useState } from 'react'
import GlobalHeader from '../../common/components/GlobalHeader'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useGraphQLDataSource } from '../../api/graphql'
import { ContentDisposition, UploadedFileStatus, TeamType, useGetProjectDocumentsQuery } from '../../graphql/generated'
import { matchPath, useRouteMatch } from 'react-router'
import { getKnownRoutePathForPage, Page, pageConfig_UploadProjectDocuments, useRouteTo } from '../../routes'
import { useAnalyticsEvent } from '../../api/providers/SegmentProvider/hooks'
import ErrorBlockPage from '../../common/components/ErrorBlock/ErrorBlockPage'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { asyncForEach } from '../../common/utils'
import { downloadUploadedFile } from '../../common/utils/files'
import { alwaysArray } from "../../common/utils"
import { cloudDownloadOutline, cloudUploadOutline } from 'ionicons/icons'
import ProjectDocumentRow from './ProjectDocumentRow'
import { ProjectDocument } from './ProjectDocumentTypes'
import { useQueryClient } from '@tanstack/react-query'
import { useParamsFromPageConfig } from '../../routesProvider'
import { useSearchState } from '../../common/hooks/pages'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'

type GetProjectDocumentsRouteParams = {id: string}
type DocumentsToDownload = {
  [documentId: string]: boolean,
}

type SearchParams = {
  /**
   * HACK: ignoreScope allows a ops (weaver team) member to ignore scoping permissions
   * this will be `"true"` when an ops member is requesting access to a project they are not part of
   */
  ignoreScope?: string,
}

const ProjectDocumentsPage: React.FC = () => {
  const queryClient = useQueryClient()
  const [ searchState ] = useSearchState<SearchParams>({})
  const ignoreScope = searchState.ignoreScope === "true"
  const weaverFlags = useWeaverFlags()
  const knownPath = getKnownRoutePathForPage(Page.ProjectDocuments)
  const routeMatch = useRouteMatch<GetProjectDocumentsRouteParams>()
  const internalRouteMatch = matchPath<GetProjectDocumentsRouteParams>(routeMatch.url, {
    path: getKnownRoutePathForPage(Page.ProjectDocuments),
    exact: true,
    strict: false,
  })

  const goToUploadDocumentsPage = useRouteTo(pageConfig_UploadProjectDocuments.path)

  if (!internalRouteMatch) throw new Error(`[ProjectDocuments] internal route match failed, route provided: ${JSON.stringify(routeMatch)}, attempted path: ${knownPath}, `)
  if (!routeMatch.params.id) {
    console.warn("[ProjectDocuments] required route param was missing. route match:", JSON.stringify(routeMatch), "internally parsed match:", JSON.stringify(internalRouteMatch))
  }
  const { id } = useParamsFromPageConfig<{id: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const getProjectDocuments = useGetProjectDocumentsQuery(gqlDataSource, {
    id,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope,
  }, { refetchOnWindowFocus: false })

  const triggerProjectDocumentDownloaded = useAnalyticsEvent("Project_Document_Downloaded")
  const triggerProjectDocumentDownloadedAll = useAnalyticsEvent("Project_Document_Downloaded_All")
  const myTeam = useMyIndividualActiveTeam()

  const uploadButtonMessage = "You don't have any uploaded documents, add documents by tapping the upload button."
  const [ documentsToDownload, setDocumentsToDownload ] = useState<DocumentsToDownload>({})

  const downloadAllProjectFiles = (listOfAllDocumentFiles: ProjectDocument[]) =>
    asyncForEach(listOfAllDocumentFiles, async (document: ProjectDocument) => {
      const saveToDownloadsFolder = true
      return downloadUploadedFile(document, (hasCompleted: boolean) => {
        setDocumentsToDownload({
          ...documentsToDownload,
          [document.id]: hasCompleted,
        })
      }, saveToDownloadsFolder, weaverFlags['MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents'].enabled)
    })

  const onDownloadAllClicked = () => {
    downloadAllProjectFiles(alwaysArray(documents))

    triggerProjectDocumentDownloadedAll({
      projectId: project.id,
      downloads: documents.map((document) => {
        const { fileName, fileContentType: fileType, fileSizeInBytes } = document
        const hasRequiredFields = fileName && fileType && fileSizeInBytes
        if (!hasRequiredFields) throw new Error(`Failed to report downloadAll, document was missing required fields: ${JSON.stringify(document)}`)
        return ({
          fileName,
          fileType,
          fileSizeInBytes,
        })
      }),
    })
  }

  const navigateToUploadDocumentsPage = () => {
    goToUploadDocumentsPage({ projectId: id })()
  }

  if (getProjectDocuments.isLoading && !getProjectDocuments.data) {
    return <LoadingSpinnerPage name="ProjectDocuments"/>
  }

  if (!myTeam) {
    return <LoadingSpinnerPage name="ProjectDocuments" />
  }

  if (getProjectDocuments.error || !getProjectDocuments.data) {
    return <ErrorBlockPage name='ProjectDocumentsPage' onRefresh={getProjectDocuments.refetch} />
  }

  const canUserUploadFiles = () => myTeam.type !== TeamType.Contractor
  const includeNonArchivedDocuments = (document: ProjectDocument) => document.status !== UploadedFileStatus.Archived

  const { getProject: project } = getProjectDocuments.data

  const documents = alwaysArray(project.documents).filter(includeNonArchivedDocuments)

  const handleDownloadDocument = (document: ProjectDocument) => {
    const { fileName, fileContentType: fileType, fileSizeInBytes } = document
    const hasRequiredFields = fileName && fileType && fileSizeInBytes
    if (!hasRequiredFields) throw new Error(`Failed to report download: Document was missing required fields: ${JSON.stringify(document)}`)

    triggerProjectDocumentDownloaded({
      projectId: project.id,
      fileName,
      fileType,
      fileSizeInBytes,
    })
  }

  const handleArchiveDocument = async () => {
    await queryClient.invalidateQueries(useGetProjectDocumentsQuery.getKey({ id }))
  }

  return (
    <WeaverIonPage id='ProjectDocumentsPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='Documents' />
      </WeaverIonHeader>
      <WeaverIonContent>
        <IonCard>
          <IonCardContent>
            <IonCardTitle>Documents</IonCardTitle>
            { canUserUploadFiles() &&
            <IonButton onClick={() => navigateToUploadDocumentsPage()}><IonIcon icon={cloudUploadOutline} slot="start" />Upload</IonButton>
            }

            {( documents.length > 0 )
              ?<>
                <IonButton onClick={() => onDownloadAllClicked()} hidden={isPlatform('mobileweb')}><IonIcon icon={cloudDownloadOutline} slot="start"/>Download All</IonButton>
                {( documents.map((document) =>
                  <ProjectDocumentRow
                    key={document.id}
                    document={document}
                    isDownloadingAll={documentsToDownload.documentId}
                    onDownload={handleDownloadDocument}
                    onArchived={handleArchiveDocument}
                  />,
                ))}
              </>
              : <IonCardContent>{uploadButtonMessage}</IonCardContent>
            }
          </IonCardContent>
        </IonCard>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default ProjectDocumentsPage
