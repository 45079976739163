import React, { useState } from "react"
import { IonAlert, IonBadge, IonIcon, IonItem } from "@ionic/react"
import { allIonIcons } from "../../../common/components/IonIconTyped/icons"
import { alwaysArray } from "../../../common/utils"
import { moneyToText } from "../../../common/utils/currency"
import { ProgressionStatus, useUpdateContractorProfileWorkHistoryMutation } from "../../../graphql/generated"
import { getProgressionStatusForWorkHistoryItem } from "../ContractorLeadPreferences/ContractorBudgetRanges/datasource"
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import { formatAddressToSingleLine } from "../../../common/utils/addresses"
import { MyContractorProfileScreenWithRenderProps, MyContractorScreenNames } from "./MyContractorProfilePage"
import { useMyContractorProfileScreenState } from "./MyContractorProfileScreenState"
import { useGraphQLDataSource } from "../../../api/graphql"
import { useQueryClient } from '@tanstack/react-query'
import Styles from "./ListWorkHistoryItems.module.scss"

const acceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const ListWorkHistoryItems: React.FC<MyContractorProfileScreenWithRenderProps> = ({ profile, useChangeScreen }) => {
  const {
    workHistoryItemIdState: [ , setWorkHistoryItemId ],
  } = useMyContractorProfileScreenState()

  const queryClient = useQueryClient()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const updateContractorProfileWorkHistory = useUpdateContractorProfileWorkHistoryMutation(gqlDataSource)

  const [ showArchiveWorkHistoryDialog, setShowArchiveWorkHistoryDialog ] = useState<boolean>(false)
  const [ showArchiveWorkHistoryDialogError, setShowArchiveWorkHistoryDialogError ] = useState<boolean>(false)

  const changeScreenToWorkHistory = useChangeScreen(MyContractorScreenNames.WorkHistory)
  const showContractorWorkHistoryItem = (workHistoryItemId: string) => {
    setWorkHistoryItemId(workHistoryItemId)
    changeScreenToWorkHistory()
  }

  const filteredNonArchivedWorkHistory = alwaysArray(profile.workHistory)
    .filter(workHistoryItem => !workHistoryItem.isArchived)

  const acceptableWorkHistory = filteredNonArchivedWorkHistory
    .filter(workHistoryItem => acceptableStatuses.includes(getProgressionStatusForWorkHistoryItem(workHistoryItem)))

  const handleArchiveWorkHistoryDialogOpen = (event: React.MouseEvent<HTMLIonIconElement, MouseEvent> ) => {
    // Parent component IonItem also has an onClick handler, this prevents bubbling
    event.stopPropagation()
    setShowArchiveWorkHistoryDialog(true)
  }

  const handleConfirmArchiveWorkHistoryItem = async (workHistoryId: string) => {
    await updateContractorProfileWorkHistory.mutateAsync({ input: { id: workHistoryId, isArchived: true } }, {
      onSuccess: () => {
        queryClient.invalidateQueries([ 'getContractorProfile' ])
        setShowArchiveWorkHistoryDialog(false)
      },
      onError: () => {
        console.debug(`[ListWorkHistoryItems] Failed to archive work history item ${workHistoryId}`)
        setShowArchiveWorkHistoryDialog(false)
        setShowArchiveWorkHistoryDialogError(true)
      },
    })
  }

  const ARCHIVE_WORK_HISTORY_ERROR_MESSAGE = "Oops sorry there was a problem <br> Please try again"

  return acceptableWorkHistory.length > 0
    ? <div className={Styles.contractorWorkHistoryContainer}>
      <h3>Work History</h3>
      {acceptableWorkHistory.map(workHistoryItem =>
        <IonItem
          key={workHistoryItem.id}
          button
          lines="none"
          detail={false}
          onClick={() => showContractorWorkHistoryItem(workHistoryItem.id)}
        >
          <IonIcon icon={allIonIcons.homeOutline} className={Styles.homeIcon} />
          <div className={Styles.workHistoryTextContainer}>
            {workHistoryItem.projectAddress && <p className={Styles.projectAddress}>{formatAddressToSingleLine(workHistoryItem.projectAddress)}</p>}
            <p key="constructionValue" className={Styles.costOfWorkText}>Cost of Work: {moneyToText(workHistoryItem.constructionValue, { withPennies: true })}</p>
            <p key="projectTypes">
              {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
            </p>
          </div>
          <IonIcon icon={allIonIcons.trashOutline} className={Styles.archiveIcon} onClick={handleArchiveWorkHistoryDialogOpen}/>
          <IonAlert
            cssClass={Styles.archiveAlertDialogContainer}
            isOpen={showArchiveWorkHistoryDialog}
            onDidDismiss={() => setShowArchiveWorkHistoryDialog(false)}
            header="Delete work history"
            message={`Are you sure you want to delete work history "${workHistoryItem.projectAddress?.addressLine1 ?? ""}"? <br> This action cannot be undone`
            }
            buttons={[
              {
                text: 'Cancel',
                role: 'dismiss',
                handler: () => {
                  setShowArchiveWorkHistoryDialog(false)
                },
              },
              {
                text: 'OK',
                role: 'destructive',
                handler: () => {
                  handleConfirmArchiveWorkHistoryItem(workHistoryItem.id)
                },
              },
            ]}
          ></IonAlert>
          <IonAlert
            cssClass={Styles.archiveAlertDialogContainer}
            isOpen={showArchiveWorkHistoryDialogError}
            onDidDismiss={() => setShowArchiveWorkHistoryDialogError(false)}
            message={ARCHIVE_WORK_HISTORY_ERROR_MESSAGE}
            buttons={[
              {
                text: 'OK',
                role: 'dismiss',
                handler: () => {
                  setShowArchiveWorkHistoryDialogError(false)
                },
              },
            ]}
          ></IonAlert>
        </IonItem>,
      )}
    </div>
    :  <div className={Styles.workHistoryTextContainer}>
      <h3>Work History</h3>
      <p className={Styles.noWorkHistoryText}>No work history has been added</p>
    </div>
}

export default ListWorkHistoryItems
