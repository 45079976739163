import React from 'react'
import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'
import { Browser } from '@capacitor/browser'
import { TAuth_LoggedIn, TAuth_LoggedOut } from '.'
import { isMobile } from '../../../common/utils/ionic'
import startWindowCloseInterval from './startWindowCloseInterval'

const PACKAGE = 'Auth0Provider.helpers'
/**
 * This value is sourced from '@auth0/auth0-spa-js' specifically @auth0/auth0-spa-js/src/utils.ts and
 * it represents the newly opened window's target context. You can check this comment for more info:
 * https://bitbucket.org/weaver-tech/myweaver-breeze/pull-requests/492#comment-349150668
 * 
 * At the time of writing this we could not find anything that relies on this exact string, but
 * a newer version of the auth0 sdk could introduce a breaking change. The current version that we use
 * is 1.20.1
 */
const AUTH0_AUTHORIZE_TARGET = "auth0:authorize:popup"

type BuildLoggedOutAuthStateProps = {
  auth0Client: Auth0Client,
  auth0ClientOptions: Auth0ClientOptions,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
export const buildLoggedOutAuthState = async ({ auth0Client, auth0ClientOptions, setAuthState }: BuildLoggedOutAuthStateProps): Promise<TAuth_LoggedOut> => ({
  engine: 'auth0',
  isLoggedIn: false,
  login: async () => {
    if (isMobile) {
      // Redirect to Auth0 as part of the Authorization Code Flow
      // Later, it will redirect back to this app, based on the Auth0ClientConfig settings
      return auth0Client.buildAuthorizeUrl()
        .then(url => Browser.open({ url, windowName: "_self" }))
    } else {
      // Define a custom popup
      const popup = window.open(
        '',
        AUTH0_AUTHORIZE_TARGET,
        'left=100,top=100,width=400,height=600,resizable,scrollbars=yes,status=1',
      )
      // Use the loginWithPopup Auth0Client approach (popup window)
      // Then update the logged in state
      try {
        await auth0Client.loginWithPopup({}, { popup })
        const loggedInState = await buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState })
        startWindowCloseInterval({ windowProxyRef: popup })
        setAuthState(loggedInState)
      } catch (err) {
        // If the popup throws an error we have found that the window
        // does not close, even if a successful login takes place.
        // By calling startWindowCloseTimer on error we ensure users
        // never become stuck on a blank screen.
        startWindowCloseInterval({ windowProxyRef: popup })
        console.error(err)
      }
      return
    }
  },
})

type BuildLoggedInAuthStateProps = {
  auth0Client: Auth0Client,
  auth0ClientOptions: Auth0ClientOptions,
  setAuthState: React.Dispatch<React.SetStateAction<TAuth_LoggedOut | TAuth_LoggedIn | undefined>>,
}
export const buildLoggedInAuthState = async ({ auth0Client, auth0ClientOptions, setAuthState }: BuildLoggedInAuthStateProps): Promise<TAuth_LoggedIn> => {
  const userData = await auth0Client.getUser()
  const gravatar = userData?.picture
  const accessToken = await auth0Client.getTokenSilently()
  const idToken = await auth0Client.getIdTokenClaims()
  console.debug(`[${PACKAGE}.buildLoggedInAuthState] Logged in auth state `, { auth0Client, userData, gravatar, accessToken, idToken })
  return {
    engine: 'auth0',
    isLoggedIn: true,
    logout: async () => {
      await Browser.open({
        url: auth0Client.buildLogoutUrl({
          returnTo: isMobile ? auth0ClientOptions.redirect_uri : window.location.origin,
        }),
        windowName: isMobile ? undefined : "_self",
      })
      await auth0Client.logout({ localOnly: true })
    },
    forceRefresh: async () => {
      try {
        console.debug(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] Refreshing... `, { auth0Client, userData, gravatar, accessToken, idToken })
        const newToken = await auth0Client.getTokenSilently({ ignoreCache: true })
        console.debug(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] Setting new auth state: `, { newToken })
        const updatedAuthState = await buildLoggedInAuthState({ auth0Client, auth0ClientOptions, setAuthState })
        console.debug(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] Updated auth state: `, { newToken, updatedAuthState })
        setAuthState(updatedAuthState)
      } catch (e) {
        // This is likely from Error: Login required
        // Potential causes: https://community.auth0.com/t/login-required-error-while-trying-to-get-authtoken-for-registered-api/45581/3
        // For now, we're going to just tell the window to do a hard reload (like clicking the refresh button)
        console.error(`[${PACKAGE}.buildLoggedInAuthState.forceRefresh] It did not work. `, e)
        window.location.reload()
      }
    },
    userData,
    gravatar,
    accessToken,
    idToken,
  }
}
