import React from 'react'
import { BudgetRange, ContractorProfileBudgetRangeBadge, Maybe } from '../../../../../graphql/generated'
import { PadLock } from '../../ContractorBudgetReferences/PadLock'
import { BadgeLevel, getLockedBadges, migrateBadgeLevels } from './BadgeUtils'
import { getBadgesInBudgetRange } from './ContractorProfileBudgetRangeBadge/hooks'
import Styles from './BadgesRow.module.scss'
import RangeBadgeLevel from '../../ContractorBudgetReferences/RangeBadgeLevel'

type BadgeRowProps = {
  budgetRange: BudgetRange,
  budgetRangeBadgeData?: Array<Partial<ContractorProfileBudgetRangeBadge>> | undefined | null,
}

export const BadgesRow: React.FC<BadgeRowProps> = ({ budgetRange, budgetRangeBadgeData }) => {
  const unlockedBadges = getBadgesInBudgetRange(budgetRangeBadgeData, budgetRange)
  const unlockedBadgesAfterMigration = migrateBadgeLevels(unlockedBadges)
  const lockedBadges = getLockedBadges(unlockedBadgesAfterMigration)

  return (
    <div className={Styles.badgeContainer}>
      { unlockedBadgesAfterMigration.map((badge) => (
        <RangeBadgeLevel key={badge}
          {...{ width: '40', height: '50' }}
          badgeLevel={badge ?? BadgeLevel.Bronze} budgetRange={budgetRange} />
      ))}

      {lockedBadges?.map((badge) => (
        <div key={badge}>
          <div>
            <PadLock {...{ width: '40', height: '50' }} isBadgeRowElement={true} />
          </div>
        </div>
      ))}
    </div>
  )
}
