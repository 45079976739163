import React from 'react'
import chatDefaultAvatar from './chat-default-avatar.png'
import Styles from "./MyChats.module.scss"
import { ensureTrailingSlash } from '../../../common/utils'
import { useRouteMatch } from 'react-router'
import { IonBadge, IonIcon, IonItem, useIonRouter } from '@ionic/react'
import { ChatRoom, ChatRoomMessage } from '../../../graphql/generated'
import { formatDateAsDuration } from '../../../common/utils/date'
import { useLastMessage } from './useLastMessage'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { chatboxEllipsesOutline } from 'ionicons/icons'

const Avatar: React.FC = () => {
  return <img className={Styles.chatRowAvatar} src={chatDefaultAvatar} alt="chatAvatar" />
}

export type ChatRoomMessageProps = {
  content: ChatRoomMessage['content'],
  sentAt: ChatRoomMessage['sentAt'],
}

const LastMessagePreview: React.FC<ChatRoomMessageProps> = ({ content }) => {
  return <div className={Styles.chatLastMessagePreview}>{content}</div>
}

const LastMessageSendDate: React.FC<ChatRoomMessageProps> = ({ sentAt }) => {
  const formattedDate = formatDateAsDuration(sentAt)
  return <div className={Styles.chatDate}>{formattedDate} </div>
}

/* @deprecated */
const DeprecatedFormattedLastMessageSentAt: React.FC<{ lastMessageSentAt: string }> = ({ lastMessageSentAt }) => {
  const formattedDate = formatDateAsDuration(lastMessageSentAt)
  return <div className={Styles.chatDate}>{formattedDate}</div>
}

const FormattedLastMessageSentAt: React.FC<{ lastMessageSentAt: string, isForChatWithUnreadMessages?: boolean }> = ({ lastMessageSentAt, isForChatWithUnreadMessages = false }) => {
  const formattedDate = formatDateAsDuration(lastMessageSentAt)
  return <div className={`${Styles.newChatDate} ${isForChatWithUnreadMessages && Styles.newChatDateForChatWithUnreadmessages}`}>{formattedDate} </div>
}

type ChatRoomNameProps = {
  name: ChatRoom['name'],
}

const ChatRoomName: React.FC<ChatRoomNameProps> = ({ name }) => {
  return (
    <div className={Styles.chatName}>
      {name}
    </div>
  )
}

/* @deprecated */
type MyChatsRowPropsDeprecated = {
  id: ChatRoom['id'],
  messages: ChatRoomMessageProps[],
}

/* @deprecated */
export const MyChatsRowDeprecated: React.FC<MyChatsRowPropsDeprecated & ChatRoomNameProps> = ({ id, name, messages }) => {
  const match = useRouteMatch()
  const ionRouter = useIonRouter()
  const lastMessage = useLastMessage(messages)

  const goToChat = (event: React.MouseEvent) => {
    event.preventDefault()
    const newLocation = `${ensureTrailingSlash(match.url)}${id}`
    ionRouter.push(newLocation)
  }

  return (
    <div className={Styles.chatRow}>
      <Avatar />
      <IonItem className={Styles.chatContentContainer} lines="none" onClick={goToChat}>
        <div className={Styles.chatContent}>
          <div className={Styles.chatTopContent}>
            <ChatRoomName name={name} />
            {lastMessage && <LastMessageSendDate {...lastMessage} />}
          </div>
          {lastMessage && <LastMessagePreview {...lastMessage} />}
        </div>
      </IonItem>
    </div>
  )
}

type MyChatsRowProps = {
  id: ChatRoom['id'],
  name: ChatRoom['name'],
  lastMessageAt: ChatRoom['lastMessageAt'],
  lastMessageContent?: ChatRoom['_lastMessageContent'],
  myChatRoomStats?: ChatRoom['myChatRoomStats'],
}

export const MyChatsRow: React.FC<MyChatsRowProps> = ({ id, name, lastMessageContent, lastMessageAt, myChatRoomStats }) => {
  const match = useRouteMatch()
  const ionRouter = useIonRouter()
  const weaverFlags = useWeaverFlags()

  const goToChat = (event: React.MouseEvent) => {
    event.preventDefault()
    const newLocation = `${ensureTrailingSlash(match.url)}${id}`
    ionRouter.push(newLocation)
  }
  const areUnreadMessagesInChatRoom = myChatRoomStats?.unreadMessagesCount !== 0

  if (weaverFlags['MW-2159-display-the-unread-messages-indicators']){
    if (weaverFlags['MW-2165-styling-changes-to-my-chats-row-element']){
      return (
        <div className={Styles.newChatRow}>
          <div className={Styles.circleForIcon}>
            <IonIcon icon={chatboxEllipsesOutline} size='large'/>
          </div>
          <IonItem lines="none" onClick={goToChat}>
            <div className={Styles.newChatContent}>
              <div className={Styles.newChatTopContent}>
                <div className={Styles.newChatName}>{name}</div>
                {lastMessageAt && <FormattedLastMessageSentAt lastMessageSentAt={lastMessageAt} isForChatWithUnreadMessages={areUnreadMessagesInChatRoom} />}
              </div>
              <div className={Styles.newChatBottomContent}>
                <div className={Styles.newChatLastMessagePreview}>{lastMessageContent}</div>
                { areUnreadMessagesInChatRoom &&
                <div className={Styles.newUnreadMessagesCircle}>{myChatRoomStats?.unreadMessagesCount}</div>
                }
              </div>
            </div>
          </IonItem>
        </div>
      )
    }
    /** @deprecated - if modified, keep in sync with the weaverFlags['MW-2165-styling-changes-to-my-chats-row-element'] result above */
    return (
      <div className={Styles.chatRow}>
        <Avatar />
        <IonItem className={Styles.chatContentContainer} lines="none" onClick={goToChat}>
          <div className={Styles.chatContent}>
            <div className={Styles.chatTopContent}>
              <div className={Styles.chatName}>{name}</div>
              {lastMessageAt && <DeprecatedFormattedLastMessageSentAt lastMessageSentAt={lastMessageAt} />}
            </div>
            <div className={Styles.chatBottomContent}>
              <div className={Styles.chatLastMessagePreview}>{lastMessageContent}</div>
              { myChatRoomStats?.unreadMessagesCount !== 0 &&
                <IonBadge className={Styles.unreadMessagesCircle} slot="end">{myChatRoomStats?.unreadMessagesCount}</IonBadge>
              }
            </div>
          </div>
        </IonItem>
      </div>
    )
  }

  return (
    <div className={Styles.chatRow}>
      <Avatar />
      <IonItem className={Styles.chatContentContainer} lines="none" onClick={goToChat}>
        <div className={Styles.chatContent}>
          <div className={Styles.chatTopContent}>
            <ChatRoomName name={name} />
            {lastMessageAt && <DeprecatedFormattedLastMessageSentAt lastMessageSentAt={lastMessageAt} />}
          </div>
          {lastMessageContent && <div className={Styles.chatLastMessagePreview}>{lastMessageContent}</div>}
        </div>
      </IonItem>
    </div>
  )
}

