import React from 'react'
import {
  IonApp,
  IonRouterOutlet,
  IonTabBar,
  IonTabs,
  setupIonicReact,
} from '@ionic/react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { IonReactRouter } from '@ionic/react-router'
import { useRouterRoutes, useRouterTabButtons, useRouterTabNavBar } from "./routes"
import Styles from './App.module.scss'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import Bootstrapper from './init/Bootstrapper'
import Menu from './common/components/Menu'
import AppUrlListener from './common/AppUrlListener'
import { useMyIndividualActiveTeam } from './api/providers/MyIndividualProvider/MyIndividualProvider'
import SegmentProvider from './api/providers/SegmentProvider'
import { InviteClaimer } from './common/components/InviteClaimer'
import EnvironmentProvider, { useEnvironmentContext } from './api/providers/EnvironmentProvider'
import { useSegmentPageChange } from './api/providers/SegmentProvider/hooks'
import { useWeaverFlags } from './api/thirdParty/launchDarkly/useWeaverFlags'

setupIonicReact()

const BootstrappedApp: React.FC = () => {
  const myTeam = useMyIndividualActiveTeam()

  const weaverFlags = useWeaverFlags()
  const weaverFlagsTabNavBar = weaverFlags['tab-nav-bar']

  useSegmentPageChange()

  const routerRoutes = useRouterRoutes(myTeam)
  const routerTabNavBar = useRouterTabNavBar(myTeam)
  const routerTabButtons = useRouterTabButtons(myTeam)

  return <>
    <IonApp>
      <InviteClaimer />
      <AppUrlListener />
      <Menu />
      {weaverFlagsTabNavBar.enabled
        ? <>
          <IonRouterOutlet key="app" id="main" className={Styles.ionRouterOutlet}>
            {routerRoutes}
          </IonRouterOutlet>
          {routerTabNavBar}
        </>
        : <IonTabs>
          <IonRouterOutlet key="app" id="main">
            {routerRoutes}
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            {routerTabButtons}
          </IonTabBar>
        </IonTabs>
      }
    </IonApp>
    <div id='googleMapsAttribution'></div>
  </>

}

const App: React.FC = () =>
  <SegmentProvider>
    <IonReactRouter>
      <Bootstrapper>
        <BootstrappedApp />
      </Bootstrapper>
    </IonReactRouter>
  </SegmentProvider>

const AppWithLaunchDarkly: React.FC = () => {
  const environment = useEnvironmentContext()

  const WithLDProvider = withLDProvider({
    clientSideID: environment.integrations.launchDarkly.clientSideId,
    reactOptions: {
      useCamelCaseFlagKeys: false,
    },
  })(App)

  return <WithLDProvider />
}

const AppWithEnvironmentAndLaunchDarkly: React.FC = () =>
  <EnvironmentProvider>
    <AppWithLaunchDarkly />
  </EnvironmentProvider>

export default AppWithEnvironmentAndLaunchDarkly
