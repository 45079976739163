import { IonItem, IonLabel } from '@ionic/react'
import React, { useState } from 'react'
import ProjectAvatar from './ProjectAvatar'
import Styles from "./ShowProjectPage.module.scss"
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ShowProjectInvite } from './ShowProjectTypes'
import { capitalizeFirstCharacter } from '../../common/utils/string'
import { PublicProfileModal } from '../profile/PublicProfileModal'
import UnclaimedInviteFakeProfileModal from './UnclaimedInviteFakeProfileModal/UnclaimedInviteFakeProfileModal'
import { teamTypeLabels } from '../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n'
import { pageConfig_Profile_Public, useRouteTo } from '../../routes'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'

type ProjectInviteRowProps = {
  invite: ShowProjectInvite,
}

const ProjectInviteRow: React.FC<ProjectInviteRowProps> = ({ invite }) => {
  const myTeam = useMyIndividualActiveTeam()
  const goToMemberProfile = useRouteTo(pageConfig_Profile_Public.path)
  const weaverFlags = useWeaverFlags()

  const [ showProfileModal, setShowProfileModal ] = useState<boolean>(false)

  if (!myTeam) throw new Error("Can't render this component without an active team")

  const companyName = invite.team?.name || invite.companyName || teamTypeLabels[invite.requiredTeamType]
  const claimedInviteTeam = invite.team ? invite.team : null

  const handleClick = () => {
    if ((weaverFlags['MW-2076-change-work-history-modal-on-projects'].enabled && weaverFlags['tab-nav-bar'].enabled)){
      claimedInviteTeam
        ? goToMemberProfile({ teamType: claimedInviteTeam.type, teamId: claimedInviteTeam.id })()
        : setShowProfileModal(true)
    } else {
      setShowProfileModal(true)
    }
  }

  return (
    <>
      <IonItem className={Styles.projectMemberItem} lines="full">
        <ProjectAvatar slot="start" title={companyName} />
        <div onClick={handleClick}>
          <IonLabel className={Styles.projectMemberLabel} >
            <p>{companyName}</p>
          </IonLabel>
          <IonLabel className={Styles.projectMemberType}>
            <p>Invited: {capitalizeFirstCharacter(invite.requiredTeamType)}</p>
          </IonLabel>
        </div>
      </IonItem>
      {claimedInviteTeam && <PublicProfileModal
        team={claimedInviteTeam}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showProfileModal}
        canDismiss={true}
        onDidDismiss={() => setShowProfileModal(false)}
      />}
      {!claimedInviteTeam && <UnclaimedInviteFakeProfileModal
        invite={invite}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showProfileModal}
        canDismiss={true}
        onDidDismiss={() => setShowProfileModal(false)}
      />}
    </>
  )
}

export default ProjectInviteRow
