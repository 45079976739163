
import React, { useCallback, useEffect, useState } from "react"
import { useIonRouter } from "@ionic/react"
import { useScreensWithProps } from "../../../common/hooks/useScreens"
import { BudgetRange, Lead, useAcceptLeadMutation, useGetStripeProductConfigsQuery } from "../../../graphql/generated"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { moneyToText } from "../../../common/utils/currency"
import { budgetRangeLabels } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges.i18n"
import { budgetRangeLeadFeeStripeProduct, budgetRangeMonthlySubscriptionStripeProduct } from "../../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { useGraphQLDataSource } from "../../../api/graphql"
import MembershipPayPerLead from "./MembershipPayPerLead"
import SelectMembershipPlan from "./SelectMembershipPlan"
import useStripePaymentForLeadPaymentHandler from "../../../api/thirdParty/stripe/useStripePaymentHandler"
import WeaverIonPage from "../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import LoadingSpinnerPage from "../../../common/components/LoadingSpinner/LoadingSpinnerPage"
import { firstElement } from "../../../common/utils/codeReadability"
import { alwaysArray } from "../../../common/utils"
import { pageConfig_ShowProject, useRouteTo } from "../../../routes"
import BrilliantScreen from "../../../common/components/BrilliantScreen/BrilliantScreen"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { DateTime } from "luxon"
import { getWorkStartEstimateLabels } from "../workEstimate.i18n"
import { getFlexibleProjectBudgetAsText } from "./../common"

enum LeadAcceptanceScreens {
  SelectMembershipPlan = "SelectMembershipPlan",
  BuyMembership = "BuyMembership",
  BuyLead = "BuyLead",
  AcceptLead = "AcceptLead", // Don't request payment
}

type ContractorLeadAcceptorProps = {
  lead: Pick<Lead, 'id' | 'workStartEstimate' | 'title' | 'description' | 'tenderReturnDate' | 'projectTypes' | 'budgetCategory' | 'budgetValue'>,
  budgetRange: BudgetRange,
  /** leads for which the user already has a subscription do not require payment */
  hasBudgetRangeSubscription?: boolean,
  /** leads created with ReferralType.ProjectOwner do not require payment */
  isProjectOwnerReferral?: boolean,
}

export enum FeeOption { Subscription, PerLead }

const ContractorLeadAcceptorPage: React.FC<ContractorLeadAcceptorProps> = ({ lead, budgetRange, hasBudgetRangeSubscription = false, isProjectOwnerReferral = false }) => {
  const weaverFlags = useWeaverFlags()

  const paymentNotNeeded = !weaverFlags["contractor-lead-acceptor"].enabled || hasBudgetRangeSubscription || isProjectOwnerReferral

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getStripeProductConfigsQuery = useGetStripeProductConfigsQuery(gqlDataSource, {}, {
    staleTime: 24 * 60 * 60 * 1000, // 1 day
  })

  const stripeProductLeadFee = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .filter(each => each.product === budgetRangeLeadFeeStripeProduct[budgetRange])
    .find(firstElement)
  const stripeProductMonthlySubscription = alwaysArray(getStripeProductConfigsQuery.data?.getStripeProductConfigs)
    .filter(each => each.product === budgetRangeMonthlySubscriptionStripeProduct[budgetRange])
    .find(firstElement)

  const isLoading = !getStripeProductConfigsQuery.data || !stripeProductLeadFee

  type ScreenProps = {
    budgetRange: typeof budgetRange,
    stripeProductLeadFee: typeof stripeProductLeadFee,
    stripeProductMonthlySubscription: typeof stripeProductMonthlySubscription,
    weaverFlags: typeof weaverFlags,
  }
  const [ Screen, activeScreen ] = useScreensWithProps<LeadAcceptanceScreens, ScreenProps>({
    init: () => paymentNotNeeded ? LeadAcceptanceScreens.AcceptLead : LeadAcceptanceScreens.SelectMembershipPlan,
    screenProps: {
      budgetRange,
      stripeProductLeadFee,
      stripeProductMonthlySubscription,
      weaverFlags,
    },
    screens: {

      SelectMembershipPlan: {
        render: useCallback(props => {
          const triggerEvent_Lead_PaymentPlanPicker_Viewed = useAnalyticsEvent("Lead_PaymentPlanPicker_Viewed")
          useEffect(() => {
            if (!weaverFlags['MW-2105-move-payment-selector']) return

            triggerEvent_Lead_PaymentPlanPicker_Viewed({
              projectId: lead.id,
              budgetRange: budgetRange,
            })
          }, [ weaverFlags['MW-2105-move-payment-selector'] ])

          const changeScreenToBuyMembership = props.useChangeScreen(LeadAcceptanceScreens.BuyMembership)
          const changeScreenToBuyLead = props.useChangeScreen(LeadAcceptanceScreens.BuyLead)

          const handleNextClick = (selected: FeeOption | undefined) => {
            if (selected !== undefined) {
              return selected === FeeOption.Subscription ? changeScreenToBuyMembership() : changeScreenToBuyLead()
            }
          }

          return <SelectMembershipPlan
            budgetRange={props.budgetRange}
            budgetRangeLabel={budgetRangeLabels[props.budgetRange]}
            budgetRangeLeadFee={moneyToText(props.stripeProductLeadFee?.price)}
            budgetRangeMonthlySubscriptionFee={moneyToText(props.stripeProductMonthlySubscription?.price)}
            handleNextClick={handleNextClick}
          />
        }, []),
      },

      BuyMembership: {
        render: useCallback(props => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const product = budgetRangeMonthlySubscriptionStripeProduct[props.budgetRange]
          const LeadPaymentLoading = useAnalyticsEvent("Lead_Payment_CheckoutSession_Loading")
          const LeadPaymentReady = useAnalyticsEvent("Lead_Payment_CheckoutSession_Ready")
          const LeadPaymentLaunched = useAnalyticsEvent("Lead_Payment_CheckoutSession_Launched")
          const isSubscriptionFee = true

          const { makeStripePayment, isReady, checkoutSessionId, checkoutSessionUrl } = useStripePaymentForLeadPaymentHandler({
            product,
            leadId: lead.id,
            onPaymentLoading: ({ leadId }) => LeadPaymentLoading({ isSubscription: isSubscriptionFee, projectId: leadId }),
            onPaymentReady: ({ leadId, checkoutSessionId }) => LeadPaymentReady({ isSubscription: isSubscriptionFee, projectId: leadId, checkoutSessionId }),
          })

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)

          const onButtonClick = async () => {
            if (!isPaymentInProgress) {
              await LeadPaymentLaunched({
                projectId: lead.id,
                checkoutSessionId,
                budget: lead.budgetValue,
                budgetRange: props.budgetRange,
                isSubscription: isSubscriptionFee,
                stripePriceId: props.stripeProductMonthlySubscription?.priceId,
              })

              setIsPaymentInProgress(true)
              makeStripePayment()
            }
          }

          const subscriptionPerksList = weaverFlags['MW-2045-contractor-lead-acceptor-copy-changes'].enabled
            ? [
              `Receive monthly leads in the ${budgetRangeLabels[props.budgetRange]} range (expect more than 2 monthly depending on seasonality)`,
              "2-week free trial (charged from week 3)",
              "Cancel at any time",
              "No further fees when you win a tender",
              "Weaver tenderers limited to 3",
              "Each lead comes with: vetted homeowners, design information, tender readiness checked",
              "Dedicated Customer Manager to help your business obtain the best leads",
            ]
            : [ `Receive leads in the ${budgetRangeLabels[props.budgetRange]} budget`,
              "Free to tender",
              "Satisfaction Money back guarantee!*",
              "Cancel your subscription at any time!*",
              "No further fees even if you win a tender",
            ]

          const title = weaverFlags['MW-2045-contractor-lead-acceptor-copy-changes'].enabled
            ? `Subscription`
            : `Get unlimited ${budgetRangeLabels[props.budgetRange]} leads`

          const paymentFee = moneyToText(props.stripeProductMonthlySubscription?.price)

          return <MembershipPayPerLead
            title={title}
            list={subscriptionPerksList}
            budgetRange={budgetRangeLabels[props.budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={isSubscriptionFee}
            isReady={isReady}
            inProgress={isPaymentInProgress}
            fallbackUrl={checkoutSessionUrl}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, []),
      },

      BuyLead: {
        render: useCallback(props => {
          const router = useIonRouter()
          const goBack = () => router.goBack()

          const product = budgetRangeLeadFeeStripeProduct[props.budgetRange]
          const LeadPaymentLoading = useAnalyticsEvent("Lead_Payment_CheckoutSession_Loading")
          const LeadPaymentReady = useAnalyticsEvent("Lead_Payment_CheckoutSession_Ready")
          const LeadPaymentLaunched = useAnalyticsEvent("Lead_Payment_CheckoutSession_Launched")
          const isSubscriptionFee = false

          const { makeStripePayment, isReady, checkoutSessionId, checkoutSessionUrl } = useStripePaymentForLeadPaymentHandler({
            product,
            leadId: lead.id,
            onPaymentLoading: ({ leadId }) => LeadPaymentLoading({ isSubscription: isSubscriptionFee, projectId: leadId }),
            onPaymentReady: ({ leadId, checkoutSessionId }) => LeadPaymentReady({ isSubscription: isSubscriptionFee, projectId: leadId, checkoutSessionId }),
          })

          const [ isPaymentInProgress, setIsPaymentInProgress ] = useState(false)

          const onButtonClick = async () => {
            if (!isPaymentInProgress) {
              if (weaverFlags['MW-2105-fix-pay-per-lead-launched-event']) {
                await LeadPaymentLaunched({
                  projectId: lead.id,
                  checkoutSessionId,
                  budget: lead.budgetValue,
                  budgetRange: props.budgetRange,
                  isSubscription: isSubscriptionFee,
                  stripePriceId: props.stripeProductMonthlySubscription?.priceId,
                })
              }

              setIsPaymentInProgress(true)
              makeStripePayment()
            }
          }

          const payPerLeadPerksList = weaverFlags['MW-2045-contractor-lead-acceptor-copy-changes'].enabled
            ? [
              "One lead only",
              "Charged when the homeowner accepts your profile",
              "Satisfaction money-back guarantee",
              "No further fees when you win the tender",
              "Weaver tenderers limited to 3",
              "Each lead comes with: vetted homeowners, design information, tender readiness checked",
            ]
            : [
              "Free to tender",
              "Satisfaction Money back guarantee!*",
              "No monthly commitment",
              "No further fees even if you win a tender",
            ]

          const paymentFee = moneyToText(props.stripeProductLeadFee?.price)

          return <MembershipPayPerLead
            title={"Pay per lead"}
            list={payPerLeadPerksList}
            budgetRange={budgetRangeLabels[props.budgetRange]}
            paymentFee={paymentFee}
            isSubscriptionFee={isSubscriptionFee}
            isReady={isReady}
            inProgress={isPaymentInProgress}
            fallbackUrl={checkoutSessionUrl}
            navigateTo={onButtonClick}
            goBack={goBack}
          />
        }, []),
      },

      /**
      * This component only renders for NWB or a builder with a subscription
      * Otherwise we use the <DisplayContractorLeadAcceptorStripeCallbackPage />
      */
      AcceptLead: {
        render: useCallback(() => {
          const goToShowProject = useRouteTo(pageConfig_ShowProject.path)
          const myTeam = useMyIndividualActiveTeam()
          const triggerEvent_Project_Lead_Responded_Accepted = useAnalyticsEvent("Project_Lead_Responded_Accepted")

          const gqlDataSource = useGraphQLDataSource({ api: 'core' })
          const acceptLead = useAcceptLeadMutation(gqlDataSource)

          return <>
            <WeaverIonContent>
              <BrilliantScreen
                title='Amazing!'
                subtitle='You have accepted a lead'
                actionButtonProps={{
                  onClick: async () => {
                    // We only accept the lead when Continue is clicked
                    await acceptLead.mutateAsync({ id: lead.id })

                    await triggerEvent_Project_Lead_Responded_Accepted({
                      projectId: lead.id,
                      titleOfProject: lead.title,
                      description: lead.description,
                      budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
                      workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
                      tenderReturnDate: DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED),
                      type: lead.projectTypes,
                      isNonWeaverBuilder: weaverFlags["MW-2048-non-weaver-builder-events"].enabled ? isProjectOwnerReferral : undefined,
                    })

                    // NOTE: Leads are virtual representations of Projects and share the same ID
                    goToShowProject({ id: lead.id })()
                  },
                  children: <>Continue</>,
                  expand: 'block',
                }}
              />
            </WeaverIonContent>
          </>
        }, []),
      },
    },
  })

  return isLoading
    ? <LoadingSpinnerPage name='ContractorLeadAcceptorPage' />
    : <WeaverIonPage id={`ContractorLeadAcceptorPage.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>{Screen}</WeaverIonPage>
}

export default ContractorLeadAcceptorPage
