import { alwaysArray } from "../../../../../../common/utils"
import { BudgetRange, ContractorProfileBudgetRangeBadge } from "../../../../../../graphql/generated"
import { getAllNonArchivedWorkHistoriesByBudgetRange, getFirstWorkHistoryByBudgetRange, getWorkHistoryById, useMyContractorProfile } from "../../datasource"
import { getBadgeToAwardAfterAddWorkHistory, BadgeLevel } from "../BadgeUtils"

const hasAnyBadgeAlready = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange): boolean =>
  alwaysArray(profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges)
    .some(badge => badge.budgetRange === budgetRange)

const doesProfileQualifyForBadge = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange): boolean => {
  console.debug(`[doesProfileQualifyForBadge] Checking: `, { profile, budgetRange })

  const workHistory = getFirstWorkHistoryByBudgetRange(profile, budgetRange)
  if (workHistory === undefined) {
    console.debug(`[doesProfileQualifyForBadge] No work history, no badge`)
    return false
  }

  const hasReferences = alwaysArray(workHistory.references).length > 0
  const hasPhotos = alwaysArray(workHistory.photos).length > 0

  console.debug(`[doesProfileQualifyForBadge] Result === ${hasReferences && hasPhotos}: `, { workHistory, hasReferences, hasPhotos })
  return hasReferences && hasPhotos
}

export const getBadgesInBudgetRange = (budgetRangeBadgeData: Partial<ContractorProfileBudgetRangeBadge>[] | undefined | null, budgetRange: BudgetRange) => {
  if (!budgetRangeBadgeData) return []

  const badgesInBudgetRange = budgetRangeBadgeData.filter((data) => data.budgetRange === budgetRange)

  const filteredBadgesArray = badgesInBudgetRange.map((data) => data.badgeLevel)

  return filteredBadgesArray
}

/**
 * This prevents us from awarding badges for incomplete work history
 */
export const getAllBadgeEligibleHistories = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange) => {
  const allNonArchivedHistories = getAllNonArchivedWorkHistoriesByBudgetRange(profile, budgetRange)
  return alwaysArray(allNonArchivedHistories).filter((project) => alwaysArray(project?.photos).length > 0 && alwaysArray(project?.references).length > 0)
}

const doesProfileQualifyForTieredBadge = (profile: ReturnType<typeof useMyContractorProfile>, budgetRange: BudgetRange, workHistoryId: string) => {

  const workHistoriesCountInBudgetRange = getAllBadgeEligibleHistories(profile, budgetRange).length
  const badgeData = profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges

  // is other work histories valid or have a badge already
  const currentWorkHistory = getWorkHistoryById(profile, workHistoryId)
  const budgetRangeBadges =  getBadgesInBudgetRange(badgeData, budgetRange)
  const eligibleBadge = getBadgeToAwardAfterAddWorkHistory(workHistoriesCountInBudgetRange)
  const alreadyHadBadgeForBudgetRange = budgetRangeBadges?.includes(eligibleBadge)

  // is current work history valid
  const hasReferences = alwaysArray(currentWorkHistory?.references).length > 0
  const hasPhotos = alwaysArray(currentWorkHistory?.photos).length > 0

  if (hasReferences && hasPhotos && !alreadyHadBadgeForBudgetRange){
    return eligibleBadge
  }

  return BadgeLevel.None
}

// Exported Hooks

export const useHasContractorProfileBudgetRangeBadge = (budgetRange: BudgetRange): boolean => {
  const profile = useMyContractorProfile()

  return hasAnyBadgeAlready(profile, budgetRange)
}

export const useShouldAwardContractorProfileBudgetRangeBadge = (budgetRange: BudgetRange): boolean => {
  const profile = useMyContractorProfile()

  if (hasAnyBadgeAlready(profile, budgetRange)) return false

  return doesProfileQualifyForBadge(profile, budgetRange)
}

export const useAwardTieredBadge = (budgetRange: BudgetRange, workHistoryId:string): BadgeLevel => {
  const profile = useMyContractorProfile()

  return doesProfileQualifyForTieredBadge(profile, budgetRange,workHistoryId)
}
