import React from "react"
import { IonBadge, IonIcon, IonItem } from "@ionic/react"
import { allIonIcons } from "../../../common/components/IonIconTyped/icons"
import { alwaysArray } from "../../../common/utils"
import { moneyToText } from "../../../common/utils/currency"
import { ProgressionStatus } from "../../../graphql/generated"
import { getProgressionStatusForWorkHistoryItem } from "../ContractorLeadPreferences/ContractorBudgetRanges/datasource"
import { projectTypeLabels } from "../../onboarding/team/onboarding.i18n"
import Styles from "./ListWorkHistoryItems.module.scss"
import { formatAddressToSingleLine } from "../../../common/utils/addresses"
import { PublicContractorProfileScreenWithRenderProps, PublicContractorScreenNames } from "./PublicContractorProfile"
import { usePublicContractorProfileScreenState } from "./PublicContractorProfileScreenState"

const acceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const ListWorkHistoryItems: React.FC<PublicContractorProfileScreenWithRenderProps> = ({ profile, useChangeScreen }) => {
  const {
    workHistoryItemIdState: [ , setWorkHistoryItemId ],
  } = usePublicContractorProfileScreenState()

  const changeScreenToWorkHistory = useChangeScreen(PublicContractorScreenNames.WorkHistory)
  const showContractorWorkHistoryItem = (workHistoryItemId: string) => {
    setWorkHistoryItemId(workHistoryItemId)
    changeScreenToWorkHistory()
  }

  const filteredNonArchivedWorkHistory = alwaysArray(profile.workHistory)
    .filter(workHistoryItem => !workHistoryItem.isArchived)

  const acceptableWorkHistory = filteredNonArchivedWorkHistory
    .filter(workHistoryItem => acceptableStatuses.includes(getProgressionStatusForWorkHistoryItem(workHistoryItem)))

  return acceptableWorkHistory.length > 0
    ? <div className={Styles.contractorWorkHistoryContainer}>
      <h3>Work History</h3>
      {acceptableWorkHistory.map(workHistoryItem =>
        <IonItem
          key={workHistoryItem.id}
          button
          lines="none"
          detail={false}
          onClick={() => showContractorWorkHistoryItem(workHistoryItem.id)}
        >
          <IonIcon icon={allIonIcons.homeOutline} />
          <div className={Styles.workHistoryTextContainer}>
            {workHistoryItem.projectAddress && <p className={Styles.projectAddress}>{formatAddressToSingleLine(workHistoryItem.projectAddress)}</p>}
            <p key="constructionValue" className={Styles.costOfWorkText}>Cost of Work: {moneyToText(workHistoryItem.constructionValue, { withPennies: true })}</p>
            <p key="projectTypes">
              {alwaysArray(workHistoryItem.projectTypes).map(projectType => <IonBadge key={projectType}>{projectTypeLabels[projectType]}</IonBadge>)}
            </p>
          </div>
        </IonItem>,
      )}
    </div>
    :  <div className={Styles.workHistoryTextContainer}>
      <h3>Work History</h3>
      <p className={Styles.noWorkHistoryText}>No work history has been added</p>
    </div>
}

export default ListWorkHistoryItems
