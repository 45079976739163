import React, { useCallback, useState } from 'react'
import { IonFooter, IonIcon, IonLabel, IonRouterLink, isPlatform } from '@ionic/react'
import Styles from './TabNavBar.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'

export type Tab = {
  name: string,
  title: string,
  icon: string,
  href?: string,
  useShouldShowIndicator?: () => boolean,
}

type TabNavBarProps = {
  tabs: Array<Tab>,
}

type TabContainerProps = {
  tab: Tab,
  onTabClicked: (tabName: string) => void,
  applyActiveTabStyles: (tabName: string) => string,
}

const TabContainer: React.FC<TabContainerProps> = ({ tab, applyActiveTabStyles, onTabClicked }) => {
  const { name, href, title, icon, useShouldShowIndicator = () => false } = tab
  const shouldShowIndicator = useShouldShowIndicator()

  const weaverFlags = useWeaverFlags()
  const isMobile = isPlatform('mobile') || isPlatform('mobileweb')

  return (
    <IonRouterLink key={name} routerLink={href} routerDirection={'none'} onClick={() =>  onTabClicked(name)}>
      <section className={Styles.section}>
        <div className={Styles.tabIconContainer}>
          {(weaverFlags['MW-2168-display-unread-messages-red-dot-on-tab']  && shouldShowIndicator) &&
            <div className={Styles.redDot} />
          }
          <IonIcon color={applyActiveTabStyles(name)} className={`${Styles.ionIcon} ${isMobile && Styles.mobile}`} icon={icon} />
        </div>
        <IonLabel color={applyActiveTabStyles(name)} className={Styles.ionLabel}>{title}</IonLabel>
      </section>
    </IonRouterLink>
  )
}

const TabNavBar: React.FC<TabNavBarProps> = ({ tabs }) => {
  const [ activeTab, setActiveTab ] = useState<string | null>(null)
  const isMobile = isPlatform('mobile') || isPlatform('mobileweb')

  const applyActiveTabStyles = useCallback((tabName: string) => {
    if (activeTab === tabName) {
      return 'primary'
    } else {
      return 'medium'
    }
  }, [ activeTab ])

  const onTabClicked = useCallback((tabName: string) => {
    setActiveTab(tabName)
  }, [ setActiveTab ])

  return (
    <div className={Styles.footerContainer}>
      <IonFooter className={`${Styles.ionFooter} ion-no-border ${isMobile && Styles.mobile}`}>
        <div className={Styles.tabsContainer}>
          {tabs.map(tab => <TabContainer key={tab.name} tab={tab} applyActiveTabStyles={applyActiveTabStyles} onTabClicked={onTabClicked}  />)}
        </div>
      </IonFooter>
    </div>
  )
}

export default TabNavBar
