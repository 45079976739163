import React from "react"
import { IonButton, IonIcon, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, useRouteTo } from "../../../../routes"
import  { GenericSelectorOption } from "../../../../common/components/GenericSelectorList"
import ListBudgetRanges, { ListBudgetRangeOption } from "./ListBudgetRanges"
import { budgetRangeLabels } from "./budgetRanges.i18n"
import { arrowBackOutline, lockClosed, lockOpen } from 'ionicons/icons'
import { createValidCssClassname } from "../../../../common/utils/string"
import { convertToLockedBudgetRangeStatuses, getFirstWorkHistoryByBudgetRange, useMyContractorProfile } from "./datasource"
import { BudgetRange, ContractorProfileBudgetRangeBadge, ProgressionStatus } from "../../../../graphql/generated"
import { objectEntries } from "../../../../common/utils/objects"
import Styles from "./ContractorBudgetRanges.module.scss"
import { progressionStatusSubtextLabel } from "./progressionStatus.i18n"
import { useAnalyticsEvent } from "../../../../api/providers/SegmentProvider/hooks"
import WeaverIonPage from "../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import { useWeaverFlags } from "../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { BadgesRow } from "./UnlockContractorBudgetRange/BadgesRow"

type IonIconPropsIcon = Parameters<typeof IonIcon>['0']['icon']
const progressionStatusIcons: Partial<Record<ProgressionStatus, IonIconPropsIcon>> = {
  [ProgressionStatus.NotStarted]: lockClosed,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
  [ProgressionStatus.WaitingOnWeaver]: lockOpen,
  [ProgressionStatus.WaitingOnUser]: lockClosed,
}

// Local Helpers
const getLockedBudgetRangeGenericSelectorOptions = (lockedBudgetRangeStatuses: Record<BudgetRange, ProgressionStatus>, filterTo?: ProgressionStatus[], budgetRangeBadgeData?: Partial<ContractorProfileBudgetRangeBadge>[] | undefined | null): GenericSelectorOption<ListBudgetRangeOption>[] =>
  objectEntries(lockedBudgetRangeStatuses)
    .filter(([ , lockedBudgetRangeStatus ]) => filterTo === undefined || filterTo.includes(lockedBudgetRangeStatus))
    .map(([ budgetRange, lockedBudgetRangeStatus ]) => ({
      key: budgetRange,
      value: {
        budgetRange,
        lockedBudgetRangeStatus,
        budgetRangeBadgeData,
      },
      itemClassName: Styles[createValidCssClassname(lockedBudgetRangeStatus)],
    }))

const GenericSelectorListRendererBudgetRange: React.FC<GenericSelectorOption<ListBudgetRangeOption>> = ({ value: { budgetRange, lockedBudgetRangeStatus, budgetRangeBadgeData } }) => {
  const weaverFlags = useWeaverFlags()
  const isMultipleWorkHistoriesEnabled = weaverFlags["MW-2096-multiple-work-histories"].enabled
  return <>
    <p>
      {budgetRangeLabels[budgetRange]}
      <IonIcon icon={progressionStatusIcons[lockedBudgetRangeStatus]} />
    </p>
    <div>{progressionStatusSubtextLabel[lockedBudgetRangeStatus]}</div>
    {isMultipleWorkHistoriesEnabled && <BadgesRow budgetRange={budgetRange} budgetRangeBadgeData={budgetRangeBadgeData} />}
  </>
}

const ContractorBudgetRangesPage: React.FC = () => {
  const goToContractorLeadPreferences = useRouteTo(pageConfig_Profile_ContractorLeadPreferences.path)
  const goToUnlockContractorBudgetRange = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange.path)
  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  const weaverFlags = useWeaverFlags()
  const isMultipleWorkHistoriesEnabled = weaverFlags["MW-2096-multiple-work-histories"].enabled

  const myContractorProfile = useMyContractorProfile()
  const lockedBudgetRangeStatuses = convertToLockedBudgetRangeStatuses(myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const budgetRangeBadgeData = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges

  const fireEventWorkHistoryReferencesRangeSelected = useAnalyticsEvent('WorkHistory_References_Range_Selected')

  const selectBudgetRange = (option?: ListBudgetRangeOption) => {
    const budgetRange = option?.budgetRange
    if (!budgetRange) return

    const firstWorkHistoryByBudgetRange = getFirstWorkHistoryByBudgetRange(myContractorProfile, budgetRange)

    fireEventWorkHistoryReferencesRangeSelected({
      budgetRangeSelected: budgetRange,
      rangeStatus: lockedBudgetRangeStatuses[budgetRange],
    })

    // If Multiple work histories flag disabled && already has one work history in budget range
    if (!isMultipleWorkHistoriesEnabled && firstWorkHistoryByBudgetRange){
      goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId: firstWorkHistoryByBudgetRange.id })()
    } else {
      goToUnlockContractorBudgetRange({ budgetRange })()
    }
  }

  return (
    <WeaverIonPage id="ContractorBudgetRangesPage">
      <WeaverIonContent className={`ion-padding ${Styles.content}`} >
        <h3 className="ion-padding-start">Pick a budget range to unlock leads</h3>
        <ListBudgetRanges
          heading="Unlocked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.Completed ], budgetRangeBadgeData)}
          onSelect={selectBudgetRange}
          render={GenericSelectorListRendererBudgetRange}
        />
        <ListBudgetRanges
          heading="Locked Budget Ranges"
          options={getLockedBudgetRangeGenericSelectorOptions(lockedBudgetRangeStatuses, [ ProgressionStatus.WaitingOnUser, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.NotStarted ], budgetRangeBadgeData)}
          onSelect={selectBudgetRange}
          render={GenericSelectorListRendererBudgetRange}
        />
      </WeaverIonContent>
      <WeaverIonFooter className="ion-no-border ion-padding">
        <IonToolbar className={Styles.ionToolbar}>
          <IonButton color="secondary" slot="start" onClick={goToContractorLeadPreferences({})}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
        </IonToolbar>
      </WeaverIonFooter>
    </WeaverIonPage>
  )
}

export default ContractorBudgetRangesPage
