import React, { MutableRefObject, useRef, useState } from "react"
import { IonButton, IonButtons, IonHeader, IonIcon, IonImg, IonItem, IonTitle, IonToolbar } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { ScreenRenderProps, useScreensWithProps } from "../../../../../../common/hooks/useScreens"
import { zodResolver } from "@hookform/resolvers/zod"
import { zWorkHistoryReference } from "../workHistoryTypes"
import { useForm, UseFormReturn } from "react-hook-form"
import { GetContractorProfileWorkHistory, getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile } from "../../datasource"
import { alwaysArray } from "../../../../../../common/utils"
import { z } from "zod"
import { BudgetRange, TeamType, useCreateContractorProfileWorkHistoryReferenceMutation } from "../../../../../../graphql/generated"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { arrowBackOutline, arrowForwardOutline, personAddOutline } from "ionicons/icons"
import ProjectAvatar from "../../../../../projects/ProjectAvatar"
import FormInputAndErrors from "./FormInputAndErrors"
import MobileNumberCapture from "../../../../../../common/components/MobileNumberCapture"
import { E164Number } from "libphonenumber-js/types"
import searchSvg from "../../../../../../assets/icons/search.svg"
import IndicatorBar from "../../../../../projects/CreateProjectPage/IndicatorBar"
import TitleWithDescriptionPage from "../../../TitleWithDescriptionPage"
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import SingleClickButton from "../../../../../../common/components/SingleClickButton"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import { useAwardTieredBadge, useShouldAwardContractorProfileBudgetRangeBadge } from "../ContractorProfileBudgetRangeBadge/hooks"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"
import WorkHistoryForReferenceModalButton from "../../../../../external/reference/WorkHistoryForReferenceModalButton"
import { WorkHistoryForReferenceProps } from "../../../../../external/reference/WorkHistoryForReference"
import LoadingSpinnerContent from "../../../../../../common/components/LoadingSpinner/LoadingSpinnerContent"
import WorkHistoryForReferenceModal from "../../../../../external/reference/WorkHistoryForReferenceModal"
import Styles from "./WorkHistoryReferences.module.scss"
import { useWeaverFlags } from "../../../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { BadgeLevel } from "../BadgeUtils"

enum WorkHistoryReferencesScreens {
  ListReferences = "ListReferences",
  ReferenceNameInstructions = "ReferenceNameInstructions",
  SelectReferenceRole = "SelectReferenceRole",
  EnterArchitectDetails = "EnterArchitectDetails",
  EnterHomeownerDetails = "EnterHomeownerDetails",
  Finished = "Finished",
}

type ScreenProps = {
  budgetRange: BudgetRange,
  workHistory?: GetContractorProfileWorkHistory,
  myContractorProfile: ReturnType<typeof useMyContractorProfile>,
  form: UseFormReturn<WorkHistoryReferenceForm>,
  workHistoryId:string,
  weaverPageRef: MutableRefObject<HTMLElement | undefined>,
}

type ScreenComponentProps = ScreenRenderProps<WorkHistoryReferencesScreens> & ScreenProps

const zWorkHistoryReferenceForm = z.object({ reference: zWorkHistoryReference })
type WorkHistoryReferenceForm = z.infer<typeof zWorkHistoryReferenceForm>

const _ListReferences: React.FC<ScreenComponentProps> = ({ useChangeScreen, budgetRange, workHistory, workHistoryId }) => {
  const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
  const onClickFinish = useChangeScreen(WorkHistoryReferencesScreens.Finished)
  const goToContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)
  const references = alwaysArray(workHistory?.references)

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className={`${Styles.addReferenceContainer} ion-padding`} fullscreen>
      <h3 className="ion-padding-start"><b>References</b></h3>
      {references.map(reference =>
        <IonItem
          key={reference.id}
          button
          detail={false}
          className={Styles.referenceDetailItem}>
          <ProjectAvatar title={reference.givenName} slot={"start"} />
          <div className={Styles.labelContainer}>
            <p key={reference.id}>{reference.givenName} {reference.familyName}</p>
            <p>{reference.teamType}</p>
          </div>
        </IonItem>,
      )}
      <IonItem
        button
        detail={false}
        className={Styles.addReferenceButton}
        onClick={onClickNext}>
        <IonIcon icon={personAddOutline} />
        <p>Add {references.length ? 'another ' : 'a '}reference name for this project</p>
      </IonItem>
    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={goToContractorBudgetRangeMenu({ budgetRange, workHistoryId })}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        {references.length > 0 && <IonButton slot="end" onClick={onClickFinish}>Finish<IonIcon icon={arrowForwardOutline} /></IonButton>}
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _ReferenceNameInstructions: React.FC<ScreenComponentProps> = ({ useChangeScreen, workHistoryId, budgetRange, workHistory }) => {
  const goToListReferences = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)

  const references = alwaysArray(workHistory?.references)
  const goToContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  // Work History Id should never be undefined here
  const onClickBack = references.length === 0
    ? goToContractorBudgetRangeMenu({ budgetRange, workHistoryId: workHistoryId })
    : goToListReferences

  const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)

  return <>
    <WeaverIonContent fullscreen className={Styles.referenceInputCoverPage}>
      <TitleWithDescriptionPage
        topDivContents={<IonImg src={searchSvg} className={Styles.customIonImg} />}
        title='Input a reference name'
        titleClassName='ion-text-center'
        description={`Obtaining references helps build trust with the project owners and makes your profile stand out from the rest.
Note, you will only be able to accept leads once this step is marked as complete.`}
      />
    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}
const _SelectReferenceRole: React.FC<ScreenComponentProps> = ({ useChangeScreen, form, budgetRange }) => {
  const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)
  const onClickArchitect = useChangeScreen(WorkHistoryReferencesScreens.EnterArchitectDetails)
  const onClickHomeowner = useChangeScreen(WorkHistoryReferencesScreens.EnterHomeownerDetails)

  const resetFormThen = (then: () => unknown) => () => {
    form.reset()
    then()
  }

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className={`${Styles.selectReferenceRoleContainer} ion-padding`} fullscreen>
      <div className={Styles.indicatorContainer}>
        <IndicatorBar currentPageIndex={0} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
      </div>
      <h3 className="ion-padding-start">Select the reference role for this past project</h3>
      <div className={Styles.referenceButtonsContainer}>
        <IonButton onClick={resetFormThen(onClickArchitect)}><span>Architect</span></IonButton>
        <IonButton onClick={resetFormThen(onClickHomeowner)}><span>Homeowner</span></IonButton>
      </div>
    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar} >
        <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} /> Back</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _EnterArchitectDetails: React.FC<ScreenComponentProps> = ({ useChangeScreen, workHistory, form, workHistoryId, budgetRange, myContractorProfile, weaverPageRef }) => {
  const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
  const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)
  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ showWorkHistoryModal, setShowWorkHistoryModal ] = useState(false)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()
  const triggerEventWorkHistoryReferencesAddedPastProjectReference = useAnalyticsEvent('WorkHistory_References_Added_Reference')
  const triggerEventWorkHistoryReferencesPreviewViewed = useAnalyticsEvent('WorkHistory_References_Preview_Viewed')

  const references = alwaysArray(workHistory?.references)
  const formReference = form.watch('reference')

  const weaverFlags = useWeaverFlags()

  const handleChange = (value: E164Number | undefined) => {
    const result = value !== undefined ? `${value}` : ""
    form.setValue('reference.phoneNumber', result)
  }

  const onClickNextIfFieldIsValid = async () => {
    const isValid = await form.trigger('reference')
    if (!isValid) return

    setSubmitting(true)

    const createReferenceResult = await createMutation.mutateAsync({
      input: {
        id: workHistoryId,
        reference: {
          teamType: TeamType.Architect,
          companyName: form.getValues('reference.companyName'),
          givenName: form.getValues('reference.givenName'),
          familyName: form.getValues('reference.familyName'),
          email: form.getValues('reference.email'),
          phoneNumber: form.getValues('reference.phoneNumber'),
        },
      },
    })

    const referenceId = createReferenceResult.createContractorProfileWorkHistoryReference.id

    await triggerEventWorkHistoryReferencesAddedPastProjectReference({
      totalNumberOfReferences: references.length,
      workHistoryId: workHistoryId,
      referenceId: referenceId,
      referenceType: TeamType.Architect,
      referenceEmail: form.getValues('reference.email'),
      referencePhone: form.getValues('reference.phoneNumber'),
      referenceGivenName: form.getValues('reference.givenName'),
      referenceFamilyName: form.getValues('reference.familyName'),
      referenceCompanyName: form.getValues('reference.companyName'),
    })

    await invalidateMyContractorProfile()
    await onClickNext()

    setSubmitting(false)
  }

  const contractorProfile = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile
  if (!contractorProfile) return <LoadingSpinnerContent name="WorkHistoryReferencesPage.EnterArchitectDetails.contractorProfile" />
  if (!workHistory) return <LoadingSpinnerContent name="WorkHistoryReferencesPage.EnterArchitectDetails.workHistory" />

  const handleOpenModal = async () => {
    setShowWorkHistoryModal(true)

    form.trigger('reference')

    await triggerEventWorkHistoryReferencesPreviewViewed({
      types: workHistory.projectTypes ?? undefined,
      contractorTeamId: contractorProfile.id,
      referenceType: TeamType.Architect,
      costOfWork: workHistory.constructionValue ?? undefined,
      workHistoryId: workHistoryId,
      referenceEmail: form.getValues('reference.email'),
      referencePhone: form.getValues('reference.phoneNumber'),
      referenceGivenName: form.getValues('reference.givenName'),
      referenceFamilyName: form.getValues('reference.familyName'),
    })
  }

  const reference: WorkHistoryForReferenceProps['reference'] = {
    id: 'TBC', // We don't have a reference saved yet! Making it up.
    givenName: formReference?.givenName ?? '',
    familyName: formReference?.familyName ?? '',
  }

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
      <div className={Styles.indicatorContainer}>
        <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
      </div>
      <h3>Enter Architect&apos;s Details</h3>
      <FormInputAndErrors
        label={"Architect Company Name*"}
        name={"reference.companyName"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.companyName} />
      <FormInputAndErrors
        label={"First name*"}
        name={"reference.givenName"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.givenName} />
      <FormInputAndErrors
        label={"Last name*"}
        name={"reference.familyName"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.familyName} />
      <FormInputAndErrors
        label={"Email*"}
        name={"reference.email"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.email} type={"email"} />
      <MobileNumberCapture
        customClassName={Styles.customMobileInput}
        textHeading="Phone number*"
        textFieldPhoneNumberTitle=""
        value={form.getValues('reference.phoneNumber')}
        onChange={handleChange} error={form.formState.errors.reference && form.formState.errors.reference.phoneNumber}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.phoneNumber?.message} />
      <input value={TeamType.Architect} {...form.register("reference.teamType")} type="hidden" />

      {!weaverFlags["MW-2023-work-history-reference-preview-doesnt-scroll"].enabled && <WorkHistoryForReferenceModalButton
        contractorProfile={contractorProfile}
        workHistoryItem={workHistory}
        reference={reference}
        slot="start"
        fill="clear"
        size="small"
        onClick={() => {
          // HACK: For some reason, FormInputAndErrors isn't triggering a re-render 'onChange'
          // even though the useForm 'mode' is set to 'onChange' - so we call trigger to force it
          // The next step would be to use useScreensWithProps and pull the screens into separate components
          // This works so we're leaving it here for now
          form.trigger('reference')
          triggerEventWorkHistoryReferencesPreviewViewed({
            types: workHistory.projectTypes ?? undefined,
            contractorTeamId: contractorProfile.id,
            referenceType: TeamType.Architect,
            costOfWork: workHistory.constructionValue ?? undefined,
            workHistoryId: workHistoryId,
            referenceEmail: form.getValues('reference.email'),
            referencePhone: form.getValues('reference.phoneNumber'),
            referenceGivenName: form.getValues('reference.givenName'),
            referenceFamilyName: form.getValues('reference.familyName'),
          })
        }}
      >
        Preview the form we will send to your reference
      </WorkHistoryForReferenceModalButton>}

      {weaverFlags["MW-2023-work-history-reference-preview-doesnt-scroll"].enabled && <>
        <IonButton
          slot="start"
          fill="clear"
          size="small"
          onClick={handleOpenModal}>
          Preview the form we will send to your reference
        </IonButton>

        <WorkHistoryForReferenceModal
          header={ () => (<IonHeader>
            <IonToolbar>
              <IonTitle>Work history preview</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowWorkHistoryModal(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>)}
          contractorProfile={contractorProfile}
          workHistoryItem={workHistory}
          reference={reference}
          isOpen={showWorkHistoryModal}
          canDismiss={true}
          onDidDismiss={() => setShowWorkHistoryModal(false)}
          presentingElement={weaverPageRef?.current}
        />
      </>}

    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack} disabled={isSubmitting}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <SingleClickButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}
const _EnterHomeownerDetails: React.FC<ScreenComponentProps> = ({ useChangeScreen, workHistoryId, workHistory, form, myContractorProfile, budgetRange, weaverPageRef }) => {
  const onClickBack = useChangeScreen(WorkHistoryReferencesScreens.SelectReferenceRole)
  const onClickNext = useChangeScreen(WorkHistoryReferencesScreens.ListReferences)
  const [ isSubmitting, setSubmitting ] = useState(false)
  const [ showWorkHistoryModal, setShowWorkHistoryModal ] = useState(false)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createMutation = useCreateContractorProfileWorkHistoryReferenceMutation(gqlDataSource)
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

  const references = alwaysArray(workHistory?.references)
  const formReference = form.watch('reference')

  const triggerEventWorkHistoryReferencesAddedPastProjectReference = useAnalyticsEvent('WorkHistory_References_Added_Reference')
  const triggerEventWorkHistoryReferencesPreviewViewed = useAnalyticsEvent('WorkHistory_References_Preview_Viewed')

  const handleChange = (value: E164Number | undefined) => {
    const result = value !== undefined ? `${value}` : ""
    form.setValue('reference.phoneNumber', result)
  }

  const onClickNextIfFieldIsValid = async () => {
    const isValid = await form.trigger('reference')
    if (!isValid) return

    setSubmitting(true)

    const createReferenceResult = await createMutation.mutateAsync({
      input: {
        id: workHistoryId,
        reference: {
          teamType: TeamType.Homeowner,
          givenName: form.getValues('reference.givenName'),
          familyName: form.getValues('reference.familyName'),
          email: form.getValues('reference.email'),
          phoneNumber: form.getValues('reference.phoneNumber'),
        },
      },
    })

    const referenceId = createReferenceResult.createContractorProfileWorkHistoryReference.id

    await triggerEventWorkHistoryReferencesAddedPastProjectReference({
      totalNumberOfReferences: references.length,
      workHistoryId: workHistoryId,
      referenceId: referenceId,
      referenceType: TeamType.Homeowner,
      referenceEmail: form.getValues('reference.email'),
      referencePhone: form.getValues('reference.phoneNumber'),
      referenceGivenName: form.getValues('reference.givenName'),
      referenceFamilyName: form.getValues('reference.familyName'),
      referenceCompanyName: form.getValues('reference.companyName'),
    })

    await invalidateMyContractorProfile()
    await onClickNext()

    setSubmitting(false)
  }

  const contractorProfile = myContractorProfile.getContractorProfileQuery.data?.getContractorProfile
  if (!contractorProfile) return <LoadingSpinnerContent name="WorkHistoryReferencesPage.EnterArchitectDetails.contractorProfile" />
  if (!workHistory) return <LoadingSpinnerContent name="WorkHistoryReferencesPage.EnterArchitectDetails.workHistory" />

  const weaverFlags = useWeaverFlags()

  const handleOpenModal = async () => {
    setShowWorkHistoryModal(true)

    form.trigger('reference')

    await triggerEventWorkHistoryReferencesPreviewViewed({
      types: workHistory.projectTypes ?? undefined,
      contractorTeamId: contractorProfile.id,
      referenceType: TeamType.Homeowner,
      costOfWork: workHistory.constructionValue ?? undefined,
      workHistoryId: workHistoryId,
      referenceEmail: form.getValues('reference.email'),
      referencePhone: form.getValues('reference.phoneNumber'),
      referenceGivenName: form.getValues('reference.givenName'),
      referenceFamilyName: form.getValues('reference.familyName'),
    })
  }

  const reference: WorkHistoryForReferenceProps['reference'] = {
    id: 'TBC', // We don't have a reference saved yet! Making it up.
    givenName: formReference?.givenName ?? '',
    familyName: formReference?.familyName ?? '',
  }

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className={`ion-padding ${Styles.referenceInputContainer}`} fullscreen>
      <div className={Styles.indicatorContainer}>
        <IndicatorBar currentPageIndex={1} totalPages={2} maxAvailableIndex={2} onNavigate={() => null} />
      </div>
      <h3>Enter Homeowner Details</h3>
      <input  {...form.register("reference.teamType")} value={TeamType.Homeowner} type="hidden" />
      <FormInputAndErrors
        label={"First name*"}
        name={"reference.givenName"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.givenName} />
      <FormInputAndErrors
        label={"Last name*"}
        name={"reference.familyName"}
        register={form.register}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.familyName} />
      <FormInputAndErrors
        label={"Email*"}
        name={"reference.email"}
        register={form.register}
        type={"email"}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.email} />
      <MobileNumberCapture
        customClassName={Styles.customMobileInput}
        textHeading="Phone number*"
        textFieldPhoneNumberTitle=""
        value={form.getValues('reference.phoneNumber')}
        onChange={handleChange} error={form.formState.errors.reference && form.formState.errors.reference.phoneNumber}
        disabled={isSubmitting}
        errorMessage={form.formState.errors.reference && form.formState.errors.reference.phoneNumber?.message} />
      {!weaverFlags["MW-2023-work-history-reference-preview-doesnt-scroll"].enabled && <WorkHistoryForReferenceModalButton
        contractorProfile={contractorProfile}
        workHistoryItem={workHistory}
        reference={reference}
        slot="start"
        fill="clear"
        size="small"
        onClick={() => {
          // HACK: For some reason, FormInputAndErrors isn't triggering a re-render 'onChange'
          // even though the useForm 'mode' is set to 'onChange' - so we call trigger to force it
          // The next step would be to use useScreensWithProps and pull the screens into separate components
          // This works so we're leaving it here for now
          form.trigger('reference')
          triggerEventWorkHistoryReferencesPreviewViewed({
            types: workHistory.projectTypes ?? undefined,
            contractorTeamId: contractorProfile.id,
            referenceType: TeamType.Homeowner,
            costOfWork: workHistory.constructionValue ?? undefined,
            workHistoryId: workHistoryId,
            referenceEmail: form.getValues('reference.email'),
            referencePhone: form.getValues('reference.phoneNumber'),
            referenceGivenName: form.getValues('reference.givenName'),
            referenceFamilyName: form.getValues('reference.familyName'),
          })
        }}
      >
        Preview the form we will send to your reference
      </WorkHistoryForReferenceModalButton>}

      {weaverFlags["MW-2023-work-history-reference-preview-doesnt-scroll"].enabled && <>
        <IonButton
          slot="start"
          fill="clear"
          size="small"
          onClick={handleOpenModal}>
          Preview the form we will send to your reference
        </IonButton>

        <WorkHistoryForReferenceModal
          header={ () => (<IonHeader>
            <IonToolbar>
              <IonTitle>Work history preview</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowWorkHistoryModal(false)}>
                  Close
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>)}
          contractorProfile={contractorProfile}
          workHistoryItem={workHistory}
          reference={reference}
          isOpen={showWorkHistoryModal}
          canDismiss={true}
          onDidDismiss={() => setShowWorkHistoryModal(false)}
          presentingElement={weaverPageRef?.current}
        />
      </>}

    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack} disabled={isSubmitting}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <SingleClickButton slot="end" onClick={onClickNextIfFieldIsValid}>Next<IonIcon icon={arrowForwardOutline} /></SingleClickButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}
const _Finished: React.FC<ScreenComponentProps> = ({ budgetRange, workHistoryId }) => {
  const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)
  const goToAwardBadge = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge.path)

  const awardedBadge = useAwardTieredBadge(budgetRange, workHistoryId)
  const goToContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)
  const weaverFlags = useWeaverFlags()

  const onClickNext = () => {
    if (weaverFlags["MW-2096-multiple-work-histories"].enabled){
      awardedBadge !== BadgeLevel.None
        ? goToAwardBadge({ budgetRange, workHistoryId })()
        : goToContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    } else {
      shouldAwardContractorProfileBudgetRangeBadge
        ? goToAwardBadge({ budgetRange, workHistoryId })()
        : goToContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    }
  }

  console.debug("[WorkHistoryReferences.Finished] Render: ", { shouldAwardContractorProfileBudgetRangeBadge })

  return <>
    <WeaverIonContent>
      <BrilliantScreen
        title='Amazing!!!'
        description='
          You can speed things along by letting your reference know that Weaver will will be in touch.
          You will get a notification once the details have been verified.
          This process usually takes about a week, depending on how quickly your reference responds to us.
        '
      />
    </WeaverIonContent>
    <WeaverIonFooter className="ion-no-border ion-padding">
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton expand="block" onClick={onClickNext}>Continue</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const WorkHistoryReferencesPage: React.FC = () => {
  const history = useHistory()
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()

  // Ref for Work History Reference Preview Card Mode modal
  const weaverPageRef = useRef(undefined)

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const form = useForm<WorkHistoryReferenceForm>({
    defaultValues: {},
    mode: "onChange",
    resolver: zodResolver(zWorkHistoryReferenceForm),
  })

  const [ Screen, activeScreen ] = useScreensWithProps<WorkHistoryReferencesScreens, ScreenProps>({
    init: () => {
      form.reset()
      const references = alwaysArray(workHistory?.references)
      return references.length === 0
        ? WorkHistoryReferencesScreens.ReferenceNameInstructions
        : WorkHistoryReferencesScreens.ListReferences
    },
    resetWhenValueChanges: history.location.search,
    screenProps: {
      budgetRange,
      workHistory,
      workHistoryId,
      myContractorProfile,
      form,
      weaverPageRef,
    },
    screens: {
      ListReferences: { render: _ListReferences },
      ReferenceNameInstructions: { render: _ReferenceNameInstructions },
      SelectReferenceRole: { render: _SelectReferenceRole },
      EnterArchitectDetails: { render: _EnterArchitectDetails },
      EnterHomeownerDetails: { render: _EnterHomeownerDetails },
      Finished: { render: _Finished },
    },
  })

  return (
    <WeaverIonPage id={`WorkHistoryReferencesPage.${activeScreen}`} pageRef={weaverPageRef} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default WorkHistoryReferencesPage
