
import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import Styles from "./UploadableFileChip.module.scss"
import { closeCircleOutline } from 'ionicons/icons'

type UploadableFileChipUnstartedProps  =
{
  file: File,
  onRemoveFileClicked: (file: File) => void,
}

const UploadableFileChipUnstarted: React.FC<UploadableFileChipUnstartedProps> = ({ file, onRemoveFileClicked }) => {
  return (
    <IonButton className={Styles.uploadBubble} fill="clear" size="small" onClick={() => onRemoveFileClicked(file)} >
      <span>{file.name}</span>
      <IonIcon icon={closeCircleOutline} />
    </IonButton>
  )
}

export default UploadableFileChipUnstarted
