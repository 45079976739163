import React, { useCallback, useEffect, useState } from "react"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonChip, IonIcon, IonItem, IonLabel, IonSpinner, useIonAlert, useIonRouter } from "@ionic/react"
import { calendarClearOutline, calendarOutline, cashOutline, checkmarkCircle, checkmarkCircleOutline, closeOutline, constructOutline } from "ionicons/icons"
import { useGraphQLDataSource } from "../../api/graphql"
import Styles from "./ShowLeadPage.module.scss"
import { useShowLeadQuery, useRejectLeadMutation, ProjectMemberRole } from "../../graphql/generated"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { getEnumLabel, uniqueFilter } from "../../common/utils"
import { DateTime } from "luxon"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { pageConfig_Projects_ContractorLeadAcceptor, useRouteTo } from "../../routes"
import { useParamsFromPageConfig } from "../../routesProvider"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { getFlexibleProjectBudgetAsMoneyOrMoneyRange, getFlexibleProjectBudgetAsText } from "./common"
import { useAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import GoogleMapFromWeaverAddress from "../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress"
import { flexibleBudgetTextToSignificantFigures } from "../../common/utils/budgetTransformation"
import { getBudgetRangeByMoney } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { teamTypeLabels } from "../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n"

const ShowLead: React.FC = () => {
  const weaverFlags = useWeaverFlags()
  const triggerEvent_Project_Lead_Responded_Rejected = useAnalyticsEvent("Project_Lead_Responded_Rejected")
  const triggerEvent_Lead_Accepted_Clicked = useAnalyticsEvent("Lead_Accept_Clicked")
  const triggerEvent_Lead_PaymentPlanPicker_Viewed = useAnalyticsEvent("Lead_PaymentPlanPicker_Viewed")

  const myTeam = useMyIndividualActiveTeam()

  const { id } = useParamsFromPageConfig<{ id: string }>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ present ] = useIonAlert()
  const router = useIonRouter()

  const showLeadQuery = useShowLeadQuery(gqlDataSource, { id }, { refetchOnWindowFocus: false })
  const routeToContractorLeadAcceptor = useRouteTo(pageConfig_Projects_ContractorLeadAcceptor.path)
  const rejectLeadMutation = useRejectLeadMutation(gqlDataSource)
  const [ rejectLoading, setRejectLoading ] = useState<boolean>(false)
  // useContractorLeadSubscriptions(acceptLeadMutation) which should
  // > check if useWeaverFlags() if Lead Subscriptions are enabled
  // > check if this contractor has an active subscription for this budget range
  // > if not, show the buy lead / subscription flow (otherwise skip)
  // > call the accept lead mutation
  // > success!

  const triggerEvent_Lead_Viewed = useAnalyticsEvent("Lead_Viewed")
  useEffect(() => {
    const lead = showLeadQuery.data?.getLead
    const leadBudgetRange = getBudgetRangeByMoney(lead?.budgetValue)

    if (lead?.id) {
      triggerEvent_Lead_Viewed({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        tenderReturnDate: DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED),
        type: lead.projectTypes,
        budgetRange: leadBudgetRange,
      })
    }
  }, [ showLeadQuery.data?.getLead.id ])

  const acceptLead = useCallback(async () => {
    if (!weaverFlags['MW-2105-move-payment-selector']) {
      const leadBudgetRange = getBudgetRangeByMoney(showLeadQuery.data?.getLead.budgetValue)
      await triggerEvent_Lead_PaymentPlanPicker_Viewed({
        projectId: id,
        budgetRange: leadBudgetRange,
      })
    }

    if (weaverFlags['MW-2122-project-lead-event-updates']) {
      const leadBudgetRange = getBudgetRangeByMoney(showLeadQuery.data?.getLead.budgetValue)
      await triggerEvent_Lead_Accepted_Clicked({
        projectId: id,
        budgetRange: leadBudgetRange,
      })
    }

    routeToContractorLeadAcceptor({ leadId: id })()
  }, [ id, showLeadQuery.data?.getLead.budgetCategory, weaverFlags['MW-2105-move-payment-selector'] ])

  const rejectLead = useCallback(async () => {
    setRejectLoading(true)
    try {
      await rejectLeadMutation.mutateAsync({ id })
      await triggerEvent_Project_Lead_Responded_Rejected({
        projectId: id,
        titleOfProject: lead.title,
        description: lead.description,
        budgetShownToContractor: getFlexibleProjectBudgetAsText(lead, myTeam) ?? undefined,
        workStartEstimate: getWorkStartEstimateLabels()[lead.workStartEstimate],
        tenderReturnDate: DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED),
        type: lead.projectTypes,
      })
    } catch (e) {
      if (e instanceof Error) {
        present({
          header: "Unable to Reject Lead",
          message: e.message,
          buttons: [
            {
              text: "Dismiss",
              role: 'cancel',
            },
          ],
        })
      }
    }

    setRejectLoading(false)
    router.push("/projects")
  }, [ rejectLeadMutation, id, setRejectLoading, router ])

  if (showLeadQuery.isFetching && !showLeadQuery.data) {
    return <LoadingSpinnerPage name="ShowLoadPage.showLeadQuery" />
  }

  if (showLeadQuery.error || !showLeadQuery.data) {
    return <ErrorBlockPage name='ShowLeadPage.showLeadQuery' onRefresh={showLeadQuery.refetch} />
  }

  const { data: { getLead: lead } } = showLeadQuery

  const loading = rejectLoading

  return <WeaverIonPage id='ShowLeadPage'>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      {weaverFlags['MW-2085-ui-updates-to-leads'].enabled
        ? <GlobalHeader pageTitle={`Lead - ${lead.projectTypes.map(x => {return  ' ' + getEnumLabel(x)})}`} />
        : <GlobalHeader pageTitle='Projects' />}
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonCard className={Styles.card}>
        {weaverFlags['project-leads'].showMap && lead.address && <GoogleMapFromWeaverAddress address={lead.address} />}

        {
          weaverFlags['MW-2085-ui-updates-to-leads'].enabled
            ? <>
              <IonItem className={`ion-margin-top`}>
                <div className={Styles.row}>
                  {weaverFlags['MW-2116-copy-updates-to-lead']
                    ? <div>Project Type</div>
                    : <div>Project type</div>}
                </div>
                <div className={Styles.row}>
                  {weaverFlags['MW-2116-copy-updates-to-lead']
                    ? lead.projectTypes.map(getEnumLabel).join(', ')
                    : lead.projectTypes.map(x => {return getEnumLabel(x) + ', '})}
                </div>
              </IonItem>

              <IonItem>
                <div className={Styles.row}>
                  <div>Budget</div>
                </div>
                <div className={Styles.row}>
                  {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(lead, myTeam))}
                </div>
              </IonItem>

              <IonItem>
                <div className={Styles.row}>
                  <div>Work Start Estimate</div>
                </div>
                <div className={Styles.row}>
                  {getWorkStartEstimateLabels()[lead.workStartEstimate]}
                </div>
              </IonItem>

              <IonItem>
                <div className={Styles.row}>
                  <div>Tender Return Date</div>
                </div>
                <div className={Styles.row}>
                  {lead.tenderReturnDate && DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
                </div>
              </IonItem>
            </>
            : <>
              <IonCardHeader>
                <IonCardSubtitle>LEAD</IonCardSubtitle>
                <IonCardTitle>{lead.title}</IonCardTitle>
              </IonCardHeader>

              <IonItem>
                <IonIcon icon={constructOutline} slot="start" />
                <IonLabel className="ion-text-wrap">
                  <IonLabel>Type</IonLabel>
                  {lead.projectTypes.map(x => <IonChip key={x} >{getEnumLabel(x)}</IonChip>)}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonIcon icon={cashOutline} slot="start" />
                <IonLabel className="ion-text-wrap">
                  <IonLabel>Budget</IonLabel>
                  {(getFlexibleProjectBudgetAsText(lead, myTeam))}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonIcon icon={calendarClearOutline} slot="start" />
                <IonLabel className="ion-text-wrap">
                  <IonLabel>Work Start Estimate</IonLabel>
                  {lead.workStartEstimate && getWorkStartEstimateLabels()[lead.workStartEstimate]}
                </IonLabel>
              </IonItem>

              <IonItem>
                <IonIcon icon={calendarOutline} slot="start" />
                <IonLabel className="ion-text-wrap">
                  <IonLabel>Tender Return Date</IonLabel>
                  {lead.tenderReturnDate && DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}
                </IonLabel>
              </IonItem>

              <IonCardContent>
                <h2>Description</h2>
                {lead.description || ""}
              </IonCardContent>
            </>
        }

        {weaverFlags['MW-2085-ui-updates-to-leads-project-lead-by'].enabled && <>
          <IonItem>
            <div className={Styles.row}>
              <div>Project Led By</div>
            </div>
            <div className={Styles.row}>
              {
                lead.members
                  .filter(member => member.projectRole === ProjectMemberRole.Owner)
                  .filter(uniqueFilter)
                  .map(member => teamTypeLabels[member.team.type])
                  .join(', ')
              }
            </div>
          </IonItem>
        </>}

        {weaverFlags['MW-2085-ui-updates-to-leads'].enabled && <>
          <IonItem>
            {weaverFlags['MW-2116-copy-updates-to-lead']
              ? <div>Further Description</div>
              : <div>Project Description</div>}
          </IonItem>
          <div className={`${Styles.descriptionText} ion-padding-start`}>
            {weaverFlags['MW-2116-copy-updates-to-lead']
              ? lead.description || "none"
              : lead.description || ""}
          </div>
        </>}

        {weaverFlags['MW-2085-ui-updates-to-leads-tender-readiness-copy'].enabled && <>
          <IonItem>
            {weaverFlags['MW-2116-copy-updates-to-lead']
              ? <div className={Styles.tenderReadinessTitle}>Tender Readiness</div>
              : <div className={Styles.tenderReadinessTitle}>Tender readiness</div>}
          </IonItem>
          <IonItem>
            <div className={Styles.tenderReadinessText}>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Local authority permissions</div>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Detailed existing & proposed drawings</div>
              <div><IonIcon color="success" icon={checkmarkCircle} slot="start" /> Weaver tenderers limited to 3</div>
            </div>
          </IonItem>
        </>}

        {weaverFlags['MW-2085-ui-updates-to-leads'].enabled
          ? (
            <div className={Styles.acceptRejectButtons}>
              <IonButton expand='block' disabled={loading} onClick={
                weaverFlags['MW-2215-fix-accept-lead-event-firing']
                  ? acceptLead
                  : routeToContractorLeadAcceptor({ leadId: id })
              } color="primary">
                Accept Lead
              </IonButton>
              <IonButton expand='block' fill="outline" disabled={loading} onClick={rejectLead} color="primary">
                {rejectLoading ? <IonSpinner /> : <>Reject</>}
              </IonButton>
            </div>
          )
          : (
            <div className={Styles.actionButtons}>
              <IonButton disabled={loading} onClick={acceptLead} color="success">
                <IonIcon icon={checkmarkCircleOutline} slot="start" />
                Accept
              </IonButton>
              <IonButton disabled={loading} onClick={rejectLead} color="danger">
                {rejectLoading ? <IonSpinner /> : <><IonIcon icon={closeOutline} slot="start" />Reject</>}
              </IonButton>
            </div>
          )
        }
      </IonCard>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ShowLead
