import { IonDatetime, IonGrid, IonNote, IonRow, isPlatform } from '@ionic/react'
import { DateTime } from 'luxon'
import React from 'react'
import { FieldError } from 'react-hook-form'
import IndicatorBar, { IndicatorBarProps } from '../../../../../../projects/CreateProjectPage/IndicatorBar'
import Styles from './ConstructionDates.module.scss'

type ConstructionStartedDateProps={
  value: string | undefined,
  setValue: (value: string | string[] | undefined | null) => Promise<void> | void,
  error?: FieldError | undefined,
  indicatorBarProps: IndicatorBarProps,
}
export const ConstructionStartedDate: React.FC<ConstructionStartedDateProps> = ({ indicatorBarProps, value , setValue, error  }) => {
  const now = DateTime.now()
  return (
    <>
      <div className='ion-padding-top'>
        <IndicatorBar {...indicatorBarProps} />
      </div>
      <IonGrid fixed>
        <IonRow class="ion-justify-content-center ion-align-items-center">
          <h3 className="ion-padding-vertically ion-padding-bottom"><b>When did the construction start?</b></h3>
        </IonRow>
        <IonRow class="ion-justify-content-center ion-align-items-center">
          <IonDatetime
            value={value}
            max={now.toISO()}
            onIonChange={(e) => setValue(e.detail.value)}
            className={Styles.ionDatetime}
            presentation={isPlatform('desktop') ? 'date' : 'month-year'}
          />
        </IonRow>
        <IonRow>
          <IonNote color={'danger'}>{error && error.message}</IonNote>
        </IonRow>
      </IonGrid>
    </>
  )
}
