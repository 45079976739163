import React from 'react'
import { IonItem, IonLabel } from '@ionic/react'
import {  Task as TaskType } from "./types"
import { getStatusData } from './Task'
import { TaskStatus } from '../../../graphql/generated'
import { alwaysArray } from '../../../common/utils'
import Styles from './TopLevelTask.module.scss'

type componentNameProps = {
  task: TaskType,
}

export const TopLevelTask: React.FC<componentNameProps> = ({ task }) => {
  const { title, status } = task
  const childTasks = alwaysArray(task.childTasks)
  const completed = childTasks.filter(x => x.status == TaskStatus.Completed).length
  const statusData = getStatusData(status)
  return (
    <IonItem className={Styles.ionItem} slot="header" lines='none'>
      <IonLabel className={Styles.ionLabel}>{title}<p className={Styles.ionLabelDescription}>{childTasks.length} Tasks<span>&#x2022;</span>{statusData.label}</p></IonLabel>
    </IonItem>
  )
}
