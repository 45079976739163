import React, { useEffect, useRef } from 'react'

import { useMyIndividual } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { ChatContentListElement, ChatContentListElementType } from "./ChatRoomTypes"
import { getChatRoomContentListElements, getIndexForNewMessageLineElement } from './utils'

import { useChatRoom } from "./services/ChatRoomProvider"
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { VirtuosoActions } from './ChatContent'

type ChatRoomMessages = ReturnType<typeof useChatRoom>['chatRoomMessages']

type UseLoadChatRoomMessageHookProps = {
  setListElements: React.Dispatch<React.SetStateAction<ChatContentListElement[]>>,
  chatRoomMessages: ChatRoomMessages,
}

type UseShowMessagesNewLineHookProps = {
  listElements: ChatContentListElement[],
  setListElements: React.Dispatch<React.SetStateAction<ChatContentListElement[]>>,
  chatRoomMessages: ChatRoomMessages,
  individual: ReturnType<typeof useMyIndividual>,
}

type UseScrollToLastMessageWhenMessagesChangeHookProps = {
  chatRoomMessages: ChatRoomMessages,
  scrollToIndex: VirtuosoActions['scrollToIndex'],
}

type UseShowEmptyStateHookProps = {
  listElements: ChatContentListElement[],
  setListElements: React.Dispatch<React.SetStateAction<ChatContentListElement[]>>,
  chatRoomMessages: ReturnType<typeof useChatRoom>['chatRoomMessages'],
}

export const useLoadChatRoomMessages = ({ setListElements, chatRoomMessages }: UseLoadChatRoomMessageHookProps) =>
  useEffect(() => {
    if (!chatRoomMessages) return
    const _listElements = getChatRoomContentListElements(chatRoomMessages)
    setListElements(_listElements)
  }, [ chatRoomMessages ])

export const useShowNewMessageLineElement = ({ setListElements, chatRoomMessages, individual }: UseShowMessagesNewLineHookProps) => {
  const shouldShowNewMessagesIndicator = useWeaverFlags()['MW-2115-implement-the-new-messages-line-indicator']

  const isFirstTimeShowingNewMessageLine = useRef<boolean>()
  const newMessageLineIndex = useRef<number>(-1)

  useEffect(() => {
    if (!shouldShowNewMessagesIndicator) return
    isFirstTimeShowingNewMessageLine.current = true
    newMessageLineIndex.current = -1
  }, [])

  useEffect(() => {
    if (!shouldShowNewMessagesIndicator) return
    if (!chatRoomMessages) return
    const index = getIndexForNewMessageLineElement(individual.id, chatRoomMessages)

    if (index !== -1 && isFirstTimeShowingNewMessageLine.current ) {
      const listElements = getChatRoomContentListElements(chatRoomMessages, index)
      newMessageLineIndex.current = index
      isFirstTimeShowingNewMessageLine.current = false
      setListElements(listElements)
    } else {
      const listElements = getChatRoomContentListElements(chatRoomMessages, newMessageLineIndex.current)
      setListElements(listElements)
    }
  }, [ chatRoomMessages ])
}

export const useScrollToLastMessageWhenMessagesChange = ({ chatRoomMessages, scrollToIndex }: UseScrollToLastMessageWhenMessagesChangeHookProps) => useEffect(() => {
  const scrollToLastMessage = () => {
    if (chatRoomMessages !== undefined){
      scrollToIndex(chatRoomMessages.length)
    }
  }
  // We need to make sure the last message has rendered on screen before we scroll
  setTimeout(scrollToLastMessage, 1)
}, [ chatRoomMessages?.length ])

export const useClearImagesCacheOnLoad = (emptyCache: () => void) =>
  useEffect(() => {
    // The message images cache is clear every time the chat screen is rendered for the first time
    emptyCache()
  }, [])

export const useShowEmptyState = ({ setListElements, chatRoomMessages }: UseShowEmptyStateHookProps) => {
  const shouldDisplayEmptyState = useWeaverFlags()['MW-2175-basic-empty-state-in-new-chat']

  return useEffect(() => {
    if (!shouldDisplayEmptyState) return

    if (!chatRoomMessages) return

    const listElements = getChatRoomContentListElements(chatRoomMessages)
    setListElements( [ { elementType: ChatContentListElementType.EmptyState }, ...listElements ])
  }, [ chatRoomMessages ])
}

