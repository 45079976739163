import { IonButton } from '@ionic/react'
import { Duration } from 'luxon'
import React, { ComponentProps } from 'react'
import { useGraphQLDataSource } from '../../api/graphql'
import Styles from "./NextTaskButton.module.scss"

import { sortBy, multiSort } from '../../common/sort'
import { useListProjectTasksQuery } from '../../graphql/generated'
import { pageConfig_TaskActioner, useRouteTo } from '../../routes'
import Task from './checklist/Task'
import { useActionableTaskFilter, unpackTasksWithDepth } from './taskUtils'

type NextTaskButtonProps = {
  projectId: string,
  /** variant=`detailed` displays additional information, like the icon, in a larger display. `simple` is just an ionButton
   *
   * `detailed` does not use an IonButton and so ionbutton props are ignored
   */
  variant?: "detailed" | "simple",
} & ComponentProps<typeof IonButton>

const defaultIonButtonProps: ComponentProps<typeof IonButton> = {
  expand: "block",
}

const PROJECT_TASKS_STALETIME = Duration.fromObject({ minutes: 5 }).as('milliseconds')

const NextTaskButton: React.FC<NextTaskButtonProps> = ({ projectId, variant = "simple", ...rawButtonProps }) => {
  const buttonProps = {
    ...defaultIonButtonProps,
    ...rawButtonProps,
  }

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const isTaskActionable = useActionableTaskFilter()

  const nextPendingTaskQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    staleTime: PROJECT_TASKS_STALETIME,
    select: (data) => {
      const tasks = unpackTasksWithDepth(data.listProjectTasks).filter(x => {
        if (!isTaskActionable(x)) return false

        // top-level tasks (Shortlisting, Hiring, Tendering) are never actionable
        if (x.depth === 0) return false

        return true
      })

      return tasks[0]
    },
  })
  const nextTask = nextPendingTaskQuery.data
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)
  if (nextPendingTaskQuery.isLoading || !nextTask) return null

  const handleClick = () => {
    routeToTaskActioner({ taskId: nextTask.id })()
  }
  if (variant === "detailed") {
    return <div className={Styles.detailedContainer}><Task task={nextTask} onAction={handleClick} /></div>
  }

  return <IonButton {...buttonProps} onClick={handleClick}>{nextPendingTaskQuery.data.title}</IonButton>

}

export default NextTaskButton
