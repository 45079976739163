import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"
import { closeCircle } from 'ionicons/icons'

type ThumbnailPhotoSuccededProps = {
  photoUrl: string,
  altText?: string,
  photoSizeSmall?: boolean,
  onThumbnailClicked?: () => void,
  onCloseClicked?: () => void,
}

const ThumbnailPhotoSucceded: React.FC<ThumbnailPhotoSuccededProps> = ({ photoUrl, altText, onThumbnailClicked, onCloseClicked, photoSizeSmall = false }) => {

  return (
    <div className={`${photoSizeSmall ? Styles.thumbnailPhotoContainerSmall : Styles.thumbnailPhotoContainer} ${Styles.success}`} onClick={() => { onThumbnailClicked && onThumbnailClicked() }}>
      <img className={`${photoSizeSmall ? Styles.thumbnailPhotoSmall : Styles.thumbnailPhoto}`} src={photoUrl} alt={altText || "Thumbnail"} />
      {
        onCloseClicked &&  <IonButton fill="clear" size="small" className={Styles.topRightIcon} onClick={(e) => { e.stopPropagation(); onCloseClicked() }}>
          <IonIcon icon={closeCircle}  />
        </IonButton>
      }
    </div>
  )
}

export default ThumbnailPhotoSucceded
