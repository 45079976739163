import React, { useState } from 'react'
import GlobalHeader from '../../../../common/components/GlobalHeader'
import WeaverIonPage from '../../../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { IonButton, IonIcon, useIonRouter } from '@ionic/react'
import { add, arrowBackOutline, warning } from 'ionicons/icons'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { ContentDisposition, TeamType, useGetTeamQuery, useShowProjectQuery } from '../../../../graphql/generated'
import LoadingSpinnerPage from '../../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { useMyIndividualActiveTeam } from '../../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import ErrorBlockPage from '../../../../common/components/ErrorBlock/ErrorBlockPage'
import Styles from "./ChatRoomOptions.module.scss"
import ChatRoomMemberBubble from './ChatRoomMemberBubble'
import { useParamsFromPageConfig } from '../../../../routesProvider'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import { isEverybodyAMember, getInvitationDisclaimer, getRoleToInvite, getAdditionalTeamToCreateGroupChatRoom } from './utils'
import InviteMemberModal from '../../../../common/components/InviteMemberModal/InviteMemberModal'
import { QueryClient } from '@tanstack/react-query'

type InviteState = {
  requiredTeamType: TeamType.Homeowner | TeamType.Architect,
}

import { ChatRoomOptionsTeam } from './types'
import { getChatName } from '../utils'
import { useChatRoomFunctions } from '../../../../api/services/chat/useChatRoomFunctions'
import { ChatRoomOptionsChatButton } from './ChatRoomOptionsChatButton'
import { teamTypeLabels } from '../../../../common/components/InviteMemberModal/inviteMemberTeamTypeLabels.i18n'

export const ChatRoomOptions: React.FC = () => {
  const router = useIonRouter()
  const queryClient = new QueryClient()

  const { projectId, teamId: teamToChatId } = useParamsFromPageConfig<{projectId: string, teamId: string}>()

  const projectVariables = {
    id: projectId,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope: false,
  }

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectQuery = useShowProjectQuery(gqlDataSource, projectVariables, { refetchOnWindowFocus: false })
  const getTeamQuery = useGetTeamQuery(gqlDataSource, { teamId: teamToChatId } )
  const myTeam = useMyIndividualActiveTeam()
  const { createChatRoom } = useChatRoomFunctions()

  if (!myTeam) throw new Error("Can't render this component without an active team")

  const [ inviteModalState, setInviteModalState ] = useState<InviteState| undefined>(undefined)

  if (showProjectQuery.isFetching && !showProjectQuery.data) return <LoadingSpinnerPage name="ChatRoomOptions"/>

  if (getTeamQuery.isFetching && !getTeamQuery.data) return <LoadingSpinnerPage name="ChatRoomOptions"/>

  if (!myTeam) return <LoadingSpinnerPage name="ChatRoomOptions" />

  if (showProjectQuery.error || !showProjectQuery.data) return <ErrorBlockPage name='ChatRoomOptions' onRefresh={showProjectQuery.refetch} />

  if (getTeamQuery.error || !getTeamQuery.data) return <ErrorBlockPage name='ChatRoomOptions' onRefresh={getTeamQuery.refetch} />

  const teamToChat = getTeamQuery.data.getTeam

  const { getProject: project } = showProjectQuery.data
  const members = project.members
  const invites = project.memberInvites

  const canGroupChatBeEnabled = isEverybodyAMember(project)
  const roleToInvite = getRoleToInvite(project, myTeam.type, teamToChat.type)
  const canInviteButtonBeDisplayed = roleToInvite !== undefined
  const invitationDisclaimer = getInvitationDisclaimer(project, myTeam.type, teamToChat.type)

  // additionalTeamToCreateGroupChatRoomType is always Architect or Homeowner
  const additionalTeamToCreateGroupChatRoomType =  getAdditionalTeamToCreateGroupChatRoom(myTeam.type, teamToChat.type)
  const additionalTeamToCreateGroupChatRoomInvite = invites.find(memberInvite => memberInvite.requiredTeamType === additionalTeamToCreateGroupChatRoomType)
  const additionalTeamToCreateGroupChatRoomMember = members.find(member => member.team?.type === additionalTeamToCreateGroupChatRoomType)

  const createChatRoomBetweenTeams = (teams: ChatRoomOptionsTeam[]) => createChatRoom({
    name: getChatName(teams, project.title),
    teamIds: teams.map(team => team.id),
    projectId: project.id,
  })

  const handleInviteSuccess = async () => {
    await queryClient.invalidateQueries(useShowProjectQuery.getKey(projectVariables))
    setInviteModalState(undefined)
  }

  const inviteTeamRole = (roleToInvite: TeamType) => {
    if (roleToInvite !== TeamType.Architect && roleToInvite !== TeamType.Homeowner) return

    setInviteModalState({ requiredTeamType: roleToInvite })
  }

  return (
    <WeaverIonPage id='ChatRoomOptions' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader
          pageTitle={"Chat Options"}
          navElement={
            <IonButton onClick={ () => { router.goBack() }}>
              <IonIcon slot="start" icon={arrowBackOutline} />
            </IonButton>
          }
        />
      </WeaverIonHeader>
      <WeaverIonContent>

        <div className='mainContainer'>
          <div className={Styles.whoWillBeInTheChatContainer}>
            <span>Who will be in the chat</span>

            <div className={Styles.membersContainer}>
              {
                invitationDisclaimer && <span>{invitationDisclaimer}</span>
              }

              <div className={Styles.membersBubblesContainer}>
                <ChatRoomMemberBubble key={myTeam.id} isInvitationPending={false} chatRoomMemberFullName={`${myTeam.name}`} />
                <ChatRoomMemberBubble key={teamToChat.id} isInvitationPending={false} chatRoomMemberFullName={`${teamToChat.name}`} />

                {/* If the additional team is a member. */}
                {
                  additionalTeamToCreateGroupChatRoomMember && <ChatRoomMemberBubble key={additionalTeamToCreateGroupChatRoomMember.id} isInvitationPending={false} chatRoomMemberFullName={`${additionalTeamToCreateGroupChatRoomMember.team.name}`} />
                }
                {/* If the additional team has been invited. */}
                {
                  additionalTeamToCreateGroupChatRoomInvite && <ChatRoomMemberBubble key={additionalTeamToCreateGroupChatRoomInvite.id} isInvitationPending={true} chatRoomMemberFullName={additionalTeamToCreateGroupChatRoomInvite.team?.name || additionalTeamToCreateGroupChatRoomInvite.companyName || teamTypeLabels[additionalTeamToCreateGroupChatRoomInvite.requiredTeamType]
                  } />
                }
              </div>
            </div>
            {
              canInviteButtonBeDisplayed && <SingleClickButton fill='outline' color='primary' expand='block' onClick={() => inviteTeamRole(roleToInvite)}><IonIcon slot='start' icon={add}/>Invite {roleToInvite}</SingleClickButton>
            }
            {
              <ChatRoomOptionsChatButton
                renderButton={(goToChatRoomCreateIfNecessary) => {
                  return canGroupChatBeEnabled
                    ? <SingleClickButton expand='block' className={Styles.groupChatButton} onClick={() => goToChatRoomCreateIfNecessary()}>Group Chat</SingleClickButton>
                    : <SingleClickButton expand='block' className={Styles.groupChatButton} onClick={() => goToChatRoomCreateIfNecessary()} color='light' disabled={true}><IonIcon slot='start' icon={warning}/>Group Chat</SingleClickButton>
                }}
                teams={ additionalTeamToCreateGroupChatRoomMember ? [ myTeam, teamToChat, additionalTeamToCreateGroupChatRoomMember.team ] : []}
                projectId={project.id}
                createChatRoom={createChatRoomBetweenTeams}
              />
            }
          </div>

          <div className={Styles.whoWillBeInTheChatContainer}>
            <span>Who will be in the chat</span>
            <div className={Styles.membersContainer}>
              <div className={Styles.membersBubblesContainer}>
                <ChatRoomMemberBubble key={teamToChat.id} isInvitationPending={false} chatRoomMemberFullName={teamToChat.name} />
              </div>
            </div>

            <ChatRoomOptionsChatButton
              renderButton={(goToChatRoom) => {
                return <SingleClickButton className={Styles.privateChatButton} onClick={() => goToChatRoom()} expand='block'>Private Chat</SingleClickButton>
              }}
              teams={[ myTeam, teamToChat ]}
              projectId={project.id}
              createChatRoom={createChatRoomBetweenTeams}
            />

          </div>
        </div>
      </WeaverIonContent>
      <InviteMemberModal analyticsSource={"chatOptions"} isOpen={!!inviteModalState} projectId={project.id} requiredTeamType={inviteModalState?.requiredTeamType} onDidDismiss={() => setInviteModalState(undefined)} onSuccess={handleInviteSuccess} />
    </WeaverIonPage>
  )
}
