import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../graphql"
import {  GetChatRoomMessagesQuery, MessageSentInfo, SendChatRoomMessage, SignedUrlStatus, UploadedFileStatus, useGetChatRoomWithMyMessagesQuery, useSendChatRoomMessageMutation } from "../../../graphql/generated"
import { CreateUploadedFileReturns } from "../../../domains/projects/documents/uploadableFileChip/UploadableFileChip"
import { sortBy } from "../../../common/sort"
import { alwaysArray, isSomething } from "../../../common/utils"
import { StorableValue, useStoredValue } from "../../providers/StorageProvider"
import { useAblyPartyLine, useAblyPartyLinePublish } from "../../providers/AblyProvider/useAblyPartyLine"
import { z } from "zod"
import { useMyIndividual } from "../../providers/MyIndividualProvider/MyIndividualProvider"
import { useCallback } from "react"
import { useWeaverFlags } from "../../thirdParty/launchDarkly/useWeaverFlags"

const PACKAGE = 'api.services.chat'

/**
 * Represents the different sources the messages can come from.
 */
enum MessageSource {
  Server = "server", // From the GraphQL Server
  Partyline = "partyline", // From Ably's partyline or channel
}

/**
 * Zod's objects
 */
// This is the base chat room message model which represents the critical chat room fields for display

const zChatRoomMessageDocument = z.object({
  id: z.string(),
  status: z.nativeEnum(UploadedFileStatus).nullable().optional(),
  fileName: z.string().nullable().optional(),
  fileSizeInBytes: z.number().nullable().optional(),
  fileContentType: z.string().nullable().optional(),
  signedUrlForDownload:  z.object({
    status: z.nativeEnum(SignedUrlStatus),
    url: z.string().nullable().optional(),
  }),
  signedUrlForUpload:  z.object({
    status: z.nativeEnum(SignedUrlStatus),
    url: z.string().nullable().optional(),
  }),
})

const zBaseChatRoomMessage = z.object({
  idFromClient: z.string(),
  documentCount: z.number().nullable().optional(),
  documents: z.array(zChatRoomMessageDocument).nullable().optional(),
  content: z.string(),
  readAt: z.string().nullable().optional(),
  sentAt: z.string(),
  sentByIndividual: z.object({
    id: z.string(),
    familyName: z.string(),
    givenName: z.string(),
    pictureURL: z.string(),
  }),
  receivedByIndividual: z.object({
    id: z.string(),
    familyName: z.string(),
    givenName: z.string(),
    pictureURL: z.string(),
  }).optional(),
})

// These are the extra fields which come when we publish party line message (currently nothing)
const zPartyLineChatRoomMessage = zBaseChatRoomMessage.extend({
  _source: z.literal(MessageSource.Partyline),
})

// These are the extra fields which come when we query the server
const zServerChatRoomMessage = zBaseChatRoomMessage.extend({
  _source: z.literal(MessageSource.Server),
  id: z.string(),
})

/**
 * Types infer from Zod's objects
 */
type BaseChatRoomMessage = z.infer<typeof zBaseChatRoomMessage>
type ServerChatRoomMessage = z.infer<typeof zServerChatRoomMessage>
type PartyLineChatRoomMessage = z.infer<typeof zPartyLineChatRoomMessage>
// The union of the above is stored in the cache
type CachedChatRoomMessage = ServerChatRoomMessage | PartyLineChatRoomMessage
export type ChatRoomMessage = BaseChatRoomMessage

/**
 * Other types
 */
export type UseRealtimeChatMessagesturns = {
  chatRoomMessages: ChatRoomMessage[] | undefined,
  sendChatRoomMessage: TMutationSendChatRoomMessageFn,
}

type QueryChatRoomMessages = NonNullable<GetChatRoomMessagesQuery['getChatRoomMessages']>
type QueryChatRoomMessage = QueryChatRoomMessages[number]

export type TMutationSendChatRoomMessageFn = (message: SendChatRoomMessage, documents: CreateUploadedFileReturns[]) => Promise<MessageSentInfo | undefined | null>

export type TMutationCreateChatRoomMessageDocumentFn = (messageDocument: CreateUploadedFileReturns) => Promise<CreateUploadedFileReturns | undefined>

export type TMutationArchiveChatRoomMessageDocumentFn = (messageId: string) => void

const markMessageAsFromServer = (message: QueryChatRoomMessage): ServerChatRoomMessage => ({ ...message, _source: MessageSource.Server })

const isMessageFromServer = (message: CachedChatRoomMessage): message is ServerChatRoomMessage => message._source === MessageSource.Server

/**
 * Reconcilies the cached messages with the incoming messages from the server. First, it replaces all the cached messages where id or idFromClient has authoritively come from the server.
 * Then it caches those server messages that don't exist in the cache currently.
 * @param messagesFromServer The messages coming from the GraphQL server.
 * @param chatRoomMessagesCache The current cached messages.
 * @returns
 */
const reconcileCachedMessagesWithServerMessages = (messagesFromServer: ServerChatRoomMessage[], chatRoomMessagesCache: StorableValue<CachedChatRoomMessage[]>): CachedChatRoomMessage[] => {
  const messagesFromServerIds = messagesFromServer.map(each => each.id)
  const messagesFromServerIdFromClient = messagesFromServer.map(each => each.idFromClient)

  // Replace all the cached messages where id / idFromClient has authoritively come from the server
  const cacheWithReplacedMessages: CachedChatRoomMessage[] = alwaysArray(chatRoomMessagesCache)
    .map(
      (cachedMessage) => {
        if (isMessageFromServer(cachedMessage) && messagesFromServerIds.includes(cachedMessage.id)) {
          return messagesFromServer.find(newMessage => cachedMessage.id === newMessage.id)
        } else {
          if (messagesFromServerIdFromClient.includes(cachedMessage.idFromClient)) {
            return messagesFromServer.find(newMessage => cachedMessage.idFromClient === newMessage.idFromClient)
          } else {
            return cachedMessage
          }
        }
      },
    )
    .filter(isSomething)

  // Add any messages where the id does not exist in the cache
  const cacheWithReplacedMessagesId = cacheWithReplacedMessages.filter(isMessageFromServer).map(each => each.id)
  const uncachedNewMessages = messagesFromServer.filter(each => !cacheWithReplacedMessagesId.includes(each.id))
  const cacheWithReplacedAndNewMessages: CachedChatRoomMessage[] = [ ...cacheWithReplacedMessages, ...uncachedNewMessages ]

  return cacheWithReplacedAndNewMessages
}

export const useRealtimeChatMessages = ({ chatRoomId }: { chatRoomId: string }): UseRealtimeChatMessagesturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useRealtimeChatMessages`

  const myIndividual = useMyIndividual()
  const weaverFlags = useWeaverFlags()

  // Load and Init the cache
  const [ chatRoomMessagesCache, setChatRoomMessagesCache ] = useStoredValue<CachedChatRoomMessage[]>({
    key: `chatRoomMessageCache:${chatRoomId}`,
    initialValue: [],
  })

  // Get data from the server
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const includeMessagesWithUnarchivedDocuments = (chatRoomMessage: ChatRoomMessage) => {
    if (!chatRoomMessage.documents || chatRoomMessage.documents.length === 0){
      return chatRoomMessage
    }

    return { ...chatRoomMessage, documents: [ chatRoomMessage.documents.filter(document => document.status !== UploadedFileStatus.Archived ) ] }
  }

  const getChatRoomWithMessagesQuery = useGetChatRoomWithMyMessagesQuery(gqlDataSource, { chatRoomId }, {
    enabled: chatRoomMessagesCache !== undefined, // Wait for the cache to load / init
    staleTime: weaverFlags['MW-2131-chat-room-new-messages-not-displayed-on-load'] ? undefined :  5 * 60 * 1000 /* 5 minutes */,
    onSuccess: dataFromServer => {
      const messagesFromServer: ServerChatRoomMessage[] = alwaysArray(dataFromServer.getChatRoom?.messages).filter(includeMessagesWithUnarchivedDocuments).map(markMessageAsFromServer)
      const cacheWithReplacedAndNewMessages = reconcileCachedMessagesWithServerMessages(messagesFromServer, chatRoomMessagesCache)
      // Sort and cache
      cacheWithReplacedAndNewMessages?.sort(sortBy(each => each.sentAt))
      setChatRoomMessagesCache(cacheWithReplacedAndNewMessages)

      return messagesFromServer
    },
  })

  // Add an invalidator so that we refetch from the server when we know updates have happened
  const queryClient = useQueryClient()
  const onSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [ 'getChatRoomMessages', { chatRoomId } ],
    })
  }

  // Connect to the PartyLine
  useAblyPartyLine({
    partyLineId: chatRoomId,
    onMessageFn: useCallback(async message => {
      try {
        const messageData = zPartyLineChatRoomMessage.parse(message.data)

        // Check I'm ready to run
        if (myIndividual == null || chatRoomMessagesCache === undefined) {
          console.debug(`[${LOCAL_PACKAGE}] Ignoring party line message as I am not ready: `, { chatRoomId, message, myIndividual, chatRoomMessagesCache: chatRoomMessagesCache })
          return
        }

        // Check to see if this is a duplicate by the idFromClient
        const chatRoomMessagesIdFromClient = chatRoomMessagesCache.map(each => each.idFromClient)
        if (chatRoomMessagesIdFromClient.includes(messageData.idFromClient)) {
          // Replace the duplicate
          const replacedChatRoomMessagesCache = chatRoomMessagesCache.map(cachedMessage =>
            cachedMessage.idFromClient === messageData.idFromClient
              ? messageData
              : cachedMessage,
          )
          setChatRoomMessagesCache(replacedChatRoomMessagesCache)
        } else {
          // Add to the end
          setChatRoomMessagesCache([ ...chatRoomMessagesCache, messageData ])
        }

        // The query is refetch in order to get trigger the reconciliation and get the attributes set by the server (e.g.: id)
        if ( weaverFlags['MW-2115-implement-the-new-messages-line-indicator'].enabled ) await getChatRoomWithMessagesQuery.refetch()

      } catch (error) {
        console.error("[useCachedAuth] Unable to decode message from Ably: ")
        throw error
      }
    }, [ chatRoomMessagesCache, setChatRoomMessagesCache ]),
  })

  const ablyPartyLinePublish = useAblyPartyLinePublish({ partyLineId: chatRoomId })

  const mutationSendChatRoomMessage = useSendChatRoomMessageMutation(gqlDataSource, { onSuccess })

  const sendChatRoomMessage = async (sendMessage: SendChatRoomMessage, documents: CreateUploadedFileReturns[]) => {
    // Check I'm ready to run
    if (myIndividual == null || chatRoomMessagesCache === undefined) {
      console.debug(`[${LOCAL_PACKAGE}] Ignoring new message as I am not ready: `, { chatRoomId, sendMessage, myIndividual, chatRoomMessagesCache })
      return
    }
    // Augment SendChatRoomMessage for the Party Line
    const chatMessage: PartyLineChatRoomMessage = {
      ...sendMessage,
      sentByIndividual: myIndividual,
      _source: MessageSource.Partyline,
      documents,
    }

    sendMessage.documentIds = documents.map(doc => doc.id)

    // Send the SendChatRoomMessage to the server
    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the server `, { sendMessage })
    const result = await mutationSendChatRoomMessage.mutateAsync({ message: sendMessage })

    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the partyline `, { chatMessage })
    ablyPartyLinePublish({ message: chatMessage })

    return result.sendChatRoomMessage
  }

  const sendChatRoomMessageDeprecated = async (sendMessage: SendChatRoomMessage, documents: CreateUploadedFileReturns[]) => {
    // Check I'm ready to run
    if (myIndividual == null || chatRoomMessagesCache === undefined) {
      console.debug(`[${LOCAL_PACKAGE}] Ignoring new message as I am not ready: `, { chatRoomId, sendMessage, myIndividual, chatRoomMessagesCache })
      return
    }
    // Augment SendChatRoomMessage for the Party Line
    const chatMessage: PartyLineChatRoomMessage = {
      ...sendMessage,
      sentByIndividual: myIndividual,
      _source: MessageSource.Partyline,
      documents,
    }

    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the partyline `, { chatMessage })
    ablyPartyLinePublish({ message: chatMessage })

    sendMessage.documentIds = documents.map(doc => doc.id)

    // Send the SendChatRoomMessage to the server
    console.log(`[${LOCAL_PACKAGE}.sendChatRoomMessage] Sending to the server `, { sendMessage })
    const result = await mutationSendChatRoomMessage.mutateAsync({ message: sendMessage })
    return result.sendChatRoomMessage
  }

  return {
    chatRoomMessages: chatRoomMessagesCache,
    sendChatRoomMessage: weaverFlags['MW-2115-implement-the-new-messages-line-indicator'].enabled ? sendChatRoomMessage: sendChatRoomMessageDeprecated ,
  }
}

