import React from 'react'
import { IonButton, IonIcon } from '@ionic/react'
import { refreshOutline, warningOutline } from 'ionicons/icons'
import CentralisedBlock from '../CentralisedBlock'

interface ContainerProps {
  name: string,
  onRefresh?: () => void,
}

const ErrorBlock: React.FC<ContainerProps> = ({ onRefresh, children = <p>Oops! That didn&apos;t work.</p> }) =>
  <CentralisedBlock>
    <IonIcon icon={warningOutline} size="large" color="danger" />
    {children}
    {onRefresh && <p><IonButton onClick={onRefresh} size="small" color="danger"><IonIcon icon={refreshOutline} /> Try Again</IonButton></p>}
  </CentralisedBlock>

export default ErrorBlock
