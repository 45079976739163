import { useEnvironmentContext } from "../../api/providers/EnvironmentProvider"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { ensureTrailingSlash } from "../../common/utils"
import { makeMoneyFlexible, MoneyRange, moneyToText } from "../../common/utils/currency"
import { getEnumValueFromString } from "../../common/utils/enum"
import { BudgetCategory, Money, ShowLeadQuery, ShowProjectQuery, TeamType } from "../../graphql/generated"

/** returns a function for generating the right invite url, dependent on the environment context */
export const useGetInviteUrl = () => {
  const env = useEnvironmentContext()
  return (inviteId: string) => `https://${ensureTrailingSlash(env.app.domain)}?claimInviteId=${encodeURIComponent(inviteId)}`
}

/** We'll take either anything that represents the `budgetValue` / `budgetCategory` of the underlying `Project` */
type ShowProjectOrLead = Pick<ShowLeadQuery['getLead'] | ShowProjectQuery['getProject'], 'budgetValue' | 'budgetCategory'>
type MyTeam = ReturnType<typeof useMyIndividualActiveTeam>

export const getFlexibleProjectBudgetAsMoneyOrMoneyRange = (projectOrLead: ShowProjectOrLead, team: MyTeam): Money | MoneyRange => {
  // These teams always see the exact budget
  if (team !== undefined && [ TeamType.Architect, TeamType.Homeowner, TeamType.Weaver ].includes(team.type)) {
    return projectOrLead.budgetValue
  }

  // Get the enum value for the BudgetCategory
  const flexPercentages: Record<BudgetCategory, number> = {
    Exact: 0,       // 0%
    RoughIdea: 10,  // 10%
    NoIdea: 20,     // 20%
  }

  const budgetCategory = getEnumValueFromString(BudgetCategory, projectOrLead.budgetCategory)
  if (!budgetCategory) throw new Error(`Unknown BudgetCategory: ${projectOrLead.budgetCategory}`)

  // Show the appropriate level of accuracy
  return makeMoneyFlexible(projectOrLead.budgetValue, flexPercentages[budgetCategory])
}

export const getFlexibleProjectBudgetAsText = (projectOrLead: ShowProjectOrLead, team: MyTeam): string | null | undefined => {
  const moneyOrMoneyRange = getFlexibleProjectBudgetAsMoneyOrMoneyRange(projectOrLead, team)
  if ('currency' in moneyOrMoneyRange) {
    return moneyToText(moneyOrMoneyRange)
  } else {
    const { rangeBottom, rangeTop } = moneyOrMoneyRange
    if (rangeBottom === rangeTop) {
      return `up to ${moneyToText(rangeTop)}`
    } else {
      return `${moneyToText(rangeBottom)} - ${moneyToText(rangeTop)}`
    }
  }
}
