import { IonAlert, IonButton, IonInput, IonItem, IonText, useIonRouter } from '@ionic/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetArchitectProfileQuery, useUpdateArchitectProfileMutation } from '../../../graphql/generated'
import EditProfileHeader from '../EditProfileHeader'
import Styles from '../EditProfile.module.scss'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'

type EditArchitectProfileInputs = {
  companyTradingAs: string,
}

const EditArchitectProfileSchema = z.object({
  companyTradingAs: z.string().nonempty('Required'),
})

type EditArchitectProfilePageProps = {
  id : string,
}

const EditArchitectProfilePage: React.FC<EditArchitectProfilePageProps> = ({ id }) => {
  const queryClient = useQueryClient()
  const [ showUnsuccessfulAlert, setShowUnsuccessfulAlert ] = useState(false)
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { data, error, isFetching, refetch } = useGetArchitectProfileQuery(gqlDataSource, { architectTeamId: id })
  const updateArchitectProfile = useUpdateArchitectProfileMutation(gqlDataSource)
  const router = useIonRouter()

  const { register, handleSubmit, setValue, formState: { errors } } = useForm<EditArchitectProfileInputs>({ resolver: zodResolver(EditArchitectProfileSchema) })

  if (isFetching) {
    return <LoadingSpinnerPage name="EditArchitectProfilePage" />
  }

  if (error) {
    console.error(`[EditArchitectProfilePage] Failed to load Trading As: `, {  data, error })
    return <ErrorBlockPage name='EditArchitectProfilePage' onRefresh={refetch} />
  }

  if (data?.getArchitectProfile?.companyTradingAs == null) {
    console.warn(`[EditArchitectProfilePage] Trading As is missing: `, { data, error })
    return <ErrorBlockPage name='EditArchitectProfilePage'><p>Unable to find the Architect Profile details.</p></ErrorBlockPage>
  }

  const { companyTradingAs } = data.getArchitectProfile
  setValue('companyTradingAs', companyTradingAs)
  const goBack = () => router.goBack()

  const onSubmit = async (data: EditArchitectProfileInputs) => {
    await updateArchitectProfile.mutateAsync({ input: { id, companyTradingAs: data.companyTradingAs } }, {
      onSuccess: () => {
        queryClient.invalidateQueries(useGetArchitectProfileQuery.getKey({ architectTeamId: id }))
        goBack()
      },
      onError: () => {
        setShowUnsuccessfulAlert(true)
      },
    })
  }

  return <WeaverIonPage id='EditArchitectProfilePage'>
    <WeaverIonHeader>
      <EditProfileHeader title='Trading Name' />
    </WeaverIonHeader>
    <WeaverIonContent>
      <IonText>
        <h5> Change your Trading Name</h5>
      </IonText>
      <IonText className={Styles.lightGrey}>
        <p> This is the name clients will see on your profile </p>
      </IonText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <IonItem className={Styles.ionItemInput} lines="none">
          <IonInput type='text' {...register("companyTradingAs", { required: true }) } />
        </IonItem>

        <IonItem lines='none' className={`${Styles.ionItem} ion-no-padding`}>
          {errors.companyTradingAs &&<IonText color='danger'>
            <p>This field is required</p>
          </IonText>
          }
        </IonItem>

        <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
          <IonButton type='submit' className={`${Styles.confirmButton} ion-no-padding`}>CONFIRM</IonButton>
        </IonItem>

        <IonAlert
          isOpen={showUnsuccessfulAlert}
          onDidDismiss={() => setShowUnsuccessfulAlert(false)}
          header={'Oops! Something has gone wrong.'}
          buttons={[ 'Dismiss' ]}
        />
      </form>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default EditArchitectProfilePage
