import React from 'react'
import { IonBadge, IonButton, IonCard, IonCardContent, IonGrid, IonIcon, IonItem, IonItemDivider, IonLabel, IonList, IonRow, IonText, IonThumbnail } from '@ionic/react'
import { DateTime } from 'luxon'
import { useGraphQLDataSource } from '../../api/graphql'
import { TeamType, useListMyProjectsAndLeadsQuery } from '../../graphql/generated'
import ProjectAvatar from './ProjectAvatar'
import Styles from './ProjectsIndexPage.module.scss'
import GlobalHeader from '../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useMyIndividualActiveTeam } from '../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { ReactComponent as ConstructionWorkerIcon } from "../../assets/icons/twemoji-construction-worker.svg"
import { getKnownRoutePathForPage, Page, pageConfig_ShowLead, useRouteTo } from '../../routes'
import WeaverIonPage from '../../common/components/WeaverIonWrappers/WeaverIonPage'
import WeaverIonContent from '../../common/components/WeaverIonWrappers/WeaverIonContent'
import LoadingSpinnerPage from '../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../common/components/ErrorBlock/ErrorBlockPage'
import WeaverIonHeader from '../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { getEnumLabel } from '../../common/utils'
import { getFlexibleProjectBudgetAsMoneyOrMoneyRange } from './common'
import { flexibleBudgetTextToSignificantFigures } from '../../common/utils/budgetTransformation'
import { arrowForwardSharp } from 'ionicons/icons'
import StaticGoogleMap from '../../common/components/StaticGoogleMap/StaticGoogleMap'
import { useWeaverFlags } from '../../api/thirdParty/launchDarkly/useWeaverFlags'
import NextTopLevelTaskDisplay from './NextTopLevelTaskDisplay'
import NextTaskButton from './NextTaskButton'

const NO_LEADS_DESCRIPTION = 'New Leads will show up here.'
const NO_PROJECTS_DESCRIPTION_CONTRACTOR = 'You don\'t have any active projects yet. Accept leads to be put forward to a project shortlist.'
const NO_PROJECTS_DESCRIPTION_OWNER = 'You don\'t have any active projects yet. Your projects will appear here.'

function timeAgo(date: string): string {
  const now = DateTime.local()
  const past = DateTime.fromISO(date)
  const diff = now.diff(past, 'hours')
  return `${Math.floor(diff.hours)} hours ago`
}

const ProjectsIndexPage: React.FC = () => {
  const currentTeam = useMyIndividualActiveTeam()
  const weaverFlags = useWeaverFlags()
  const goToLead = useRouteTo(pageConfig_ShowLead.path)

  const myTeam = useMyIndividualActiveTeam()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const { isFetching, error, data, refetch } = useListMyProjectsAndLeadsQuery(gqlDataSource, {}, { refetchOnWindowFocus: false })

  if (isFetching) {
    return <LoadingSpinnerPage name="ProjectsIndex"/>
  }

  if (error || !data) {
    return <ErrorBlockPage name='ProjectsIndexPage' onRefresh={refetch} />
  }

  const projects = data.listMyProjects
  const leads = data.listMyLeads
  const newLeads = leads.filter(x => !x.lastAccessed)

  const renderLeadsSection = () => {
    if (currentTeam?.type !== TeamType.Contractor) return null

    if (leads.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <IonLabel>Leads</IonLabel>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <p>{NO_LEADS_DESCRIPTION}</p>
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )

    return (
      <>
        {weaverFlags["MW-2107-ui-updates-to-lead-list"]
          ? <div className={`${Styles.label} ion-padding-start`}>
            Leads {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}
          </div>
          : <IonItemDivider>
            <IonLabel>Leads {newLeads.length > 0 ? <IonBadge>{newLeads.length}</IonBadge> : null}</IonLabel>
          </IonItemDivider>
        }
        {weaverFlags["MW-2107-ui-updates-to-lead-list"]
          ? <>
            {leads.map((lead) =>
              <IonCard className={Styles.leadCard} key={lead.id} onClick={goToLead({ id: lead.id })}>
                <div className={Styles.leadsContainer}>
                  {
                    lead.address?.postCode &&
                    <IonThumbnail slot="start">
                      <div className={Styles.leadStaticGoogleMapContainer}>
                        <StaticGoogleMap
                          location={lead.address?.postCode}
                          size={'150x150'}
                          zoom={11}
                          scale={1}
                        />
                      </div>
                    </IonThumbnail>
                  }
                  <IonGrid>
                    <div className={Styles.leadHeader}>
                      {`Lead - ${lead.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                    </div>
                    <IonRow>
                      <div className={Styles.leadSubtitle}>
                        {`${lead.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                      </div>
                    </IonRow>
                    <IonRow>
                      <div className={Styles.leadCopyContainer}>
                        <div className={Styles.date}>
                          {lead.tenderReturnDate && `${DateTime.fromISO(lead.tenderReturnDate).toLocaleString(DateTime.DATE_MED)}`.slice(0,-4)}
                        </div>
                        <div className={Styles.divider}>
                          |
                        </div>
                        <div className={Styles.money}>
                          {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(lead, myTeam))}
                        </div>
                      </div>
                      <div className={Styles.arrowIcon}>
                        <IonIcon color='primary' size='small' icon={arrowForwardSharp}/>
                      </div>
                    </IonRow>
                  </IonGrid>
                </div>
              </IonCard>,
            )}
          </>
          : <>
            {leads.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/leads/${id}`}>
              <ProjectAvatar slot="start" title={title} />
              <IonLabel>
                {title} {!lastAccessed ? <IonBadge slot="end" color='success'>New</IonBadge> : null }
                {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
              </IonLabel>
            </IonItem>,
            )}
          </>
        }
      </>
    )

  }

  const renderProjectsSection = () => {
    const isContractor = currentTeam?.type === TeamType.Contractor
    if (projects.length < 1) return (
      <div className={Styles.noResultsContainer}>
        <div className={`${Styles.label} ion-padding`}>Projects</div>
        <IonCard className={Styles.card} >
          <IonCardContent>
            <IonText>
              <ConstructionWorkerIcon className={Styles.projectsIcon} />
              <p>{isContractor ? NO_PROJECTS_DESCRIPTION_CONTRACTOR : NO_PROJECTS_DESCRIPTION_OWNER}</p>
              {!isContractor ? <IonButton routerLink={getKnownRoutePathForPage(Page.CreateProject)}>Create a project</IonButton> : null}
            </IonText>
          </IonCardContent>
        </IonCard>
      </div>
    )
    return <>
      <div className={`${Styles.label} ion-padding`}>Projects</div>
      {!isContractor
        ? <IonButton mode='md' className='ion-padding-start ion-no-margin' routerLink={getKnownRoutePathForPage(Page.CreateProject)}>Create a project</IonButton>
        : null}
      { weaverFlags['MW-1803-ui-update-to-project-list']
        ? projects.map((project) =>
          <IonCard className={Styles.projectCard} key={project.id} routerLink={`/projects/${project.id}`}>
            <div className={Styles.projectContainer}>
              <IonGrid className='ion-no-padding'>
                <IonRow>
                  <div className={Styles.projectStaticGoogleMapContainer}>
                    <StaticGoogleMap
                      location={project.address}
                      size={'730x210'}
                      zoom={15}
                      scale={2}
                    />
                  </div>
                </IonRow>
                <div className={Styles.projectTitleContainer}>
                  <div>
                    <div className={Styles.projectHeader}>
                      {`${project.title}, ${project.address?.postTown}, ${project.address?.postCode}`}
                    </div>
                    <IonRow>
                      <div className={`${Styles.projectSubtitle}`}>
                        {`${project.projectTypes.map(x => ' ' + getEnumLabel(x))}`}
                      </div>
                    </IonRow>
                  </div>
                  <IonRow>
                    { weaverFlags['tasks'].enabled
                      ? <NextTaskButton
                        className={`${Styles.nextTaskButton} ion-padding-end`}
                        projectId={project.id}
                        color='primary'
                        fill='outline'/>
                      : <></>}
                  </IonRow>
                </div>
                <IonRow>
                  <div className={Styles.projectCopyContainer}>
                    {weaverFlags['tasks'].enabled
                      ? <>
                        <div className={Styles.projectStatusText}>
                          <NextTopLevelTaskDisplay projectId={project.id} />
                        </div>
                        <div className={Styles.projectDivider}>
                          |
                        </div>
                      </>
                      :<></>}
                    <div className={Styles.projectMoney}>
                      {flexibleBudgetTextToSignificantFigures(getFlexibleProjectBudgetAsMoneyOrMoneyRange(project, myTeam))}
                    </div>
                  </div>
                  <div className={Styles.projectArrowIcon}>
                    <IonIcon color='primary' size='small' icon={arrowForwardSharp}/>
                  </div>
                </IonRow>
              </IonGrid>
            </div>
          </IonCard>,
        )
        : projects.map(({ id, title, lastAccessed }) => <IonItem key={id} routerLink={`/projects/${id}`}>
          <ProjectAvatar slot="start" title={title} />
          <IonLabel>
            {title}
            {lastAccessed ? <IonLabel color='medium'>Last Viewed: {timeAgo(lastAccessed)}</IonLabel> : null }
          </IonLabel>
        </IonItem>,
        )}
    </>
  }

  return <WeaverIonPage id='ProjectsIndexPage'>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='Projects'/>
    </WeaverIonHeader>
    <WeaverIonContent fullscreen>
      <IonList>
        {renderLeadsSection()}
        {renderProjectsSection()}
      </IonList>
    </WeaverIonContent>
  </WeaverIonPage>
}

export default ProjectsIndexPage
