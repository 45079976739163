import React from 'react'
import SuccessPage from '../../../common/components/SuccessPage/SuccessPage'
import { pageConfig_Project_Documents, useRouteTo } from '../../../routes'
import { useParamsFromPageConfig } from '../../../routesProvider'

const UploadedProjectDocumentSuccessPage: React.FC = () => {

  const { projectId } = useParamsFromPageConfig<{ projectId: string }>()
  const goToUploadDocumentsPage = useRouteTo(pageConfig_Project_Documents.path)

  const routeToProjectDocumentsPage = () => goToUploadDocumentsPage({ id : projectId }, undefined, undefined, "pop")()

  return (
    <SuccessPage routeToPage={routeToProjectDocumentsPage} header= 'Success!' text= 'Documents successfully uploaded' buttonText= 'Continue!'/>
  )
}
export default UploadedProjectDocumentSuccessPage
