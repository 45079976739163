import React, { useState } from "react"
import { IonAccordion, IonAccordionGroup, IonButton,  IonCard, IonCardContent, IonCardHeader, IonCardTitle,  IonChip, IonIcon, IonItem,  IonLabel,  IonList, IonText, useIonRouter } from "@ionic/react"
import { addOutline, arrowBackOutline } from "ionicons/icons"
import { TeamType, useShowProjectQuery, ContentDisposition, Task, TaskStatus, Currency } from "../../graphql/generated"
import { useGraphQLDataSource } from "../../api/graphql"
import { useMyIndividualActiveTeam } from "../../api/providers/MyIndividualProvider/MyIndividualProvider"
import GlobalHeader from "../../common/components/GlobalHeader/GlobalHeader"
import GlobalHeaderStyles from '../../common/components/GlobalHeader/GlobalHeader.module.scss'
import ProjectInviteRow from "./ProjectInviteRow"
import { getFlexibleProjectBudgetAsText } from "./common"
import { getWorkStartEstimateLabels } from "./workEstimate.i18n"
import { DateTime } from "luxon"
import { useChatRoomFunctions } from "../../api/services/chat/useChatRoomFunctions"
import { pageConfig_AllProjectTasks, pageConfig_ChatRoomOptions, pageConfig_MyProjectTasks, pageConfig_Projects, pageConfig_Project_Documents, useRouteTo } from "../../routes"
import { formatAddressToSingleLine } from "../../common/utils/addresses"
import WeaverIonPage from "../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonHeader from "../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonContent from "../../common/components/WeaverIonWrappers/WeaverIonContent"
import ErrorBlockPage from "../../common/components/ErrorBlock/ErrorBlockPage"
import LoadingSpinnerPage from "../../common/components/LoadingSpinner/LoadingSpinnerPage"
import { ProjectMemberTeam } from "./ShowProjectTypes"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"
import Paperclip from "../../assets/images/paperClip.svg"
import { informationCircle, checkboxOutline, checkmarkDoneOutline  } from "ionicons/icons"
import { ChildTask } from "./checklist/types"
import { ProjectIndicator } from "./ProjectIndicator"
import { useSearchState } from "../../common/hooks/pages"
import { MemberRow } from "../../common/components/MemberRow/MemberRow"
import { useParamsFromPageConfig } from "../../routesProvider"
import { projectTypeLabels } from "../onboarding/team/onboarding.i18n"
import { getLabelsFromEnumList } from "../../common/utils/enum"
import InviteMemberModal, { InviteMemberOnSuccessHandler } from "../../common/components/InviteMemberModal/InviteMemberModal"
import Styles from "./ShowProjectPage.module.scss"
import { getChatName } from "../chats/chatRoom/utils"
import GoogleMapFromWeaverAddress from "../../common/components/GoogleMapFromWeaverAddress.tsx/GoogleMapFromWeaverAddress"
import { filterMyProjectTasks, getCurrentTopLevelTask } from "./taskUtils"
import { useFireAnalyticsEvent } from "../../api/providers/SegmentProvider/hooks"
import { getBudgetRangeByMoney } from "../profile/ContractorLeadPreferences/ContractorBudgetRanges/budgetRanges"
import { getProjectMemberOwnerTeamTypes } from "../../api/providers/SegmentProvider/helpers"
import NextTaskButton from "./NextTaskButton"
import { multiSort, sortBy } from "../../common/sort"

type SearchParams = {
  /**
   * HACK: ignoreScope allows a ops (weaver team) member to ignore scoping permissons
   * this will be `"true"` when an ops member is requesting access to a project they are not part of
   */
  ignoreScope?: string,
}

type InviteState = {
  requiredTeamType: TeamType.Contractor | TeamType.Architect | TeamType.Homeowner,
}

const getAllTaskSteps = (allTasks: Array<Task | ChildTask >) => {
  return allTasks.reduce((prev, task) => prev + task.childTasks.length, 0)
}

const ShowProject: React.FC = () => {
  const weaverFlags = useWeaverFlags()
  const { createChatRoom } = useChatRoomFunctions()
  const [ searchState ] = useSearchState<SearchParams>({})
  const ignoreScope = searchState.ignoreScope === "true"

  const goToProjectList = useRouteTo(pageConfig_Projects.path)
  const goToDocumentsPage = useRouteTo(pageConfig_Project_Documents.path)
  const goToMyTasksPage = useRouteTo(pageConfig_MyProjectTasks.path)
  const goToAllTasksPage = useRouteTo(pageConfig_AllProjectTasks.path)
  const goToChatOptions = useRouteTo(pageConfig_ChatRoomOptions.path)

  const [ inviteModalState, setInviteModalState ] = useState<InviteState| undefined>(undefined)

  const { id } = useParamsFromPageConfig<{id:string}>()

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const showProjectQuery = useShowProjectQuery(gqlDataSource, {
    id,
    config: { disposition: ContentDisposition.Attachment, transformation: { width: 1000, height: 1000 } },
    ignoreScope,
  }, {
    refetchOnWindowFocus: false,
    select: ({ getProject }) => ({
      ...getProject,
      tasks: multiSort(getProject.tasks, [ sortBy("order"), sortBy("title") ]),
    }),
  })

  const myTeam = useMyIndividualActiveTeam()
  const isContractor = myTeam?.type === TeamType.Contractor

  useFireAnalyticsEvent({
    eventName: 'Project_Viewed',
    extraData: {
      projectId: showProjectQuery.data?.id ?? '',
      types: showProjectQuery.data?.projectTypes,
      description: showProjectQuery.data?.description,
      budget: showProjectQuery.data?.budgetValue,
      budgetFlex: showProjectQuery.data?.budgetCategory,
      budgetRange: getBudgetRangeByMoney(showProjectQuery.data?.budgetValue),
      address: showProjectQuery.data?.address ?? undefined,
      constructionStart: showProjectQuery.data?.workStartEstimate,
      tenderReturn: showProjectQuery.data?.tenderReturnDate && DateTime.fromISO(showProjectQuery.data?.tenderReturnDate).toLocaleString(DateTime.DATE_MED),
      projectOwnerTeamTypes: getProjectMemberOwnerTeamTypes(showProjectQuery.data?.members),
    },
    deps: [ showProjectQuery.data?.id ],
    enabled: !!showProjectQuery.data?.id && weaverFlags['MW-2122-project-lead-event-updates'],
  })

  if (showProjectQuery.isFetching && !showProjectQuery.data) {
    return <LoadingSpinnerPage name="ShowProject"/>
  }

  if (!myTeam) {
    return <LoadingSpinnerPage name="ShowProject" />
  }

  if (showProjectQuery.error || !showProjectQuery.data) {
    return <ErrorBlockPage name='ShowProject' onRefresh={showProjectQuery.refetch} />
  }

  const project = showProjectQuery.data

  const projectHasArchitect = project.memberInvites.some(x => x.requiredTeamType === TeamType.Architect) || project.members.some(x => x.team.type === TeamType.Architect)
  const projectHasHomeowner = project.memberInvites.some(x => x.requiredTeamType === TeamType.Homeowner) || project.members.some(x => x.team.type === TeamType.Homeowner)

  const members = project.members
  const invites = project.memberInvites
  const listProjectTasks = project.tasks

  const { myTasksCount } = filterMyProjectTasks(project.tasks, myTeam)

  const unclaimedInvites = invites.filter(x => !x.team)
  const claimedInvites = invites.filter(x => x.team)

  const createChatRoomBetweenProjectTeams = (myTeam: ProjectMemberTeam, memberTeam: ProjectMemberTeam) =>
    createChatRoom({
      name: getChatName([ myTeam, memberTeam ], project.title),
      teamIds: [ myTeam.id, memberTeam.id ],
      projectId: project.id,
    })

  const handleInviteSuccess: InviteMemberOnSuccessHandler = () => {
    showProjectQuery.refetch()
    setInviteModalState(undefined)
  }

  type InfoDetailProps = {
    projectInfoLabel: string,
    projectInfoValue: string | null | undefined,
  }

  const InfoDetail: React.FC <InfoDetailProps> = ({ projectInfoLabel, projectInfoValue }) => {
    return (
      (projectInfoValue && projectInfoValue.length)
        ? <div className={Styles.infoDetail}>
          <IonText color="medium" className={Styles.infoDetailLabel}>{projectInfoLabel}</IonText>
          {projectInfoLabel === "Project status"
            ? <IonChip>{projectInfoValue}</IonChip>
            : <IonText className={Styles.infoDetailValue}><p>{projectInfoValue}</p></IonText>}
        </div>
        : null
    )
  }

  const navigateToProjectDocumentsPage = () => goToDocumentsPage({ id })()

  const navigateToMyTasksPage = () => {
    weaverFlags["MW-2081-project-to-tasks-pages-navigation"].enabled ? goToMyTasksPage({ projectId: project.id })() : null
  }

  const navigateToAllTasksPage = () => {
    weaverFlags["MW-2081-project-to-tasks-pages-navigation"].enabled ? goToAllTasksPage({ projectId: project.id })() : null
  }

  const onProjectMemberChatBubbleClicked = (selectedTeam: ProjectMemberTeam, goToChatRoomCreateIfNecessary: () => void) => {
    const amIAContractor = myTeam.type === TeamType.Contractor
    const doIWantToSpeakToAContractor = selectedTeam.type === TeamType.Contractor

    if (weaverFlags['chat-group'].enabled && weaverFlags['chat-group'].showChatGroupOptions) {
      // The Chat Room options page only displays when the current user's a contractor or the current user just clicked on the chat icon for a Contractor
      if (amIAContractor || doIWantToSpeakToAContractor){
        goToChatOptions({ projectId: project.id, teamId: selectedTeam.id })()
      } else {
        goToChatRoomCreateIfNecessary()
      }
    } else {
      goToChatRoomCreateIfNecessary()
    }
  }

  return (
    <WeaverIonPage id='ShowProjectPage' disableDirectChildStructureChecks={true}>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader
          pageTitle={project.title}
          navElement={
            <IonButton onClick={goToProjectList({})}>
              <IonIcon slot="start" icon={arrowBackOutline} />
            </IonButton>
          }
        />
      </WeaverIonHeader>
      <WeaverIonContent className={Styles.showProjectContent}>
        <IonCard className={Styles.projectCard}>
          {weaverFlags['project-leads'].showMap && project.address &&
            <GoogleMapFromWeaverAddress isExact={true} address={project.address}/>
          }
          <IonCardHeader>
            <IonCardTitle><p className={Styles.address}>{formatAddressToSingleLine(project.address)}</p></IonCardTitle>
          </IonCardHeader>
          <IonCardContent className={Styles.showProjectHeader}>
            {/** // TODO: Add navigation to current task and display status in button (https://weaver.atlassian.net/browse/MW-1897) **/}
            {weaverFlags.tasks.enabled && (
              <>
                <div onClick={navigateToMyTasksPage}>
                  <ProjectIndicator taskListData={listProjectTasks} />
                </div>
                <NextTaskButton projectId={id} />
              </>
            )}
          </IonCardContent>
        </IonCard>

        <section className={Styles.showProjectSection}>
          <IonText><p className={Styles.projectHeadings}>Project details</p></IonText>
          <IonList lines="full">
            <IonAccordionGroup>
              <IonAccordion value="first">
                <IonItem slot="header" lines="full">
                  <div className={Styles.iconContainer}>
                    <IonIcon icon={informationCircle} />
                  </div>
                  <p className={Styles.projectItemName}>Info</p>
                </IonItem>
                <div slot="content">
                  <InfoDetail projectInfoLabel={"Project type"} projectInfoValue={getLabelsFromEnumList(projectTypeLabels, project.projectTypes)} />
                  <InfoDetail projectInfoLabel={"Address"} projectInfoValue={formatAddressToSingleLine(project.address)} />
                  <InfoDetail projectInfoLabel={"Budget"}projectInfoValue={getFlexibleProjectBudgetAsText(project, myTeam)} />
                  <InfoDetail projectInfoLabel={"Work start estimate"} projectInfoValue={project.workStartEstimate && getWorkStartEstimateLabels()[project.workStartEstimate]} />
                  <InfoDetail projectInfoLabel={"Tender return date"} projectInfoValue={project.tenderReturnDate && DateTime.fromISO(project.tenderReturnDate).toLocaleString(DateTime.DATE_MED)} />
                  {weaverFlags.tasks.enabled && (
                    <InfoDetail projectInfoLabel={"Project status"} projectInfoValue={getCurrentTopLevelTask(listProjectTasks)?.title} />
                  )}
                  <InfoDetail projectInfoLabel={"Description"} projectInfoValue={project.description} />
                </div>
              </IonAccordion>
            </IonAccordionGroup>
            <IonItem button slot="header" lines="full" detail={true} onClick={navigateToProjectDocumentsPage}>
              <div className={Styles.iconContainer}>
                <IonIcon src={Paperclip} />
              </div>
              <p className={Styles.projectItemName}>Documents</p>
              <IonLabel slot="end"><p>{project?.documents?.length ?? ''}</p></IonLabel>
            </IonItem>
          </IonList>
        </section>

        {(weaverFlags.tasks.enabled && weaverFlags.tasks.showProjectList) && (
          <section className={Styles.showProjectSection}>
            <IonText><p  className={Styles.projectHeadings}>Tasks</p></IonText>
            <IonList>
              <IonItem button slot="header" lines="full" detail={true} onClick={navigateToMyTasksPage}>
                <div className={`${Styles.iconContainer} ${Styles.iconYourTasks}`}>
                  <IonIcon icon={checkboxOutline} color="secondary" />
                </div>
                <p className={Styles.projectItemName}>Your tasks</p>
                <IonLabel slot="end"><p>{myTasksCount}</p></IonLabel>
              </IonItem>
              <IonItem button slot="header" lines="full" detail={true} onClick={navigateToAllTasksPage}>
                <div className={Styles.iconContainer}>
                  <IonIcon icon={checkmarkDoneOutline}/>
                </div>
                <p className={Styles.projectItemName}>All tasks</p>
                <IonLabel slot="end">
                  <p>{listProjectTasks !== undefined && getAllTaskSteps(listProjectTasks)}</p>
                </IonLabel>
              </IonItem>
            </IonList>
          </section>
        )}

        <section className={Styles.showProjectSection}>
          <IonText><p className={Styles.projectHeadings}>Members</p></IonText>
          { !isContractor && <IonButton onClick={() => setInviteModalState({ requiredTeamType: TeamType.Contractor })}><IonIcon icon={addOutline} slot="start"/>Invite Contractor</IonButton> }
          { weaverFlags["project-owner-invites"].enabled && !isContractor && myTeam?.type === TeamType.Homeowner && (
            <IonButton onClick={() => setInviteModalState({ requiredTeamType: TeamType.Architect })} disabled={projectHasArchitect}><IonIcon icon={addOutline} slot="start"/>Invite Architect</IonButton>
          )}
          { weaverFlags["project-owner-invites"].enabled && !isContractor && myTeam?.type === TeamType.Architect && (
            <IonButton onClick={() => setInviteModalState({ requiredTeamType: TeamType.Homeowner })} disabled={projectHasHomeowner}><IonIcon icon={addOutline} slot="start"/>Invite Homeowner</IonButton>
          )  }
          <IonList className={Styles.membersListBottomMargin}>
            { unclaimedInvites.map(unclaimed => <ProjectInviteRow key={unclaimed.id} invite={unclaimed} />) }
            { claimedInvites.map(claimed => <ProjectInviteRow key={claimed.id} invite={claimed} />) }
            { members.map(member => <MemberRow key={member.id} team={member.team} projectId={project.id} createChatRoom={createChatRoomBetweenProjectTeams} onChatBubbleClickedAction={onProjectMemberChatBubbleClicked}/>) }
          </IonList>
        </section>

      </WeaverIonContent>
      <InviteMemberModal analyticsSource={"showProject"} isOpen={!!inviteModalState} projectId={project.id} requiredTeamType={inviteModalState?.requiredTeamType} onDidDismiss={() => setInviteModalState(undefined)} onSuccess={handleInviteSuccess} />
    </WeaverIonPage>
  )
}

export default ShowProject
