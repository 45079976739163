import React, { useState } from "react"
import { IonButton,IonCard,IonIcon,IonToolbar, useIonAlert } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge, useRouteTo } from "../../../../../../routes"
import { useHistory } from "react-router"
import { getWorkHistoryById, useInvalidateMyContractorProfile, useMyContractorProfile, GetContractorProfileWorkHistory } from "../../datasource"
import { ScreenRenderProps, useScreensWithProps } from "../../../../../../common/hooks/useScreens"
import { alwaysArray } from "../../../../../../common/utils"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { addCircleOutline, arrowBackOutline, arrowForwardOutline } from "ionicons/icons"
import ProvidePhotosScreen from "./ScreenProvidePhotos"
import { BudgetRange, UploadedFileStatus, useCreateContractorProfileWorkHistoryPhotoMutation } from "../../../../../../graphql/generated"
import { DisplayUploadedFileAsPhoto } from "./DisplayUploadedFileAsPhoto"
import { UploadFile } from "./UploadFile"
import { getSupportedImageFileTypes } from "../../../../../../common/utils/getSupportedFileTypes"
import { BudgetRangeIndicatorHeader } from "../BudgetRangeIndicatorHeader"
import { useAwardTieredBadge, useShouldAwardContractorProfileBudgetRangeBadge } from "../ContractorProfileBudgetRangeBadge/hooks"
import Styles from './WorkHistoryPhotos.module.scss'
import { useAnalyticsEvent } from "../../../../../../api/providers/SegmentProvider/hooks"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonFooter from "../../../../../../common/components/WeaverIonWrappers/WeaverIonFooter"
import WeaverIonHeader from "../../../../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import BrilliantScreen from "../../../../../../common/components/BrilliantScreen/BrilliantScreen"
import BrowseFilesButton from "../../../../../../common/components/BrowseFilesButton"
import { useWeaverFlags } from "../../../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { BadgeLevel } from "../BadgeUtils"

enum WorkHistoryPhotosScreens {
  ProvidePhotosInstructions = "ProvidePhotosInstructions",
  UploadPhotos = "UploadPhotos",
  Finished = "Finished",
}

type ScreenProps = {
  budgetRange: BudgetRange,
  workHistory?: GetContractorProfileWorkHistory,
  workHistoryId:string,
}

type ScreenComponentProps = ScreenRenderProps<WorkHistoryPhotosScreens> & ScreenProps

const _ProvidePhotosInstructions: React.FC<ScreenComponentProps> = ({ useChangeScreen, budgetRange, workHistoryId }) => {

  const onClickNext = useChangeScreen(WorkHistoryPhotosScreens.UploadPhotos)
  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  return <>
    <WeaverIonContent className={`${Styles.textContainer}`}>
      <ProvidePhotosScreen />
    </WeaverIonContent>
    <WeaverIonFooter className={`ion-no-border ion-padding ${Styles.ionFooter}`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId }, undefined, "forward", "push")}>
          <IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNext}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _UploadPhotos: React.FC<ScreenComponentProps> = ({ useChangeScreen, workHistory, workHistoryId, budgetRange }) => {
  const invalidateMyContractorProfile = useInvalidateMyContractorProfile()

  const onClickBack = useChangeScreen(WorkHistoryPhotosScreens.ProvidePhotosInstructions)
  const onClickNext = useChangeScreen(WorkHistoryPhotosScreens.Finished)

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createContractorProfileWorkHistoryPhoto = useCreateContractorProfileWorkHistoryPhotoMutation(gqlDataSource)
  const weaverFlags = useWeaverFlags()
  const triggerEventWorkHistoryReferencesAddedPastProjectPhoto = useAnalyticsEvent('WorkHistory_References_Added_Photo')

  const [ filesToUpload, setFilesToUpload ] = useState<File[]>([])
  const [ presentAlert ] = useIonAlert()

  const photos = alwaysArray(workHistory?.photos)

  const addUploadFileToWorkHistoryItemPhotos = (uploadedFileId: string) =>
    createContractorProfileWorkHistoryPhoto.mutateAsync({
      workHistoryId,
      uploadedFileId,
    })

  const onPhotoUploaded = async (uploadFileId: string) => {
    await addUploadFileToWorkHistoryItemPhotos(uploadFileId)
    invalidateMyContractorProfile()
  }

  const onPhotoArchived = () => {
    invalidateMyContractorProfile()
  }
  const supportedImageFileTypes = getSupportedImageFileTypes()
  const buildSupportedImageFormatsLabel = supportedImageFileTypes.map(format => format.replace("image/", "").toUpperCase()).join(", ")
  const nonArchivedPhotos = photos.filter(photo => photo.status !== UploadedFileStatus.Archived)

  const onFilesSelectedByUser = (files: File[]) => {
    const photosCount = files.length + nonArchivedPhotos.length
    if (weaverFlags['contractor-upload-project-photos'].enabled && photosCount > weaverFlags['contractor-upload-project-photos'].photosLimit) {
      presentAlert({
        header: `${weaverFlags['contractor-upload-project-photos'].confirmationDescription}`,
        buttons: [
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
        onDidDismiss: (e: CustomEvent) => uploadProcess(`${e.detail.role}`, files),
      })
    }
    else {
      setFilesToUpload(files)
    }
  }

  const uploadProcess = (response: string, files: File[]) => {
    if (response === 'confirm') {
      setFilesToUpload(files)
    }
  }

  return <>
    <WeaverIonHeader>
      <BudgetRangeIndicatorHeader budgetRange={budgetRange} />
    </WeaverIonHeader>
    <WeaverIonContent className="ion-padding">
      <h3 className="ion-padding-start">Upload {weaverFlags['contractor-upload-project-photos'].enabled && weaverFlags['contractor-upload-project-photos'].recommendedPhotosRange} photos of the completed work at {workHistory?.projectAddress?.addressLine1}</h3>
      <span className="ion-padding-start">Supported formats: {buildSupportedImageFormatsLabel}.</span>

      <BrowseFilesButton
        onFilesSelectedByUserCallback={onFilesSelectedByUser}
        containerCssClassName={Styles.uploadPhotosButtonContainer}
        multipleSelection={true}
        supportedFileTypes={supportedImageFileTypes}
        renderButton={
          (openNativeFilePicker) => (
            <IonCard>
              <IonButton onClick={openNativeFilePicker} fill="clear" size="small" className={Styles.uploadPhotosButton}>
                <IonIcon icon={addCircleOutline} className={Styles.uploadPhotosButtonIcon}/>
                Upload Photo
              </IonButton>
            </IonCard>
          )
        }
      />

      <div className={Styles.photosContainer}>
        {
          nonArchivedPhotos
            .map(photo => <DisplayUploadedFileAsPhoto key={photo.id} uploadedFileId={photo.id} onFileArchived={onPhotoArchived} shouldDisplayDeleteButton={true} />)
        }
        {
          filesToUpload
            .map(file => <UploadFile key={file.name} fileToUpload={file} onUploadComplete={onPhotoUploaded} trackEvent={triggerEventWorkHistoryReferencesAddedPastProjectPhoto} />)
        }
      </div>
    </WeaverIonContent>
    <WeaverIonFooter className={`ion-no-border ion-padding ${Styles.ionFooter}`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton color="secondary" slot="start" onClick={onClickBack}><IonIcon slot="start" icon={arrowBackOutline} />Back</IonButton>
        <IonButton slot="end" onClick={onClickNext} disabled={nonArchivedPhotos.length === 0}>Next<IonIcon icon={arrowForwardOutline} /></IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const _Finished: React.FC<ScreenComponentProps> = ({ budgetRange, workHistoryId }) => {

  const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)
  const goToAwardBadge = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRange_AwardBadge.path)

  const awardedBadge = useAwardTieredBadge(budgetRange, workHistoryId)

  const weaverFlags = useWeaverFlags()

  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  const onClickNext = () => {
    if (weaverFlags["MW-2096-multiple-work-histories"].enabled){
      awardedBadge !== BadgeLevel.None
        ? goToAwardBadge({ budgetRange, workHistoryId })()
        : goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    } else {
      shouldAwardContractorProfileBudgetRangeBadge
        ? goToAwardBadge({ budgetRange, workHistoryId })()
        : goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    }
  }

  console.debug("[WorkHistoryPhotos.Finished] Render: ", { shouldAwardContractorProfileBudgetRangeBadge })

  return <>
    <WeaverIonContent>
      <BrilliantScreen title="Amazing!!!" description="You have added photos"/>
    </WeaverIonContent>
    <WeaverIonFooter className={`ion-no-border ion-padding ${Styles.ionFooter}`}>
      <IonToolbar className={Styles.ionToolbar}>
        <IonButton expand="block" onClick={onClickNext}>Continue</IonButton>
      </IonToolbar>
    </WeaverIonFooter>
  </>
}

const WorkHistoryPhotosPage: React.FC = () => {

  const history = useHistory()
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string }>()

  const myContractorProfile = useMyContractorProfile()
  const workHistory = getWorkHistoryById(myContractorProfile, workHistoryId)

  const [ Screen, activeScreen ] = useScreensWithProps<WorkHistoryPhotosScreens, ScreenProps>({
    init: WorkHistoryPhotosScreens.ProvidePhotosInstructions,
    resetWhenValueChanges: history.location.search,
    screenProps: {
      budgetRange,
      workHistory,
      workHistoryId,
    },
    screens: {
      ProvidePhotosInstructions: { render: _ProvidePhotosInstructions } ,
      UploadPhotos: { render: _UploadPhotos } ,
      Finished: { render: _Finished } ,
    },
  })

  return (
    <WeaverIonPage id={`WorkHistoryPhotosPage.${activeScreen}`} key={activeScreen} disableDirectChildStructureChecks={true}>
      {Screen}
    </WeaverIonPage>
  )
}

export default WorkHistoryPhotosPage
