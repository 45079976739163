import React, { useEffect, useState } from 'react'
import { IonButton, IonChip, IonIcon, IonItem, IonLabel, useIonRouter } from '@ionic/react'
import MemberAvatar from './MemberAvatar'
import Styles from "./MemberRow.module.scss"
import { chatboxOutline } from "ionicons/icons"
import { useGraphQLDataSource } from '../../../api/graphql'
import { useMyIndividualActiveTeam } from '../../../api/providers/MyIndividualProvider/MyIndividualProvider'
import { PublicProfileModal } from '../../../domains/profile/PublicProfileModal'
import { ShowProjectQuery, ChatRoom, useGetChatRoomForProjectTeamsQuery } from '../../../graphql/generated'
import { capitalizeFirstCharacter } from '../../utils/string'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { pageConfig_Profile_Public, useRouteTo } from '../../../routes'

type Project = ShowProjectQuery["getProject"]
type ProjectMember = NonNullable<Project["members"]>[number]
type ProjectMemberTeam = ProjectMember["team"]

type MemberRowProps = {
  /* The team that is a member of the Project or Chat. */
  team: ProjectMemberTeam,
  /** The id of the project the Chat Room is associated with */
  projectId?: string | null | undefined,
  /* This function creates the chat in case where the chat doesn't exist yet. */
  createChatRoom: (team1: ProjectMemberTeam, team2: ProjectMemberTeam) => Promise<Pick<ChatRoom, 'id' >>,
  /* The action called when the chat button is clicked. Thus, consumers of this component are allowed to customise what happens then (e.g: running a validation) */
  onChatBubbleClickedAction: (selectedTeam: ProjectMemberTeam, goToChatRoomCreateIfNecessary: () => void) => void,
}

/**
 * Represents a Team that is a member of a Chat or a Project. It displays basic information about the Team as well a button to start or open a chat between the current user
 * and the selected team. The consumers of this component are provided with a way to overide the action of this button.
 */
export const MemberRow: React.FC<MemberRowProps> = ({ team, projectId, createChatRoom, onChatBubbleClickedAction }) => {
  const weaverFlags = useWeaverFlags()
  const myTeam = useMyIndividualActiveTeam()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })

  const [ showModal, setShowModal ] = useState<boolean>(false)
  if (!myTeam) throw new Error("Can't render this component without an active team")

  const ionRouter = useIonRouter()
  const goToMemberProfile = useRouteTo(pageConfig_Profile_Public.path)
  const getChatRoomForProjectTeamsQuery = useGetChatRoomForProjectTeamsQuery(gqlDataSource, { teamIds: [ myTeam.id,  team.id ], projectId }, { refetchOnWindowFocus: true })

  useEffect(() => {
    setShowModal(false)
  }, [ ionRouter.routeInfo.pathname ])

  const navigateToChatPage = (chatRoomId: string) => {
    ionRouter.push(`chats/${chatRoomId}`)
  }

  const goToChatRoomCreateIfNecessary = async () => {
    if (!getChatRoomForProjectTeamsQuery.data) return

    const existingChatRoomId = getChatRoomForProjectTeamsQuery?.data.getChatRoomForProjectTeams?.id
    if (existingChatRoomId){
      navigateToChatPage(existingChatRoomId)
    } else {
      const result = await createChatRoom(myTeam, team)
      navigateToChatPage(result.id)
    }
  }

  const handleClick = () => {
    weaverFlags['MW-2076-change-work-history-modal-on-projects'].enabled && weaverFlags['tab-nav-bar'].enabled
      ? goToMemberProfile({ teamType: team.type, teamId: team.id })()
      : setShowModal(true)
  }

  return (
    <>
      <IonItem key={team.id} className={Styles.memberItem} lines="full">
        <MemberAvatar slot="start" title={team.name} />
        <div onClick={handleClick}>
          <IonLabel className={Styles.memberLabel} >
            <p>{team.name}</p>
          </IonLabel>
          <IonLabel>
            <p>{capitalizeFirstCharacter(team.type)}</p>
          </IonLabel>
        </div>
        <IonChip className={Styles.chatChipButton} outline slot="end" hidden={myTeam.id === team.id} disabled={getChatRoomForProjectTeamsQuery.isLoading} onClick={event => {
          event.preventDefault()
          onChatBubbleClickedAction(team, goToChatRoomCreateIfNecessary)
          event.stopPropagation()
        }}>
          <span className={Styles.chipText}>Message</span>
        </IonChip>
      </IonItem>
      <PublicProfileModal
        team={team}
        breakpoints={[ 0, 0.5, 0.75, 0.95 ]}
        initialBreakpoint={0.95}
        isOpen={showModal}
        canDismiss={true}
        onDidDismiss={() => setShowModal(false)}
      />
    </>
  )
}
