import { Directory, Filesystem } from "@capacitor/filesystem"
import write_blob from "capacitor-blob-writer"
import { decode } from "base64-arraybuffer"
import { isPlatform } from "@ionic/react"
import { FileOpener } from '@awesome-cordova-plugins/file-opener'
import { DateTime } from "luxon"
import { UploadedFile } from "../../graphql/generated"
import { readFile } from "./capacitor/Filesystem"

export const fileIconSelector = (fileName: string) => {
  const fileEnding = fileName.split('.').pop()
  switch (fileEnding) {
    case 'pdf':
      return '/assets/icon/pdf-icon.svg'
    case 'xlsx':
      return '/assets/icon/sheets-icon.svg'
    case 'zip':
      return '/assets/icon/zip-icon.svg'
    case 'docx':
    case 'doc':
      return '/assets/icon/document-icon.svg'
    case 'png':
    case 'jpeg':
      return '/assets/icon/image-icon.svg'
    default:
      return '/assets/icon/default-icon.svg'
  }
}

/**
 * Saves a file to the Downloads folder for Desktop devices. The download is done by creating a HTML A object with the URL and clicking on it.
 * This function returns an exception if Capacitor's isPlatform('desktop')
 * resolves to false.
 * @param fileName The name to be use for the download file
 * @param blob  The content to download.
 */
export const saveFileToDownloadsFolder = (fileName: string, blob: Blob) => {
  if (!(isPlatform('desktop') || isPlatform('mobileweb'))) {
    throw Error('This function is only supported for Desktop or mobile web devices.')
  }
  // Create a HTML A object with the URL and click on it in order to trigger to download.
  const url = window.URL.createObjectURL(
    new Blob([ blob ]),
  )
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  // Append to html link element page
  document.body.appendChild(link)
  // Start download
  link.click()
  // Remove the link
  link.parentNode?.removeChild(link)
}

/**
 * Saves a file to the app's internal documents folder (Directory.Documents).
 * @param file - The file to be saved.
 * @param enabledFastMode Whether or not fast mode should be enabled.
 * @returns The path of the file with the Directory.Documents path, e.g.: /DOCUMENTS/sample.txt
 */
export const saveFileToDeviceStorage = async (file: File, enabledFastMode?: boolean): Promise<string> => {
  // We use write_blob for writing since it's more effective than Capacitor's write function on iOS and Android
  // Filesystem.writeFile strips the MIME type from a valid Data URI. For example if we called the following:
  // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFC" is saved as "iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFC"
  const documentPath = await write_blob({
    directory: Directory.Documents,
    path: file.name,
    blob: file,
    // Fast mode vastly improves read and write speeds on the web platform. For files written with 'fast_mode' set to true, Filesystem.readFile will produce
    // a Blob rather than a Base64-encoded string.
    // This is only available for web. https://weaver.atlassian.net/browse/MW-2073 covers the effort to fix this for mobile or come up with a general solution
    fast_mode: enabledFastMode ? true : false,
    on_fallback: (error: Error) => { throw error },
  })
  return documentPath
}

const getMimeTypeFromExtension = (extension: string): string | undefined => {
  const extToMimes : Record<string, string>  = {
    'jpg': 'image/jpeg',
    'jpeg': 'image/jpeg',
    'png': 'image/png',
    'pdf': 'application/pdf',
    'bmp': 'image/bmp',
    'tiff': 'image/tiff',
    'tif': 'image/tif',
    'webp': 'image/webp',
    'heic': 'image/heic',
  }

  return extToMimes[extension]
}

/**
 * Reads a file from the app's internal documents folder (Directory.Documents).
 * @param fileName The name of the file.
 * @deprecated Because of MW-2039.
 * @returns The file's blob content.
 */
export const readFileFromDeviceStorageDeprecated = async (fileName: string | null | undefined): Promise<Blob | undefined> => {
  try {
    if (!fileName) return
    const result = await Filesystem.readFile({
      directory: Directory.Documents,
      path: fileName,
    })
    const blob = new Blob([ new Uint8Array(decode(result.data)) ])
    return blob
  } catch (e){
    // If an exception happens, e.g.: the file doesn't exist, return undefined
    return undefined
  }
}

/**
 * Reads a file from the app's internal documents folder (Directory.Documents). This function is expected to be used when the feature flag
 * MW-2039-chat-user-cannot-open-received-files is enabled. It uses Weaver's custom readFile function which ensures the return object is a File
 * @param fileName The name of the file.
 * @returns The file's blob content.
 */
export const readFileFromDeviceStorage = async (fileName: string | null | undefined): Promise<File | undefined> => {
  try {
    if (!fileName) return
    const result = await readFile({
      directory: Directory.Documents,
      path: fileName,
    })

    // This handle the case for images already saved into the device storage before MW-2039.
    // The following code migrates the file stored in the Device's Storage to the new format.
    if (!(result instanceof File)) {
      const base64Result = await readFileFromDeviceStorageDeprecated(fileName)
      if (!base64Result) return

      const fileExtension = fileName.split('.').pop()
      if (!fileExtension) return

      // We need to infer the mime type from the extension because for the old format only includes the file data in Base64
      const mimeType = getMimeTypeFromExtension(fileExtension)
      if (!mimeType) return

      const file = new File([ base64Result ], fileName, {
        lastModified: DateTime.now().toMillis(),
        type: mimeType,
      })

      // After the user has selected a file from the Device's Storage we save it in the new format (as File)
      // by doing this we achieve a Gradual migration of the files in the old format in the new old
      const isMW2066FlagEnabled = true
      saveFileToDeviceStorage(file, isMW2066FlagEnabled)

      return file
    }

    return result
  } catch (e){
    // If an exception happens, e.g.: the file doesn't exist, return undefined
    return undefined
  }
}

/**
 * Resolves the full path of the app's internal documents folder.
 * @returns A string with the full path for the documents folder, e.g.: file:///var/mobile/Containers/Data/Application/8D125AE6-A599-401E-9465-7A3311175FAD/Documents/
 */
export const resolveAppDirectoryFullPath = async (directory = Directory.Documents) =>
  Filesystem.getUri({
    directory,
    path: '',
  }).then(getUriResult => getUriResult.uri)

/**
 * Opens a file in the FileOpener's plug-in.
 * @param fileName The name of the file
 * @param fileContentType The file's content type
 */
export const openFile = async (fileName: string | null | undefined, fileContentType: string | null | undefined) => {
  if (!fileName)
    return

  if (!fileContentType)
    return

  await resolveAppDirectoryFullPath().then(appDirectoryFullPath => {
    const fullFilePath = appDirectoryFullPath + '/' + fileName
    FileOpener.open(fullFilePath, fileContentType)
  }, (error) => {
    console.log(error)
    throw error
  })
}

export const convertBlobToFile = (currentBlob: Blob, fileName: string): File => {
  return new File([ currentBlob ], fileName, { lastModified: new Date().getTime(), type: currentBlob.type })
}

export const convertImageToBlob = (base64ImageRepresentation: string | undefined, format: string ) => {
  if (base64ImageRepresentation === undefined) return null
  const blob = new Blob([ new Uint8Array(decode(base64ImageRepresentation)) ], {
    type: `image/${format}`,
  })
  return blob
}

export const isFilenameExtensionAnImageExtension =  (fileName: string | null | undefined): boolean => {
  if (!fileName)
    return false

  const mostCommonImageFormatExtensions = [ 'png', 'jpeg', 'jpg', 'bmp' ]
  const fileEnding = fileName.split('.').pop()
  if (fileEnding){
    return mostCommonImageFormatExtensions.includes(fileEnding)
  }
  return false
}

export const convertImageToFile = (base64ImageRepresentation: string | undefined, format: string): File | undefined => {
  const blob = convertImageToBlob(base64ImageRepresentation, format)
  if (blob){
    return convertBlobToFile(blob, getRandomNameForFile(format))
  }
  return undefined
}

/**
 * Returns a random file name using the current time's epoch milliseconds and the specified extension in lowercase.
 * @param fileExtension the file's extension , e.g.: 'jpeg', 'doc'.
 * @returns A file name with the format '${currentTimeMilliseconds}.{fileExtensionInLowercase}'
 */
export const getRandomNameForFile = (fileExtension: string) => {
  return DateTime.now().toMillis() + "." + fileExtension.toLocaleLowerCase()
}

export const downloadUploadedFile = async (
  uploadedFile: Pick<UploadedFile, 'id' | 'fileName' | 'fileSizeInBytes' | 'updatedAt' | 'signedUrlForDownload'>,
  onProgressUpdate: (inProgress: boolean) => Promise<void> | void,
  saveToDownloadsFolder = true,
  isFlagMW2039ChatUserCannotOpenReceivedFilesEnabled = false,
): Promise<File | null | undefined>  => {
  try {
    onProgressUpdate(true)
    const url = uploadedFile.signedUrlForDownload.url
    if (!url) return

    const fileName = uploadedFile.fileName
    if (!fileName) return

    const result = await fetch(url)
    const blob = await result.blob()

    if ((isPlatform('desktop') || isPlatform('mobileweb')) && saveToDownloadsFolder) {
      saveFileToDownloadsFolder(fileName, blob)
    }

    const file = convertBlobToFile(blob, fileName)
    await saveFileToDeviceStorage(file, isFlagMW2039ChatUserCannotOpenReceivedFilesEnabled)

    onProgressUpdate(false)
    return file
  } catch (err) {
    onProgressUpdate(false)
    throw new Error(`File ${uploadedFile.fileName} could not be saved. ${err}`)
  }
}
