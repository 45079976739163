import React from 'react'
import { IonIcon, IonItem } from '@ionic/react'
import { createOutline } from 'ionicons/icons'
import TeamMembers from '../TeamMembers/TeamMembers'
import ButtonChangeLeadPreferences from '../ContractorLeadPreferences/ButtonChangeLeadPreferences'
import RangeBadges from './RangeBadges'
import ListWorkHistoryItems from './ListWorkHistoryItems'
import { pageConfig_Profile_Edit, useRouteTo } from '../../../routes'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { MyContractorProfileScreenProps, MyContractorScreenNames } from './MyContractorProfilePage'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader from '../../../common/components/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { MyContractorProfileDetailsCard } from './MyContractorProfileDetailsCard'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { MyWorkHistoryTimeline } from './MyWorkHistoryTimeline'

import Styles from './MyContractorProfilePage.module.scss'

const MyContractorProfileScreenHome: React.FC<MyContractorProfileScreenProps & ScreenRenderProps<MyContractorScreenNames>> = (props) => {
  const routeToEditMyProfile = useRouteTo(pageConfig_Profile_Edit.path)
  const goToEditMyProfile = routeToEditMyProfile({})

  const weaverFlags = useWeaverFlags()
  const isNewContractorProfileEnabled = weaverFlags['MW-2171-new-contractor-profile'].enabled

  const hasAwardedBadges = !!props?.profile?.budgetRangeBadges

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle='My Profile' />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>

      { isNewContractorProfileEnabled
        ? <>
          <MyContractorProfileDetailsCard contractorProfile={props.profile} goToEditMyProfile={goToEditMyProfile} />
          {/** TODO: New badges integration -> https://weaver.atlassian.net/browse/MW-2170 **/}
          {hasAwardedBadges && <section className={`${Styles.tieredBadgeRangeContainer} ion-padding-horizontal`}>
            <h5 className={Styles.badgeTitle}>Badges</h5>
            <RangeBadges screenProps={props} width="90" height="90" />
          </section>
          }

          <section className={`${Styles.unlockedBudgetRangesButton} ion-padding-horizontal`}>
            <ButtonChangeLeadPreferences />
          </section>
        </>
        : <section className={Styles.companyInfoContainer}>
          <IonItem className={`${Styles.ionItem} ion-no-padding ${Styles.cursorEnabled}`} lines='none' onClick={goToEditMyProfile}>
            <h3 className={Styles.companyTradingName}>{props.profile.companyTradingAs}</h3>
            <IonIcon className={Styles.editIcon} color='primary' src={createOutline}></IonIcon>
          </IonItem>
          <p className={Styles.contractorTeamType}>Main Contractor</p>
          <p>{formatAddressToSingleLine(props.profile.companyAddress) ?? <em>No Address</em>}</p>
          <RangeBadges screenProps={props} width="90" height="90" />
        </section>
      }

      {!isNewContractorProfileEnabled && <section className={Styles.unlockedBudgetRangesContainer}>
        <ButtonChangeLeadPreferences />
      </section>}

      {isNewContractorProfileEnabled
        ? <section className={Styles.listWorkHistoryContainer}>
          <h3 className={[ Styles.pastProjectsTitle, 'ion-padding-horizontal' ].join(" ")}>Past projects</h3>
          {!props.profile.workHistory && <p className="ion-padding">No work history has been added</p>}
          <MyWorkHistoryTimeline {...props} />
        </section>
        : <section>
          <ListWorkHistoryItems {...props} />
        </section>
      }

      <section className={Styles.teamMemberListContainer}>
        <TeamMembers teamId={props.profile.id} />
      </section>
    </WeaverIonContent>
  </>
}

export default MyContractorProfileScreenHome
