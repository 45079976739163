import React from "react"
import { IonBackButton, IonButtons, IonMenuButton, IonTitle, IonToolbar, useIonRouter } from "@ionic/react"
import { getKnownRoutePathForPages, Page } from "../../../routes"
import Styles from "./GlobalHeader.module.scss"

export type GlobalHeaderProps = {
  pageTitle?: string,
  /** Page subtitle indicates the light-font text next to the title of the header */
  pageSubtitle?: string,
  /** If we this page has a top-left navigation element, setting this will override it */
  navElement?: JSX.Element,
}

export const NavElement: { [key: string]: GlobalHeaderProps['navElement'] } = {
  None: <></>,
}

const GlobalHeader: React.FC<GlobalHeaderProps> = ({ pageTitle, pageSubtitle, navElement }) => {
  const hideButtonOnThesePages = [
    Page.Projects,
    Page.MyChats,
    Page.Onboarding,
    Page.Profile,
    Page.Help,
    Page.Success,
  ]

  const router = useIonRouter()
  const routePathsToHide = getKnownRoutePathForPages(...hideButtonOnThesePages)
  const hideNavigation = routePathsToHide.find(route => router.routeInfo?.pathname === route)

  return (
    <IonToolbar>
      <IonButtons slot="start">
        {
          // Always use the nav element, if provided
          navElement
            ? navElement
            // Otherwise, show the hamburger, which opens the tray on the left handside, or the back button
            : hideNavigation
              ? <IonMenuButton autoHide={false} />
              : <IonBackButton className={Styles.backButton} defaultHref="/projects"/>
        }
      </IonButtons>
      {pageTitle && <IonTitle>{pageTitle} {pageSubtitle && <span className={Styles.pageSubtitle} color='dark'> &#8211; {pageSubtitle}</span>}</IonTitle>}
    </IonToolbar>
  )
}

export default GlobalHeader
