import { IonButton } from '@ionic/react'
import React, { useCallback } from 'react'
import Styles from "./ChatRoom/Chat.module.scss"
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import { useScreensWithProps } from '../../../common/hooks/useScreens'
import { isRunningLocallyInDevelopment } from '../../../common/utils/ionic'
import { useParamsFromPageConfig } from '../../../routesProvider'
import { useGraphQLDataSource } from '../../../api/graphql'
import { useGetChatRoomQuery } from '../../../graphql/generated'
import FilePicker from './FilePicker/FilePicker'
import ChatRoomPresenceTracker from './ChatRoom/ChatRoomPresenceTracker'
import ChatRoomProvider from './ChatRoom/services/ChatRoomProvider'
import ChatRoom from './ChatRoom'

const PACKAGE = 'TechDemoPage.ChatterPage'

enum ChatRoomScreens {
  ChatRoom = "ChatRoom",
  FilePicker = "FilePicker",
}

const ChatRoomContainer: React.FC<{chatRoomId: string, goToShowChatRoomScreen : () => void}> = ({ chatRoomId, goToShowChatRoomScreen }) => {
  return (
    <WeaverIonContent>
      <IonButton onClick={ () => goToShowChatRoomScreen() }>Go to File Picker {chatRoomId}</IonButton>
    </WeaverIonContent>
  )
}

const ChatRoomPresenceChecker : React.FC = ({ children }) => {
  const shouldTrackUserPresence = !isRunningLocallyInDevelopment
  return shouldTrackUserPresence
    ? <ChatRoomPresenceTracker>
      <>{children}</>
    </ChatRoomPresenceTracker>
    :  <>{children}</>
}

const ErrorWhileLoadingChatPage: React.FC = () =>
  <ErrorBlockPage name='ErrorWhileLoadingChatPage'><p>There is an error loading the chatroom!</p></ErrorBlockPage>

const ChatRoomPage: React.FC = () => {
  const { chatRoomId } = useParamsFromPageConfig<{chatRoomId: string}>()

  type ScreenProps = {
    chatRoomId: typeof chatRoomId,
  }

  const [ Screen, activeScreen ] = useScreensWithProps<ChatRoomScreens, ScreenProps>({
    init: ChatRoomScreens.ChatRoom,
    screenProps: {
      chatRoomId,
    },
    screens: {
      ChatRoom: {
        render: useCallback(({ useChangeScreen }) => {
          const goToFilePickerScreen = useChangeScreen(ChatRoomScreens.FilePicker)
          return <ChatRoom goToFilePickerScreen={goToFilePickerScreen}/>
        }, []),
      },
      FilePicker: {
        render: useCallback(({ useChangeScreen, previousScreen }) => {
          const changeToPreviousScreen = useChangeScreen(previousScreen)
          return <FilePicker goBackToPreviousScreen={changeToPreviousScreen}/>
        }, []),
      },
    },
  })

  const LOCAL_PACKAGE = `${PACKAGE}.ChatRoomLoader`

  const fiveMinutesInMilliseconds = 5 * 60 * 1000
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getChatRoomQuery = useGetChatRoomQuery(gqlDataSource, { chatRoomId }, {
    staleTime: fiveMinutesInMilliseconds,
  })

  const chatRoom = getChatRoomQuery?.data?.getChatRoom
  const projectId = chatRoom?.project?.id

  if (getChatRoomQuery.isLoading) return <LoadingSpinnerPage name={`${LOCAL_PACKAGE}.undefined`} />

  if (getChatRoomQuery.error) return <ErrorWhileLoadingChatPage />

  if (!chatRoom) return <ErrorWhileLoadingChatPage />

  return (
    <ChatRoomProvider chatRoomId={chatRoomId} chatRoomName={chatRoom?.name} projectId={projectId}>
      <WeaverIonPage id='ChatRoomPage' key={activeScreen} className={Styles.chatPage} disableDirectChildStructureChecks={true}>
        <ChatRoomPresenceChecker>
          {Screen}
        </ChatRoomPresenceChecker>
      </WeaverIonPage>
    </ChatRoomProvider>
  )
}

export default ChatRoomPage
