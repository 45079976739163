import { DateTime } from 'luxon'
import React, { useEffect, useRef, useState } from 'react'
import { useGraphQLDataSource } from '../../../../api/graphql'
import { FileEventInfo } from '../../../../api/providers/SegmentProvider/events'
import { SignedUrl, UploadedFile, UploadedFileStatus, useCreateChatRoomMessageUploadedFileMutation, useUpdateChatRoomMessageUploadedFileMutation } from '../../../../graphql/generated'
import UploadableFileChipErrored from './UploadableFileChipErrored'
import UploadableFileChipInProgress from './UploadableFileChipInProgress'
import UploadableFileSucceededChip from './UploadableFileChipSucceded'
import UploadableFileChipUnstarted from './UploadableFileChipUnstarted'

type UploadableFileChipProps = {
  // The file to be uploaded
  file: File,
  onUploadedFileCreated: (result: CreateUploadedFileReturns) => Promise<unknown> | void,
  // This function is called when a file is removed by the user.
  onUploadedFileArchived: (result: UpdateUploadedFileReturns) => Promise<unknown> | void,
  trackEvent?: (eventInfo: FileEventInfo) => void,
}

enum UploadStatus {
  Unstarted = 'Unstarted',
  InProgress = 'InProgress',
  Error = 'Error',
  Success = 'Success',
}

export type CreateUploadedFileReturns = Pick<UploadedFile, 'id' | 'status' | 'fileName' | 'fileContentType' | 'fileSizeInBytes'> & { signedUrlForUpload: Pick<SignedUrl, 'status' | 'url'> } & { signedUrlForDownload: Pick<SignedUrl, 'status' | 'url'> }

export type UpdateUploadedFileReturns = Pick<UploadedFile, 'id' | 'status' | 'fileName' | 'fileContentType' | 'fileSizeInBytes'>

/**
 * Represents a Chip that the first time that's rendered it will call the createDocument function in order to obtain a upload Url. If the previous step is successful the specified File is uploaded using the Url.
 * In this scenario a Document represents something in the Database server, while File is something local, associated to the device's File system.
 */
export const UploadableFileChip: React.FC<UploadableFileChipProps> = ({ file, onUploadedFileCreated, onUploadedFileArchived, trackEvent = () => null }) => {
  const [ uploadStatus, setUploadStatus ] = useState<UploadStatus>(UploadStatus.Unstarted)
  const uploadedFileId = useRef<string>("")
  const uploadUrl = useRef<string>("")

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const createUploadFileMutation = useCreateChatRoomMessageUploadedFileMutation(gqlDataSource)
  const updateUploadFileMutation = useUpdateChatRoomMessageUploadedFileMutation(gqlDataSource)

  const updateUploadFileStatus = (uploadFileId: string, status: UploadedFileStatus) =>
    updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadFileId,
        status,
      },
    })

  const archiveUploadedFile = (uploadedFileId: string) =>
    updateUploadFileMutation.mutateAsync({
      input: {
        id: uploadedFileId,
        status: UploadedFileStatus.Archived,
      },
    })

  const createUploadedFile = async (file: File) => {
    const result = await createUploadFileMutation.mutateAsync({
      input: {
        fileName: file.name,
        fileContentType: file.type,
        fileSizeInBytes: file.size,
      },
    })
    if (result){
      await onUploadedFileCreated(result.createUploadedFile)
      return result.createUploadedFile
    }
    return undefined
  }

  const onArchiveFileClicked = async () => {
    // Don't let the user remove the card while the upload is in progress
    if (uploadStatus === UploadStatus.InProgress)
      return undefined

    const wasDocumentCreated = uploadedFileId !== undefined
    if (wasDocumentCreated){
      const result = await archiveUploadedFile(uploadedFileId.current)
      onUploadedFileArchived(result.updateUploadedFile)
    }
  }

  const retryUpload = () => uploadFile(uploadUrl.current)

  const uploadFile = async (uploadUrl: string) => {
    const startOfUpload = DateTime.now()
    await updateUploadFileStatus(uploadedFileId.current, UploadedFileStatus.Uploading)

    setUploadStatus(UploadStatus.InProgress)
    try {
      const result = await fetch(uploadUrl, { method: "PUT", body: file })
      const finishedUpload = DateTime.now()
      const uploadSuccess = result.status === 200

      await updateUploadFileStatus(uploadedFileId.current, UploadedFileStatus.Completed)
      setUploadStatus(uploadSuccess ? UploadStatus.Success : UploadStatus.Error)

      if (uploadSuccess) {
        trackEvent({
          fileName: file.name,
          fileSizeInBytes: file.size,
          transferDurationInMilliseconds: finishedUpload.diff(startOfUpload, [ "milliseconds" ]).toObject().milliseconds,
          fileType: file.type,
        })
      }
    } catch (error) {
      await updateUploadFileStatus(uploadedFileId.current, UploadedFileStatus.Failed)
      setUploadStatus(UploadStatus.Error)
      console.log(error)
    }
  }

  useEffect(() => {
    const attemptToCreateDocument = async () => {
      const result = await createUploadedFile(file)
        .catch((error: Error) => {
          console.log('Error while creating the document ', error)
          throw error
        })
      if (result && result.signedUrlForUpload && result.signedUrlForUpload.url) {
        uploadedFileId.current = result.id
        uploadUrl.current = result.signedUrlForUpload.url
        await uploadFile(result.signedUrlForUpload.url)
      }
    }
    if (UploadStatus.Unstarted) {
      attemptToCreateDocument()
    }
  }, [])

  if (uploadStatus === UploadStatus.InProgress)
    return <UploadableFileChipInProgress file={file} onRemoveFileClicked={onArchiveFileClicked} />

  if (uploadStatus === UploadStatus.Error)
    return <UploadableFileChipErrored file={file} onRemoveFileClicked={onArchiveFileClicked} retryUpload={retryUpload}  />

  if (uploadStatus === UploadStatus.Success)
    return <UploadableFileSucceededChip file={file} onRemoveFileClicked={onArchiveFileClicked} />

  return <UploadableFileChipUnstarted file={file} onRemoveFileClicked={onArchiveFileClicked}/>
}
