import React, { useState } from "react"
import { IonButton, IonIcon, IonSpinner } from "@ionic/react"
import { useAutoRetryGetUploadedFileQuery } from "../../hooks/useAutoRetryGetUploadedFileQuery"
import DownloadUploadedFileModalStyles from "./DownloadUploadedFileModal.module.scss"
import { downloadUploadedFile } from "../../utils/files"
import { cloudDownloadOutline, refreshOutline } from "ionicons/icons"
import { UseQueryResult } from '@tanstack/react-query'
import { GetUploadedFileQuery } from "../../../graphql/generated"
import { useWeaverFlags } from "../../../api/thirdParty/launchDarkly/useWeaverFlags"

type DownloadUploadedFileModalType = {
  uploadedFileQuery: UseQueryResult<GetUploadedFileQuery, unknown>,
}

const TryAgainButton: React.FC<{refetch: () => void}> = ({ refetch }) => (
  <IonButton onClick={() => refetch()} size="small" color="danger">
    <IonIcon icon={refreshOutline} /> Try Again
  </IonButton>
)

const DownloadUploadedFileModal: React.FC<DownloadUploadedFileModalType> = ({ uploadedFileQuery }) => {
  const maxAttemptsReached = useAutoRetryGetUploadedFileQuery(uploadedFileQuery)
  const [ isDownloadingInProgress, setIsDownloadingInProgress ] = useState<boolean>(false)
  const weaverFlags = useWeaverFlags()
  const { refetch } = uploadedFileQuery
  const uploadedFile = uploadedFileQuery.data?.getUploadedFile

  const url = uploadedFileQuery.data?.getUploadedFile.signedUrlForDownload.url

  if (uploadedFileQuery.isLoading || !url ){
    return <div className={DownloadUploadedFileModalStyles.container}>
      <IonSpinner /><span className={DownloadUploadedFileModalStyles.statusDescription}>Processing the file.</span>
    </div>
  }

  if (!uploadedFile) return  <TryAgainButton refetch={refetch}/>

  if (maxAttemptsReached){
    return <TryAgainButton refetch={refetch}/>
  }

  const onProgressUpdate = (inProgress: boolean) => {
    setIsDownloadingInProgress(inProgress)
  }

  return (
    <div className={DownloadUploadedFileModalStyles.container} >
      <span className={DownloadUploadedFileModalStyles.statusDescription}>Click to download.</span>
      {
        isDownloadingInProgress
          ? <IonSpinner className={DownloadUploadedFileModalStyles.downloadSpinner} name="bubbles" />
          : <IonButton fill='clear' size='small'
            className={DownloadUploadedFileModalStyles.downloadButton}
            onClick={() => { downloadUploadedFile(uploadedFile, onProgressUpdate, weaverFlags['MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents'].enabled) }}>
            <IonIcon icon={cloudDownloadOutline}/>
          </IonButton>
      }
    </div>
  )
}

export default DownloadUploadedFileModal
