import React from 'react'
import ChatFooter from './footer/ChatFooter'
import ChatContentWithNewMessagesLines from './ChatContent'
import ChatContentWithoutNewMessagesLine from './ChatContentWithoutNewMessagesLine'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { useChatRoom } from './services/ChatRoomProvider'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar, useIonRouter } from '@ionic/react'
import { arrowBackOutline, ellipsisVertical } from 'ionicons/icons'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { pageConfig_ChatRoomMembers, useRouteTo } from '../../../../routes'

type ChatRoomProps = {
  goToFilePickerScreen: () => void,
}

const ChatRoom: React.FC<ChatRoomProps> = ({ goToFilePickerScreen }) => {
  const { chatRoomName, chatRoomId } = useChatRoom()
  const weaverFlags = useWeaverFlags()
  const ionRouter = useIonRouter()

  const goToChatRoomMembersPage = useRouteTo(pageConfig_ChatRoomMembers.path)

  const goBack = () => ionRouter.goBack()

  const shouldDisplayGoToChatRoomMembersButton = weaverFlags['chat-members'].enabled && weaverFlags['chat-members'].showChatMembers
  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <IonToolbar>
          <IonButtons slot="start">
            <IonButton onClick={goBack}>
              <IonIcon icon={arrowBackOutline}/>
            </IonButton>
          </IonButtons>
          <IonTitle>{chatRoomName || 'My Chat'}</IonTitle>
          <IonButtons slot="end" >
            <IonButton onClick={ goToChatRoomMembersPage({ chatRoomId: chatRoomId }) } hidden={!shouldDisplayGoToChatRoomMembersButton}>
              <IonIcon icon={ellipsisVertical}/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </WeaverIonHeader>

      {
        !weaverFlags['MW-2115-implement-the-new-messages-line-indicator'].enabled && <ChatContentWithoutNewMessagesLine />
      }

      {
        weaverFlags['MW-2115-implement-the-new-messages-line-indicator'].enabled && <ChatContentWithNewMessagesLines />
      }

      <ChatFooter goToFilePickerScreen={goToFilePickerScreen} />
    </>
  )
}

export default ChatRoom
