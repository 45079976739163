import { IonAvatar, IonIcon, IonItem, IonLabel } from '@ionic/react'
import { DateTime } from 'luxon'
import React from 'react'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { MyContractorProfileScreenProps } from './MyContractorProfilePage'

import Styles from '../PublicContractorProfile/WorkHistoryItem.module.scss'

export const ContractorTimelineCard:React.FC<MyContractorProfileScreenProps> = (props) => {

  const contractorProfile = props.profile

  return (
    <IonItem lines='none' className={Styles.profileWorkHistoryItem}>
      <IonAvatar slot="start" className={[ Styles.contractorAvatar, "ion-margin-top" ].join(" ")}>
        <IonIcon color="primary" icon={allIonIcons.business} />
      </IonAvatar>

      <IonLabel className={Styles.ionItemBody}>
        <h2>Company incorporated</h2>
        <p className={Styles.contractorTeamType}>{props.profile.companyTradingAs}</p>
        <p>Private limited company</p>
        <p>Company number: {contractorProfile.companyNumber}</p>
        {contractorProfile.companyIncorporationDate && <p>Date of incorporation: {DateTime.fromISO(contractorProfile.companyIncorporationDate).toFormat("MMMM yyyy")}</p>}

        <section className={Styles.contractorOfficeContainer}>
          <p className={Styles.registeredOfficeTitleAddress}>Registered address:</p>
          <p className={Styles.registeredOfficeTitleAddress}>{formatAddressToSingleLine(contractorProfile.companyAddress) ?? ""}</p>
        </section>
      </IonLabel>
    </IonItem>
  )
}
