import React, { ComponentProps, useState } from 'react'
import { IonButton } from '@ionic/react'
import WorkHistoryForReferenceModal from './WorkHistoryForReferenceModal'
import WorkHistoryForReference from './WorkHistoryForReference'

type WorkHistoryForReferenceModalButtonProps = ComponentProps<typeof WorkHistoryForReference> & ComponentProps<typeof IonButton>

/**
 * A wrapper for IonButton which additionally takes the `WorkHistoryForReference` props as `referenceIds`.
 * It shows the `WorkHistoryForReferenceModal` modal `onClick` (in addition to calling any passed `onClick`).
 */
const WorkHistoryForReferenceModalButton: React.FC<WorkHistoryForReferenceModalButtonProps> = (props) => {
  const [ showModal, setShowModal ] = useState(false)

  // Split props into `workHistoryForReferenceProps` and `ionButtonProps`
  const { contractorProfile, workHistoryItem, reference, ...ionButtonProps } = props
  const workHistoryForReferenceProps = { contractorProfile, workHistoryItem, reference }

  return <>
    <IonButton {...ionButtonProps} onClick={(...onClickArgs) => {
      setShowModal(true)
      ionButtonProps.onClick && ionButtonProps.onClick(...onClickArgs)
    }}/>
    <WorkHistoryForReferenceModal
      {...workHistoryForReferenceProps}
      breakpoints={[ 0, 0.5, 0.75, 0.99 ]}
      initialBreakpoint={0.99}
      isOpen={showModal}
      canDismiss={true}
      onDidDismiss={() => setShowModal(false)}
    />
  </>
}

export default WorkHistoryForReferenceModalButton
