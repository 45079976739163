import { useIonToast } from "@ionic/react"
import { UseQueryResult } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../../api/graphql"
import { GetActionableTaskQuery, TaskStatus, useSetTaskStatusMutation } from "../../../graphql/generated"
import { pageConfig_MyProjectTasks, useRouteTo } from "../../../routes"
import { checkmarkCircleOutline } from 'ionicons/icons'
import { useAnalyticsEvent } from "../../../api/providers/SegmentProvider/hooks"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"

type MarkTaskStatusOptions = {
  onSetTaskSuccess?: NonNullable<Parameters<typeof useSetTaskStatusMutation>[1]>["onSuccess"],
  /** Defaults to `true`. When `false`, the mutation will do nothing after completion */
  routeToMyProjectOnSuccess?: boolean,
}

const useMarkTaskStatusMutation = (
  getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>,
  newStatus: TaskStatus,
  { onSetTaskSuccess, routeToMyProjectOnSuccess = true }: MarkTaskStatusOptions = {},
) => {
  const datasource = useGraphQLDataSource({ api: 'core' })
  const activeTeam = useMyIndividualActiveTeam()
  if (!activeTeam) throw new Error("useMarkTaskStatusMutation requires an active team")

  const fireTaskStatusChanged = useAnalyticsEvent("Task_Status_Changed")
  const setTaskStatusMutation = useSetTaskStatusMutation(datasource, { onSuccess: async (data, variables, context) => {
    const previous = getActionableTaskQuery.data?.getTask
    if (!previous?.id) throw new Error(`[useMarkTaskStatusMutation.${newStatus}] Missing task data`)

    await Promise.all([ async () => onSetTaskSuccess && onSetTaskSuccess(data, variables, context),
      fireTaskStatusChanged({
        projectId: previous.projectId,

        taskId: variables.taskId,
        taskTitle: previous.title,
        taskDueAt: previous.dueAt,
        taskStatus: variables.newStatus,
        taskPreviousStatus: previous.status,
        taskActionableType: previous.actionableType,
        taskActionablePayload: previous.actionablePayload,
      }),
    ])
  } })

  const useRouteToMyProjectTasks = useRouteTo(pageConfig_MyProjectTasks.path)

  return async () => {
    if (!getActionableTaskQuery.data?.getTask.id) throw new Error(`[useMarkTaskStatusMutation.${newStatus}] Missing task data`)

    await setTaskStatusMutation.mutateAsync({
      taskId: getActionableTaskQuery.data.getTask.id,
      newStatus,
    })

    if (routeToMyProjectOnSuccess) {
      await useRouteToMyProjectTasks({ projectId: getActionableTaskQuery.data.getTask.projectId })()
    }
  }
}

export const useMarkTaskCompleteMutation = (getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>, options: Omit<MarkTaskStatusOptions, "onSetTaskSuccess"> = {} ) => {
  const [ presentToast ] = useIonToast()

  return useMarkTaskStatusMutation(getActionableTaskQuery, TaskStatus.Completed, {
    ...options,
    onSetTaskSuccess: () => {
      presentToast({
        duration: 3000,
        position: 'bottom',
        icon: checkmarkCircleOutline,
        header: 'Task Complete',
        message: getActionableTaskQuery.data?.getTask.title,
        color: 'success',
      })
    },
  })
}

export const useMarkTaskSkippedMutation = (getActionableTaskQuery: UseQueryResult<GetActionableTaskQuery, unknown>) =>
  useMarkTaskStatusMutation(getActionableTaskQuery, TaskStatus.Skipped)
