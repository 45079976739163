import React, { ComponentProps } from 'react'
import { IonButton, IonGrid, IonCol, IonRow } from '@ionic/react'
import RangeBadge from './RangeBadge'
import Styles from './AnimatedBudgetRangeBadgePage.module.scss'
import { budgetRangeLabels } from '../ContractorBudgetRanges/budgetRanges.i18n'
import { BudgetRange } from '../../../../graphql/generated'
import SingleClickButton from '../../../../common/components/SingleClickButton'
import { BadgeLevel } from '../ContractorBudgetRanges/UnlockContractorBudgetRange/BadgeUtils'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

type AnimatedBudgetRangeBadgePageProps = {
  title: string,
  subtitle: string,
  description: string,
  budgetRange: BudgetRange,
  actionButtonProps: ComponentProps<typeof IonButton>,
  badgeLevel?: BadgeLevel,
  onClick?: () => void,
}

const AnimatedBudgetRangeBadgePage: React.FC<AnimatedBudgetRangeBadgePageProps> = ({ title, subtitle, budgetRange, description, actionButtonProps, onClick, badgeLevel }) => {
  const weaverFlags = useWeaverFlags()
  const multipleWorkHistoryFlagEnabled = weaverFlags["MW-2096-multiple-work-histories"].enabled

  return <IonGrid>
    <IonRow className='ion-justify-content-center ion-align-items-center'>
      <IonCol className='ion-margin-top ion-text-center'>
        <div className={Styles.fadeInUp}>
          <h5><b>{title}</b></h5>
          <h1><b>{subtitle}
            <br/>{multipleWorkHistoryFlagEnabled && 'badge' }
          </b></h1>
          <span>{description}<br/>{budgetRangeLabels[budgetRange]} range</span>
        </div>
        <div className={`${Styles.pulse} ${Styles.badge}`}>
          <RangeBadge budgetRange={budgetRange} badgeLevel={badgeLevel} />
        </div>
        <SingleClickButton onClick={onClick} {...actionButtonProps} className={`${Styles.continueBtn} ${actionButtonProps.className}`} />
      </IonCol>
    </IonRow>
  </IonGrid>
}

export default AnimatedBudgetRangeBadgePage
