import React, { useCallback, useState } from 'react'
import  BrowseFilesButton from  '../../../../common/components/BrowseFilesButton'
import { DateTime } from 'luxon'
import { DeviceStorageFileList } from './DeviceStorageFileList'
import { IonButton, IonButtons, IonIcon, IonTitle, IonToolbar, useIonAlert } from '@ionic/react'
import { readFileFromDeviceStorageDeprecated, readFileFromDeviceStorage, saveFileToDeviceStorage } from '../../../../common/utils/files'
import { useChatRoom } from '../ChatRoom/services/ChatRoomProvider'
import LoadingSpinner from '../../../../common/components/LoadingSpinner'
import Styles from "./FilePicker.module.scss"
import useDeviceStorageFiles, { StoredFile } from '../../../../common/hooks/useDeviceStorageFiles'
import { chevronBack } from 'ionicons/icons'
import WeaverIonHeader from '../../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../../common/components/WeaverIonWrappers/WeaverIonContent'
import GlobalHeaderStyles from '../../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useWeaverFlags } from '../../../../api/thirdParty/launchDarkly/useWeaverFlags'

const PACKAGE = 'chat.filepicker'

const NoRecentFilesPlaceholder: React.FC = () => {
  return (
    <div className={Styles.emptyRecentFilesPlaceholder}>
      Your recent documents will appear here. Add your first document below 👇
    </div>
  )
}

export type FilesToUpload = {
  [fileName: string]: boolean,
}

type FilePickerProps = {
  goBackToPreviousScreen: () => void,
}

const MAX_FILES_SELECTABLE_BY_USER = 5

const FilePicker: React.FC<FilePickerProps> = ({ goBackToPreviousScreen }) => {
  const { files } = useDeviceStorageFiles()
  const { addFileToNewMessage, addFilesToNewMessage } = useChatRoom()
  const LOCAL_PACKAGE = `${PACKAGE}.FilePicker`
  const [ multiAddState, setMultiAddState ] = useState(false)
  const [ filesToUpload, setFilesToUpload ] = useState<FilesToUpload>({})
  const [ present ] = useIonAlert()
  const weaverFlags = useWeaverFlags()

  const selectSingleFile = useCallback(async (storedFile: StoredFile) => {
    if (multiAddState) return

    if (weaverFlags['MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents'].enabled) {
      const result = await readFileFromDeviceStorage(storedFile.name)

      if (result === undefined) return

      addFileToNewMessage(result)

    } else {
      const blob = await readFileFromDeviceStorageDeprecated(storedFile.name)
      if (blob === undefined) return

      const file = new File([ blob ], storedFile.name, {
        lastModified: DateTime.now().toMillis(),
        type: blob.type,
      })
      addFileToNewMessage(file)
    }
    goBackToPreviousScreen()
  }, [ readFileFromDeviceStorageDeprecated, addFileToNewMessage, goBackToPreviousScreen, multiAddState ])

  const filterFiles = () => {
    const fileNamesToUpload: string[] = []
    const filesToFilter = Object.entries(filesToUpload)
    const filteredToShowIsChecked = filesToFilter.filter(([ , value ]) => value === true)
    filteredToShowIsChecked.forEach((key) => fileNamesToUpload.push(key[0]))
    return fileNamesToUpload
  }

  const displayMaxFilesSelectedExceededPopUp = () => present({
    header: "Warning",
    message: `Some files could not be uploaded, because number of uploaded files is exceeding the maximum of ${MAX_FILES_SELECTABLE_BY_USER}. Try uploading less than ${MAX_FILES_SELECTABLE_BY_USER} files.`,
    buttons: [
      {
        text: "Ok",
        role: "cancel",
      },
    ],
  })

  const ensureSelectedFilesDoNotExceedMaximum = async <T,>(selectedFiles: T[]) => {
    if (selectedFiles.length > MAX_FILES_SELECTABLE_BY_USER) {
      await displayMaxFilesSelectedExceededPopUp()
      return selectedFiles.slice(0, MAX_FILES_SELECTABLE_BY_USER)
    }
    return selectedFiles
  }

  const onFilesSelectedByUser = useCallback(async (userSelectedFiles: File[]) => {
    const filteredFiles = await ensureSelectedFilesDoNotExceedMaximum(userSelectedFiles)
    const isMW2066FlagEnabled = weaverFlags['MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents'].enabled
    await Promise.all(filteredFiles.map( file => saveFileToDeviceStorage(file, isMW2066FlagEnabled)))
      .catch(error => {
        console.log(`Error while saving files ${userSelectedFiles}`)
        throw error
      })

    addFilesToNewMessage(filteredFiles)
    goBackToPreviousScreen()
  }, [ saveFileToDeviceStorage, addFileToNewMessage, goBackToPreviousScreen ])

  const selectMultipleFiles = useCallback(async () => {
    const filteredSelectedFiles = await ensureSelectedFilesDoNotExceedMaximum(filterFiles())

    if (filteredSelectedFiles.length === 0) return
    const filesSelected: File[] = []
    const readSelectedFiles = async () => {
      const promises = filteredSelectedFiles.map(async (value) => {
        if (weaverFlags['MW-2066-links-always-downloading-for-files-uploaded-from-recent-documents'].enabled){
          const result = await readFileFromDeviceStorage(value)

          // This is related to MW-2066: this is necessary so Typescript doesn't complain about result being a Blob or File.
          // readFileFromDeviceStorage can return a Blob when reading old files and when the flag for MW-2066 is enable

          if (result){
            filesSelected.push(result)
          }
        } else {
          const blob = await readFileFromDeviceStorageDeprecated(value)
          if (blob){
            const file = new File([ blob ], value, {
              lastModified: DateTime.now().toMillis(),
              type: blob.type,
            })

            filesSelected.push(file)
          }
        }

      })
      await Promise.all(promises)
      return filesSelected
    }
    const files = await readSelectedFiles()
    addFilesToNewMessage(files)
    goBackToPreviousScreen()
  }, [ filesToUpload, setFilesToUpload, readFileFromDeviceStorageDeprecated, addFilesToNewMessage, goBackToPreviousScreen ])

  const setSelected = (fileName: string, isChecked: boolean) =>
    setFilesToUpload({
      ...filesToUpload,
      [fileName]: isChecked,
    })

  return (
    <>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <IonToolbar>
          <IonButtons slot="start">
            {
              multiAddState
                ? <IonButton color="danger" onClick={() => { setMultiAddState(!multiAddState) }}>Cancel</IonButton>
                : <IonButton className={Styles.backButton} color="primary" onClick={() => goBackToPreviousScreen()}>
                  <IonIcon icon={chevronBack}/>Back
                </IonButton>
            }
          </IonButtons>
          <IonButtons slot="end" >
            {
              multiAddState
                ? <IonButton color="primary" onClick={() => selectMultipleFiles()}>Done</IonButton>
                : <IonButton color="primary" onClick={() => setMultiAddState(!multiAddState)}>Select</IonButton>
            }
          </IonButtons>
          {multiAddState
            ? (filterFiles().length === 0)
              ? <IonTitle>Select Items</IonTitle>
              : <IonTitle>{filterFiles().length} Selected</IonTitle>
            : <IonTitle>Recent Documents</IonTitle>
          }
        </IonToolbar>
      </WeaverIonHeader>
      <WeaverIonContent scrollY={false} useFullHeight={true}>
        <h4 className={Styles.header}>Recent Documents</h4>
        {
          files === undefined
            ? <LoadingSpinner name={`${LOCAL_PACKAGE}`} />
            : files.length === 0
              ? <>
                <NoRecentFilesPlaceholder />
                <BrowseFilesButton onFilesSelectedByUserCallback={onFilesSelectedByUser} multipleSelection={true}/>
              </>
              : <>
                <BrowseFilesButton onFilesSelectedByUserCallback={onFilesSelectedByUser} multipleSelection={true}/>
                <DeviceStorageFileList files={files} multiAddState={multiAddState}
                  selectFile={selectSingleFile} filesToUpload={filesToUpload} setSelected={setSelected}
                />
              </>
        }
      </WeaverIonContent>
    </>
  )
}

export default FilePicker
