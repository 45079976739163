import React, { Dispatch, SetStateAction } from 'react'
import { IonCheckbox, IonDatetime, IonGrid, IonItem, IonLabel, IonNote, IonRow, isPlatform } from '@ionic/react'
import Styles from './ConstructionDates.module.scss'
import IndicatorBar, { IndicatorBarProps } from '../../../../../../projects/CreateProjectPage/IndicatorBar'
import { isMobile } from '../../../../../../../common/utils/ionic'
import { FieldError } from 'react-hook-form'
import { DateTime } from 'luxon'

type ConstructionCompletedDateProps = {
  value: string | undefined,
  setValue: (value: string | string[] | undefined | null) => Promise<void> | void,
  error?: FieldError | undefined,
  indicatorBarProps: IndicatorBarProps,
  constructionStartedDate: string,
  setConstructionIsStillOnGoing: Dispatch<SetStateAction<boolean>>,
  constructionIsStillOnGoing: boolean,
}

export const ConstructionCompletedDate: React.FC<ConstructionCompletedDateProps> = ({ indicatorBarProps, value , setValue, error, constructionStartedDate , setConstructionIsStillOnGoing , constructionIsStillOnGoing  }) => {
  const now = DateTime.now()
  const handleCheckBoxChange = (checked: boolean) => {
    setConstructionIsStillOnGoing(checked)
  }
  return <>
    <div className='ion-padding-top'>
      <IndicatorBar {...indicatorBarProps} />
    </div>
    <IonGrid>
      <IonRow class="ion-justify-content-center">
        <h3 className="ion-padding-vertically ion-padding-bottom"><b>When did the construction complete?</b></h3>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonDatetime
          value={value}
          max={now.toISO()}
          min={DateTime.fromISO(constructionStartedDate).toISO()}
          onIonChange={(e) => setValue(e.detail.value)}
          disabled={constructionIsStillOnGoing}
          className={Styles.ionDatetime}
          presentation={isPlatform('desktop') ? 'date' : 'month-year'}
        />
      </IonRow>
      <IonRow className={isMobile || isPlatform('mobileweb') ? `ion-justify-content-start` : `ion-justify-content-center`}>
        <IonItem lines='none' className={`${Styles.ionItem} ion-padding-top`}>
          <IonCheckbox
            slot="start"
            color='primary'
            checked={constructionIsStillOnGoing}
            onIonChange={(e) => handleCheckBoxChange(e.detail.checked)} />
          <IonNote color={'danger'}>{error && error.message}</IonNote>
          <IonLabel color={'primary'} >Construction is still ongoing</IonLabel>
        </IonItem>
      </IonRow>
    </IonGrid>
  </>
}

