import React from 'react'
import { useGraphQLDataSource } from '../../../api/graphql'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import WorkHistoryForReference from './WorkHistoryForReference'
import { useGetContractorProfileQuery, useLookupContractorProfileQuery } from '../../../graphql/generated'
import { useParamsFromPageConfig } from '../../../routesProvider'
import { alwaysArray } from '../../../common/utils'
import { firstElement } from '../../../common/utils/codeReadability'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'

const WorkHistoryForReferencePage: React.FC = () => {
  const { referenceId } = useParamsFromPageConfig<{ referenceId?: string }>()
  const triggerEventWorkHistoryReferencesPageViewed = useAnalyticsEvent('WorkHistory_References_Page_Viewed')

  // Load the data in stages

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const lookupQuery = useLookupContractorProfileQuery(
    gqlDataSource,
    { input: { workHistoryReferenceId: referenceId } },
    {
      staleTime: 5 * 60 * 1000,
    },
  )

  const {
    contractorProfileId,
    workHistoryId,
  } = alwaysArray(lookupQuery.data?.lookupContractorProfile).find(firstElement) ?? {}

  const contractorProfileQuery = useGetContractorProfileQuery(
    gqlDataSource,
    { contractorTeamId: contractorProfileId ?? '' },
    {
      enabled: !!contractorProfileId,
      staleTime: 5 * 60 * 1000,
    },
  )

  if (!referenceId) {
    console.error(`[external.reference.WorkHistoryForReferencePage] A reference ID must be specified: `, { lookupQuery, contractorProfileQuery, referenceId })
    return <ErrorBlockPage name='WorkHistoryForReferencePage' />
  }

  if (lookupQuery.isLoading || contractorProfileQuery.isLoading) {
    return <LoadingSpinnerPage name="external.reference.WorkHistoryForReferencePage" />
  }

  if (lookupQuery.error || !contractorProfileId || !workHistoryId) {
    console.error(`[external.reference.WorkHistoryForReferencePage] Failed to find any Contractor Profiles by Reference ID: `, { lookupQuery, contractorProfileQuery, referenceId, contractorProfileId, workHistoryId })
    return <ErrorBlockPage name='WorkHistoryForReferencePage' onRefresh={lookupQuery.refetch} />
  }

  const contractorProfile = contractorProfileQuery.data?.getContractorProfile
  const workHistoryItem = alwaysArray(contractorProfileQuery.data?.getContractorProfile?.workHistory).find(each => each.id === workHistoryId)
  const reference = alwaysArray(workHistoryItem?.references).find(each => each.id === referenceId)

  if (contractorProfileQuery.error || !contractorProfile || !workHistoryItem || !reference) {
    console.error(`[external.reference.WorkHistoryForReferencePage] Failed to load the Contractor Profile, WorkHistory and Reference: `, { lookupQuery, contractorProfileQuery, referenceId, contractorProfileId, workHistoryId, contractorProfile, workHistoryItem, reference })
    return <ErrorBlockPage name='WorkHistoryForReferencePage' onRefresh={contractorProfileQuery.refetch} />
  }

  triggerEventWorkHistoryReferencesPageViewed({
    referenceType: reference.teamType,
    workHistoryId: workHistoryId,
    referenceId: referenceId,
    referenceEmail: reference.email,
    referencePhone: reference.phoneNumber,
    referenceGivenName: reference.givenName,
    referenceFamilyName: reference.familyName,
  })

  console.debug(`[external.reference.WorkHistoryForReferencePage] Render: `, { lookupQuery, contractorProfileQuery, referenceId, contractorProfileId, workHistoryId, contractorProfile, workHistoryItem, reference })
  return (
    <WeaverIonPage id='WorkHistoryForReferencePage' disableDirectChildStructureChecks={true}>
      <WorkHistoryForReference
        contractorProfile={contractorProfile}
        workHistoryItem={workHistoryItem}
        reference={reference}
      />
    </WeaverIonPage>
  )
}

export default WorkHistoryForReferencePage
