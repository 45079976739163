import React, { ComponentProps } from 'react'
import classnames from 'classnames'
import { ChildChildTask, ChildTask, Task as TaskType } from "./types"
import { IonItem, IonLabel } from '@ionic/react'
import Styles from "./Task.module.scss"
import IonIconTyped from '../../../common/components/IonIconTyped'
import { TaskStatus } from '../../../graphql/generated'
import { getTaskIcon, useActionableTaskFilter } from '../taskUtils'

export const getStatusData = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.Completed:
      return { color: "success", label: "Complete" }
    case TaskStatus.Overdue:
      return { color: "danger", label: "Overdue" }
    case TaskStatus.InProgress:
      return { color: "tertiary", label: "In Progress" }
    case TaskStatus.Disabled:
      return { color: 'light', label: 'Locked' }
    case TaskStatus.NotStarted:
    default:
      return { color: "light", label: "Not started" }
  }
}

type HandleClick = ({ id, parentId }: { id: string, parentId: string | undefined }) => unknown | void

type TaskProps = {
  onAction?: HandleClick,
  task: TaskType | ChildTask | ChildChildTask,
  parentId?: string | undefined,
  refetchTasks?: any,
  ionItemProps?: ComponentProps<typeof IonItem>,
}

const Task: React.FC<TaskProps> = ({ task, parentId, onAction, ionItemProps  }) => {
  const { id, icon, title, status, assignedTeam } = task

  const isActionable = useActionableTaskFilter()

  const actionable = isActionable(task)

  const handleClick = onAction && ((...params: Parameters<typeof onAction>) => {
    if (actionable) return onAction(...params)
  })

  const showDetail = actionable
  const iconName = getTaskIcon(icon, task)

  return <IonItem lines='full' className={`${classnames({ [Styles.taskItem]: true,
    [Styles.completed]: status === TaskStatus.Completed,
    [Styles.notStarted]: status === TaskStatus.NotStarted,
    [Styles.locked]: status === TaskStatus.Disabled,
    [Styles.nextStep]: status === TaskStatus.NextStep,
    [Styles.skipped]: status === TaskStatus.Skipped })}`}
  key={id}
  onClick={() => handleClick && handleClick({ id, parentId })}
  detail={showDetail} {...ionItemProps}>
    <section className={Styles.iconContainer}>
      <IonIconTyped className={`${Styles.taskIcon}`} iconName={iconName} />
    </section>
    <div className={Styles.itemContentContainer}>
      <IonLabel>
        {title}
      </IonLabel>
      <div className={Styles.subtextContainer}>
        {assignedTeam && (
          <div>
            <span>{assignedTeam?.name}</span>
          </div>
        )}
      </div>
    </div>
  </IonItem>
}

export default Task
