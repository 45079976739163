import React from 'react'
import { IonBackButton, IonItem } from '@ionic/react'
import TeamMembers from '../TeamMembers/TeamMembers'
import RangeBadges from './RangeBadges'
import ListWorkHistoryItems from './ListWorkHistoryItems'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { PublicContractorProfileScreenProps, PublicContractorScreenNames, RenderAs } from './PublicContractorProfile'
import { ScreenRenderProps } from '../../../common/hooks/useScreens'
import GlobalHeader, { NavElement } from '../../../common/components/GlobalHeader/GlobalHeader'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import { useWeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { PublicContractorProfileDetailsCard } from './PublicContractorProfileDetailsCard'
import { PublicWorkHistoryTimeline } from './PublicWorkHistoryTimeline'

import Styles from './PublicContractorProfilePage.module.scss'

const ScreenHome: React.FC<PublicContractorProfileScreenProps & ScreenRenderProps<PublicContractorScreenNames>> = (props) => {

  const weaverFlags = useWeaverFlags()

  const isNewContractorProfileEnabled = weaverFlags['MW-2171-new-contractor-profile'].enabled
  const shouldApplyStyle = weaverFlags['tab-nav-bar'].enabled && weaverFlags['MW-2076-change-work-history-modal-on-projects'].enabled

  return <>
    <WeaverIonHeader className={shouldApplyStyle ? GlobalHeaderStyles.globalHeader : undefined}>
      <GlobalHeader
        navElement={props.renderProps.as === RenderAs.Page ? <IonBackButton className={Styles.backButton} /> : NavElement.None}
        pageTitle='Public Profile'
      />
    </WeaverIonHeader>
    <WeaverIonContent className={Styles.displayContractorProfileContainer}>
      { isNewContractorProfileEnabled
        ? <>
          <PublicContractorProfileDetailsCard contractorProfile={props.profile} />
          <section className={Styles.tieredBadgeRangeContainer}>
            <h5 className={Styles.badgeTitle}>Badges</h5>
            {/** TODO: New badges integration -> https://weaver.atlassian.net/browse/MW-2170 **/}
            <RangeBadges screenProps={props} width="90" height="90" />
          </section>
        </>
        : <section className={Styles.companyInfoContainer}>
          <IonItem className={`${Styles.ionItem} ion-no-padding`} lines='none'>
            <h3>{props.profile.companyTradingAs}</h3>
          </IonItem>
          <p className={Styles.contractorTeamType}>Main Contractor</p>
          <p>{formatAddressToSingleLine(props.profile.companyAddress) ?? <em>No Address</em>}</p>
          <RangeBadges screenProps={props} width="90" height="90" />
        </section>
      }

      {isNewContractorProfileEnabled
        ? <section className={Styles.listWorkHistoryContainer}>
          <h3 className={Styles.pastProjectsTitle}>Past projects</h3>
          {!props.profile.workHistory && <p className="ion-padding" >No work history has been added</p>}
          <PublicWorkHistoryTimeline {...props} />
        </section>
        : <section>
          <ListWorkHistoryItems {...props} />
        </section>
      }

      <section className={Styles.teamMemberListContainer}>
        <TeamMembers teamId={props.profile.id} />
      </section>
    </WeaverIonContent>
  </>
}

export default ScreenHome
