import { Maybe } from "../../../../../graphql/generated"
import { isSomething } from '../../../../../common/utils'

export enum BadgeLevel {
  Gold = "Gold",
  Silver = "Silver",
  Bronze = "Bronze",
  None = "None"
}

export const badgeLevelWorkHistoryThreshold: Record<BadgeLevel, number> = {
  [BadgeLevel.None]: 0,
  [BadgeLevel.Bronze]: 1,
  [BadgeLevel.Silver]: 3,
  [BadgeLevel.Gold]: 5,
}
/**
 * This function is how we determine that if a work history is about to be completed should
 * we award the user a badge of one of three levels.
 *
 * If the users work history is not eligible for a badge return Badge.None and use this
 * as a flag to not perform the mutation or route to the award page.
 *
 * @param workHistoryCount
 * @returns BadgeLevel
 */
export const getBadgeToAwardAfterAddWorkHistory = (workHistoryCount: number | undefined) => {
  if (workHistoryCount === 0 || workHistoryCount === undefined) return BadgeLevel.None

  if (workHistoryCount === 1) return BadgeLevel.Bronze

  if (workHistoryCount === 3) return BadgeLevel.Silver

  if (workHistoryCount === 5) return BadgeLevel.Gold

  return BadgeLevel.None
}

/**
 * Calculate by completed work histories which badge a user is
 * working towards. Displayed in the splash screens header
 * and in the work history menu while users are adding work histories to a budget range.
 *
 * If 1 or no work histories the header badge should be bronze
 *
 * If 2 or 3 show silver
 *
 * 4 or more show Gold
 */
export const getInprogressBadgeLevel = (workHistoryCount: number | undefined) => {
  if (workHistoryCount === undefined || workHistoryCount < 1 ) return BadgeLevel.Bronze

  if (workHistoryCount < 4) return BadgeLevel.Silver

  return BadgeLevel.Gold
}

/**
 * This function takes an input an array of unlocked badges and based on that calculates how many padlocks will be displayed on Badges Row.
 * It returns back an array of BadgeLevels.
 *
 * if the unlockBadges is an empty array then no badge has been rewarded.
 * if the unlockBadges array contains gold badge that indicates that you have been rewarded with every possible badge.
 * if the unlockBadges array contains silver badge that indicates that you have rewarded with silver and bronze.
 * if the unlockBadges array contains bronze or has at least one element badge that indicates that you have rewarded with bronze.
 *
 * @param unlockedBadges
 * @returns Array<BadgeLevel | null>>
 */

export const getLockedBadges = (unlockedBadges: Array<Maybe<BadgeLevel | undefined>>) => {
  if (!unlockedBadges.length) {
    return [ BadgeLevel.Bronze, BadgeLevel.Silver, BadgeLevel.Gold ]
  }

  if (unlockedBadges.includes(BadgeLevel.Gold)) {
    return []
  }

  if (unlockedBadges.includes(BadgeLevel.Silver)) {
    return [ BadgeLevel.Gold ]
  }

  if (unlockedBadges.includes(BadgeLevel.Bronze) || unlockedBadges.length === 1) {
    return [ BadgeLevel.Silver, BadgeLevel.Gold ]
  }
}

export const migrateBadgeLevels = (legacyAndNewBadgeLevels: (BadgeLevel | null | undefined)[]): (BadgeLevel | null | undefined)[] => {
  // If I have no badges, do nothing
  if (legacyAndNewBadgeLevels.length === 0) return legacyAndNewBadgeLevels
  // If I have new badge levels, show only those new badge levels (these supereceed the old ones)
  const newBadgeLevels = legacyAndNewBadgeLevels.filter(isSomething)
  if (newBadgeLevels.length > 0) return newBadgeLevels

  // Show the legacy badge as a single bronze
  return [ BadgeLevel.Bronze ]
}
