import { alwaysArray, isSomething } from "../../../common/utils"
import { useGraphQLDataSource } from "../../graphql"
import { ListMyChatRoomsQuery, useListMyChatRoomsQuery } from "../../../graphql/generated"
import { useWeaverFlags } from "../../thirdParty/launchDarkly/useWeaverFlags"
import { DateTime } from "luxon"
import { sortByOriginalOrder } from "../../../common/utils/sorting"

const PACKAGE = 'api.services.chat'

export type TMyChatRoom = NonNullable<NonNullable<NonNullable<ListMyChatRoomsQuery['listMyChatRooms']>[number]>['chatRoom']>
export type IndividualChatRoomConnection = NonNullable<NonNullable<NonNullable<ListMyChatRoomsQuery['listMyChatRooms']>>>[0]

export const useMyChatRoomsQueryKey = [ 'listMyChatRooms' ]

export type TUseMyChatRoomsReturns = TMyChatRoom[] | null | undefined

export const useMyChatRooms = (): TUseMyChatRoomsReturns => {
  const LOCAL_PACKAGE = `${PACKAGE}.useMyChatRooms`

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const queryResult = useListMyChatRoomsQuery(gqlDataSource, {}, { queryKey: [ ...useMyChatRoomsQueryKey, 'list' ] })
  const weaverFlags = useWeaverFlags()

  console.debug(`[${LOCAL_PACKAGE}] React Graph`, { queryResult })

  const getChatRoomLastMessageAt = (chatRoom: TMyChatRoom) => {
    if (chatRoom.messages && chatRoom?.messages?.length > 0){
      return DateTime.fromISO(chatRoom.lastMessageAt)
    }
    // A date in the past is used so the chat rooms without messages are ordered in the bottom.
    // This is necessary since lastMessageAt is set as the chat's creation time for new chat rooms.
    return DateTime.fromISO('1970-01-01T00:00:00.000')
  }

  const sortByLastMessageSent = (chatRoomConnection1: NonNullable<IndividualChatRoomConnection>, chatRoomConnection2: NonNullable<IndividualChatRoomConnection>) => {
    const chatRoomConnection1LastMessageAt = getChatRoomLastMessageAt(chatRoomConnection1.chatRoom)
    const chatRoomConnection2LastMessageAt = getChatRoomLastMessageAt(chatRoomConnection2.chatRoom)
    return chatRoomConnection2LastMessageAt.toMillis() - chatRoomConnection1LastMessageAt.toMillis()
  }

  const myChatRooms = alwaysArray(queryResult.data?.listMyChatRooms)

  const chatRooms = queryResult.isSuccess
    ? alwaysArray(queryResult.data.listMyChatRooms)
      .sort(
        weaverFlags['MW-2155-sort-chat-lists-by-last-message-activity']
          ? sortByLastMessageSent
          : sortByOriginalOrder(myChatRooms),
      )
      .map(each => each.chatRoom)
      .filter(isSomething)
    : queryResult.isLoading
      ? undefined
      : null

  return chatRooms
}
