import React from 'react'
import { IonButton } from '@ionic/react'

import Styles from "./ThumbnailPhoto.module.scss"

type ThumbnailPhotoUnstartedProps = {
  photoSizeSmall?: boolean,
}

const ThumbnailPhotoUnstarted: React.FC<ThumbnailPhotoUnstartedProps> = ({ photoSizeSmall = false }) =>
  <IonButton className={`${photoSizeSmall ? Styles.thumbnailPhotoContainerSmall : Styles.thumbnailPhotoContainer}`} fill="clear" size="small"/>

export default ThumbnailPhotoUnstarted

