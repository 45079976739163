import React from "react"
import { IonIcon, IonText } from "@ionic/react"
import { checkmarkOutline } from "ionicons/icons"
import { TaskStatus } from "../../graphql/generated"
import { ChildTask, Task } from "./checklist/types"
import Styles from "./ProjectIndicator.module.scss"
import { useWeaverFlags } from "../../api/thirdParty/launchDarkly/useWeaverFlags"

type ProjectIndicatorProps = {
  taskListData: Array<Task | ChildTask>,
};

export const ProjectIndicator: React.FC<ProjectIndicatorProps> = ({ taskListData }) => {
  const weaverFlags = useWeaverFlags()
  const totalSteps = taskListData.length

  const isLastStep = (index: number) => totalSteps === index + 1

  return (
    <div className={Styles.indicatorContainer}>
      {taskListData.map(({ status, title }, index) => {
        return (
          <React.Fragment key={title}>
            <div className={Styles.stepContainer}>
              <div className={Styles.stepIconContainer} data-status={status}>
                {status === TaskStatus.Completed
                  ? <IonIcon icon={checkmarkOutline} />
                  : <IonText>
                    <p>{index + 1}</p>
                  </IonText>}
              </div>
              {/* FLAG: MW-2072 */}
              <IonText className={`${Styles.stepName} ${weaverFlags["MW-2072-include-dynamic-font-on-project-tasks-indicator"].enabled ? Styles.dynamicFontSize : undefined}` }>
                <p className={Styles.title} data-status={status}>{title}</p>
              </IonText>
            </div>
            {isLastStep(index) ? null : <div className={Styles.indicatorBar} data-status={status} />}
          </React.Fragment>
        )
      })}
    </div>
  )
}
