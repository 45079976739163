import React from 'react'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import WeaverIonHeader from '../../../common/components/WeaverIonWrappers/WeaverIonHeader'
import WeaverIonPage from '../../../common/components/WeaverIonWrappers/WeaverIonPage'
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'
import GlobalHeader from '../../../common/components/GlobalHeader'
import { useParamsFromPageConfig } from '../../../routesProvider'
import { useListProjectTasksQuery, useGetProjectPostCodeQuery } from '../../../graphql/generated'
import { useGraphQLDataSource } from '../../../api/graphql'
import { IonAccordion, IonAccordionGroup, IonCard, IonCardContent, IonRefresher, IonRefresherContent, RefresherEventDetail } from '@ionic/react'
import Task from './Task'
import ErrorBlockPage from '../../../common/components/ErrorBlock/ErrorBlockPage'
import LoadingSpinnerPage from '../../../common/components/LoadingSpinner/LoadingSpinnerPage'
import { TopLevelTask } from './TopLevelTask'
import Styles from './AllProjectTasksPage.module.scss'
import { ProjectIndicator } from '../ProjectIndicator'
import { pageConfig_TaskActioner, useRouteTo } from '../../../routes'
import { multiSort, sortBy } from '../../../common/sort'

const AllProjectTasksPage: React.FC = () => {
  const { projectId } = useParamsFromPageConfig<{projectId: string}>()
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const listProjectTasksQuery = useListProjectTasksQuery(gqlDataSource, { id: projectId }, {
    refetchOnWindowFocus: false,
    select: (data) => multiSort(data.listProjectTasks, [ sortBy("order"), sortBy("title") ]),
  })
  const getProjectPostCodeQuery = useGetProjectPostCodeQuery(gqlDataSource, { id: projectId }, { refetchOnWindowFocus: false  })
  const routeToTaskActioner = useRouteTo(pageConfig_TaskActioner.path)

  const handleRefetchData = async () => {
    await getProjectPostCodeQuery.refetch()
    await listProjectTasksQuery.refetch()
  }

  function handlePullRefresh(event: CustomEvent<RefresherEventDetail>) {
    setTimeout(() => {
      handleRefetchData()
      event.detail.complete()
    }, 2000)
  }

  if (listProjectTasksQuery.isLoading || getProjectPostCodeQuery.isLoading) {
    return <LoadingSpinnerPage name="AllProjectTasksPage" />
  }

  if (listProjectTasksQuery.error || !listProjectTasksQuery.data || getProjectPostCodeQuery.error || !getProjectPostCodeQuery.data) {
    return <ErrorBlockPage name='AllProjectTasksPage' onRefresh={handleRefetchData} />
  }

  const projectTasks = listProjectTasksQuery.data
  const projectPostCode = getProjectPostCodeQuery.data.getProject.address?.postCode

  return (
    <WeaverIonPage id='AllProjectTasksPage'>
      <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
        <GlobalHeader pageTitle='All Tasks' pageSubtitle={projectPostCode}/>
      </WeaverIonHeader>
      <WeaverIonContent scrollY={false}>
        <IonRefresher className={Styles.ionRefresher}  slot="fixed" onIonRefresh={handlePullRefresh}>
          <IonRefresherContent
            refreshingSpinner={'crescent'}>
          </IonRefresherContent>
        </IonRefresher>
        <IonCard className={Styles.ionCard}>
          <IonCardContent className={`${Styles.ionCardContent} ion-no-padding`}>
            <ProjectIndicator taskListData={projectTasks} />
          </IonCardContent>
        </IonCard>
        <IonAccordionGroup multiple={false} className={Styles.ionAccordionGroup}>
          { projectTasks?.map((task) => {
            const { id, childTasks , title } = task
            const sortedChildren = multiSort(childTasks, [ sortBy("order"), sortBy("title") ])
            return  (
              <React.Fragment key={id}>
                <IonAccordion value={title}>
                  <TopLevelTask task={task}/>
                  <div className='ion-margin-bottom' slot="content">
                    {
                      sortedChildren.map((child) => (
                        <div key={child.id}>
                          <Task task={child} parentId={id} onAction={(actioned) => routeToTaskActioner({ taskId: actioned.id })()}  />
                        </div>
                      ))}
                  </div>
                </IonAccordion>
              </React.Fragment>
            )
          })}
        </IonAccordionGroup>
      </WeaverIonContent>
    </WeaverIonPage>
  )
}

export default AllProjectTasksPage
