import React from 'react'
import SplashScreen from '../../common/components/SplashScreen'
import StorageProvider from '../../api/providers/StorageProvider'
import ReactQueryProvider from '../../api/providers/ReactQueryProvider'
import AuthProvider from '../../api/providers/AuthProvider'
import AblyProvider from '../../api/providers/AblyProvider'
import MyIndividualProvider from '../../api/providers/MyIndividualProvider'
import BootstrappedProvider from '../../api/providers/BootstrappedProvider'
import PushNotificationsProvider from '../../api/providers/PushNotificationsProvider/PushNotificationsProvider'
import SplashScreenHidden from '../../common/components/SplashScreen/SplashScreenHidden'
import { InviteCapture } from '../../common/components/InviteClaimer'
import MyWeaverConsoleProvider from '../../api/providers/MyWeaverConsoleProvider'
import ContactSupportProvider from '../../api/providers/ContactSupportProvider'
import SentryProvider from '../../api/providers/SentryProvider/SentryProvider'
import AdBlockProvider from '../../api/providers/AdBlockerProvider'

const Bootstrapper: React.FC = ({ children }) =>
  <SplashScreen>
    <SentryProvider>
      <AdBlockProvider>
        <StorageProvider>
          <InviteCapture />
          <ReactQueryProvider>
            <AuthProvider>
              <AblyProvider>
                <MyWeaverConsoleProvider>
                  <MyIndividualProvider>
                    <PushNotificationsProvider>
                      <BootstrappedProvider>
                        <ContactSupportProvider>
                          <SplashScreenHidden>
                            {children}
                          </SplashScreenHidden>
                        </ContactSupportProvider>
                      </BootstrappedProvider>
                    </PushNotificationsProvider>
                  </MyIndividualProvider>
                </MyWeaverConsoleProvider>
              </AblyProvider>
            </AuthProvider>
          </ReactQueryProvider>
        </StorageProvider>
      </AdBlockProvider>
    </SentryProvider>
  </SplashScreen>
export default Bootstrapper
