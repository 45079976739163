import React, { useEffect } from "react"
import { ScreenComponentProps } from "./TaskActionerPage"
import { useGetChatRoomForProjectTeamsQuery, GetActionableTaskQuery, useShowProjectQuery, useGetTeamQuery } from "../../../graphql/generated"
import { pageConfig_ChatRoom, useRouteTo } from "../../../routes"
import { useMyIndividualActiveTeam } from "../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { useChatRoomFunctions } from "../../../api/services/chat/useChatRoomFunctions"
import { getChatName } from "../../chats/chatRoom/utils"
import { useMarkTaskCompleteMutation } from "./commonActions"
import GlobalHeader from "../../../common/components/GlobalHeader"
import WeaverIonHeader from "../../../common/components/WeaverIonWrappers/WeaverIonHeader"
import GlobalHeaderStyles from '../../../common/components/GlobalHeader/GlobalHeader.module.scss'

import { useAsyncQuery } from "../../../common/hooks/query"
import { useMutation } from '@tanstack/react-query'
import ErrorBlock from "../../../common/components/ErrorBlock"
import WeaverIonContent from "../../../common/components/WeaverIonWrappers/WeaverIonContent"
import LoadingSpinner from "../../../common/components/LoadingSpinner"
import { Duration } from "luxon"

/** staletime for queryable dependent project & team data that might already be in the app */
const OPEN_MEMBER_CHATROOM_STALETIME = Duration.fromObject({ minutes: 5 }).as('milliseconds')

/** Extract teamIds from OpenMemberChatroom payload */
const getTeamIdsFromOpenMemberChatroomTask = (task: GetActionableTaskQuery["getTask"]) => {
  const taskIds = task.actionablePayload?.split("|")
  if (!taskIds || taskIds.length < 1) throw new Error(`[ScreenOpenMemberChatroom] Failed to retrieve memberIds from Task action payload. ${JSON.stringify(task)}`)
  return taskIds
}

/** Main functionality for the task action, extracted to a useMutation, so that the component can track state, errors, and other meta */
const useCompleteOpenMemberChatroomMutation = () => {
  const asyncQuery = useAsyncQuery({ api: "core" })
  const activeTeam = useMyIndividualActiveTeam()
  if (!activeTeam) throw new Error("Missing team")

  const { createChatRoom } = useChatRoomFunctions()

  return useMutation(
    [ 'completeOpenMemberChatroomMutation' ],
    async ({ task }: { task: GetActionableTaskQuery["getTask"]} ) => {

      const teamIdsfromTask = getTeamIdsFromOpenMemberChatroomTask(task)

      if (!activeTeam) throw new Error("[ScreenOpenMemberChatroom] Missing team")
      if (Number(teamIdsfromTask?.length) < 1) throw new Error(`[ScreenOpenMemberChatroom] Failed to retrieve memberIds from Task action payload. ${JSON.stringify(task)}`)

      // Combine the teamIds from the task and the currentTeamId & Sort to ensure a consistent order i.e other tasks with the same members
      // The query should handle this, but it keeps the output + network calls predictable
      const teamIdsForChatroom = teamIdsfromTask.concat([ activeTeam.id ]).sort()

      console.debug("[completeOpenMemberChatroomMutation]", "getting project info")
      const { getProject: project } = await asyncQuery(useShowProjectQuery, { id: task.projectId }, { staleTime: OPEN_MEMBER_CHATROOM_STALETIME })

      // do not allow getChatRoomForProjectTeams to go stale, as it's very imporant we don't try and create a duplicate chatroom!
      console.debug("[completeOpenMemberChatroomMutation]", "getting chatroom info")
      const { getChatRoomForProjectTeams: existingChatroom } = await asyncQuery(useGetChatRoomForProjectTeamsQuery, { teamIds: teamIdsForChatroom, projectId: project.id })

      const existingChatRoomId = existingChatroom?.id
      if (existingChatRoomId) {
        console.debug("[completeOpenMemberChatroomMutation]", "Chatroom found!", existingChatRoomId)
        return existingChatRoomId
      }

      console.debug("[completeOpenMemberChatroomMutation]", "looking up team info for", teamIdsForChatroom)
      const teams = await Promise.all(teamIdsForChatroom.map(async (teamId) => (await asyncQuery(useGetTeamQuery, { teamId }, { staleTime: OPEN_MEMBER_CHATROOM_STALETIME })).getTeam))

      const result = await createChatRoom({
        name: getChatName(teams, project?.title),
        teamIds: teamIdsForChatroom,
        projectId: task?.projectId,
      })

      if (!result.id) throw new Error("Failed to create chatroom")

      return result.id
    })
}

const ScreenOpenMemberChatroom: React.FC<ScreenComponentProps> = ({ getActionableTaskQuery }) => {
  const routeToChatroom = useRouteTo(pageConfig_ChatRoom.path)
  const markTaskComplete = useMarkTaskCompleteMutation(getActionableTaskQuery, { routeToMyProjectOnSuccess: false })

  const completeOpenMemberChatroomMutation = useCompleteOpenMemberChatroomMutation()

  /** Trigger completeOpenMemberChatroomMutation when getActionableTaskQuery completed successfully  */
  useEffect(() => {
    async function openMemberChatroom() {
      if (!getActionableTaskQuery.isSuccess || !getActionableTaskQuery.data.getTask) return
      if (completeOpenMemberChatroomMutation.isLoading) return
      if (completeOpenMemberChatroomMutation.isError) return
      const chatRoomId = await completeOpenMemberChatroomMutation.mutateAsync({ task: getActionableTaskQuery.data.getTask })

      markTaskComplete()
      routeToChatroom({ chatRoomId })()
    }

    openMemberChatroom()
  }, [ getActionableTaskQuery.isSuccess ])

  const task = getActionableTaskQuery.data?.getTask

  return <>
    <WeaverIonHeader className={GlobalHeaderStyles.globalHeader}>
      <GlobalHeader pageTitle={task?.actionTitle ?? task?.title}/>
    </WeaverIonHeader>
    <WeaverIonContent>
      {!task || getActionableTaskQuery.isLoading || completeOpenMemberChatroomMutation.isLoading
        ? <LoadingSpinner name="ScreenOpenMemberChatroom" />
        : completeOpenMemberChatroomMutation.isError
          ? <ErrorBlock name={"ScreenOpenMemberChatroom"} />
          : null}
    </WeaverIonContent>

  </>
}

export default ScreenOpenMemberChatroom
