import React from 'react'
import { IonBadge, IonButton, IonIcon, IonItem, IonRouterLink } from '@ionic/react'
import WeaverIonContent from '../../../common/components/WeaverIonWrappers/WeaverIonContent'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import { alwaysArray, uniqueFilter } from '../../../common/utils'
import { formatAddressToSingleLine } from '../../../common/utils/addresses'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import Styles from './WorkHistoryForReferencePage.module.scss'
import { DisplayUploadedFileAsPhoto } from '../../profile/ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import { moneyToText } from '../../../common/utils/currency'
import { GetContractorProfileQuery, UploadedFileStatus } from '../../../graphql/generated'
import { useWeaverFlags, WeaverFlags } from '../../../api/thirdParty/launchDarkly/useWeaverFlags'
import { useAuthContext } from '../../../api/providers/AuthProvider'
import { useAnalyticsEvent } from '../../../api/providers/SegmentProvider/hooks'
import { replaceAndEncode } from './utils'

type ContractorProfile = NonNullable<GetContractorProfileQuery['getContractorProfile']>
type ContractorProfileWorkHistoryItem = NonNullable<ContractorProfile['workHistory']>[number]
type ContractorProfileWorkHistoryItemReference = NonNullable<ContractorProfileWorkHistoryItem['references']>[number]

export type WorkHistoryForReferenceProps = {
  contractorProfile: ContractorProfile,
  workHistoryItem: ContractorProfileWorkHistoryItem,
  reference: Pick<ContractorProfileWorkHistoryItemReference, 'id' | 'givenName' | 'familyName'>,
}

const WorkHistoryForReference: React.FC<WorkHistoryForReferenceProps> = ({ contractorProfile, workHistoryItem, reference }) => {
  const auth = useAuthContext()

  const triggerEventWorkHistoryReferencesAccepted = useAnalyticsEvent('WorkHistory_References_Attempted_To_Give_Reference')
  const triggerEventWorkHistoryReferencesRejected = useAnalyticsEvent('WorkHistory_References_Declined_To_Give_Reference')

  const weaverFlags = useWeaverFlags()
  const weaverFlagsReferences = weaverFlags['contractor-profile-work-history-references']
  console.debug(`[WorkHistoryForReference] Render: `, { contractorProfile, workHistoryItem, reference, weaverFlags })

  const referenceName =
    !!reference.givenName && !!reference.familyName
      ? `${reference.givenName} ${reference.familyName}`
      : reference.givenName
        ? reference.givenName
        : reference.familyName

  type EnabledReferences = WeaverFlags['contractor-profile-work-history-references'] & { enabled: true }
  const onClickBuilder = (buttonConfig: keyof EnabledReferences['buttons']) => () => {
    if (!weaverFlagsReferences.enabled) return
    const config = weaverFlagsReferences.buttons[buttonConfig]

    if (config.link) {
      const parsedLink = replaceAndEncode(config.link, {
        'USER_EMAIL': auth.userData?.email,
        'REFERENCE_ID': reference.id,
        'BUDGET_WITH_PENNIES': moneyToText(workHistoryItem.constructionValue, { withPennies: true }),
        'BUDGET': moneyToText(workHistoryItem.constructionValue, { withPennies: false }),
        'REFEREE_NAME': referenceName,
        'CONTRACTOR_COMPANY': contractorProfile.companyTradingAs,
        'POSTCODE': workHistoryItem.projectAddress?.postCode,
      })

      window.location.href = parsedLink
      triggerEventWorkHistoryReferencesAccepted({
        referenceName: referenceName,
      })
      return
    }

    if (config.email) {
      window.location.href = `mailto:${config.email}`
      triggerEventWorkHistoryReferencesRejected({
        referenceName: referenceName,

      })
      return
    }
  }

  return <>
    <WeaverIonContent>
      <div className='ion-padding'>
        <p>{!referenceName ? 'You' : `${referenceName} you`} have been asked to be {contractorProfile.companyTradingAs} reference for the work you did on this project:</p>

        <h3>{formatAddressToSingleLine(workHistoryItem.projectAddress) ?? 'Work History'}</h3>

        <IonItem className={Styles.costOfWorkContainer}>
          <IonIcon slot='start' icon={allIonIcons.cashOutline} />
          <span>
            <p>Cost of Work</p>
            <p>{moneyToText(workHistoryItem.constructionValue, { withPennies: true })}</p>
          </span>
        </IonItem>

        <IonItem className={Styles.typeOfWorkContainer}>
          <IonIcon icon={allIonIcons.hammerOutline} slot="start" />
          <span>
            <p>Type</p>
            {alwaysArray(workHistoryItem.projectTypes).map(projectType => (
              <IonBadge key={projectType}>
                {projectTypeLabels[projectType]}
              </IonBadge>
            ))}
          </span>
        </IonItem>

        <h3>Reference</h3>
        {workHistoryItem.references !== undefined
          ? alwaysArray(workHistoryItem.references)
            .map(reference => reference.teamType)
            .filter(uniqueFilter)
            .map(teamType => <p key={teamType}>{teamType}</p>)
          : <p>No references have been uploaded</p>
        }

        <h3>Photos</h3>
        <div className={Styles.photoContainer}>
          {workHistoryItem?.photos
            ? alwaysArray(workHistoryItem.photos).filter(photo => photo.status !== UploadedFileStatus.Archived)
              .map(photo => (
                <div key={photo.id} className={Styles.photoItemContainer}>
                  <DisplayUploadedFileAsPhoto uploadedFileId={photo.id} shouldDisplayDeleteButton={false} />
                </div>
              ))
            : <p>No photos have been uploaded</p>
          }
        </div>

        {weaverFlagsReferences.enabled &&
        <div className={Styles.actionButtonContainer}>
          <IonButton color='primary' expand='block' onClick={onClickBuilder('yes')}>
            {weaverFlagsReferences.buttons.yes.text}
          </IonButton>
          <IonRouterLink className={Styles.declineButton}  onClick={onClickBuilder('no')}>
            {weaverFlagsReferences.buttons.no.text}
          </IonRouterLink>
        </div>
        }
      </div>
    </WeaverIonContent>
  </>
}

export default WorkHistoryForReference
