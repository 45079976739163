import React, { useEffect } from "react"
import { IonAlert } from "@ionic/react"
import { pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu, useRouteTo } from "../../../../../../routes"
import { useGraphQLDataSource } from "../../../../../../api/graphql"
import { BudgetRange, useAwardContractorProfileBudgetRangeBadgeMutation } from "../../../../../../graphql/generated"
import { useAnalyticsEvent, useCallAnalyticsGroup } from "../../../../../../api/providers/SegmentProvider/hooks"
import  AnimatedBudgetRangeBadgePage from "../../../ContractorBudgetReferences/AnimatedBudgetRangeBadgePage"
import { useAwardTieredBadge, useHasContractorProfileBudgetRangeBadge, useShouldAwardContractorProfileBudgetRangeBadge } from "./hooks"
import { useMyContractorProfile } from "../../datasource"
import ResponsiveContentWrapper from "../../../../../../common/components/ResponsiveContentWrapper/ResponsiveContentWrapper"
import { useParamsFromPageConfig } from "../../../../../../routesProvider"
import WeaverIonPage from "../../../../../../common/components/WeaverIonWrappers/WeaverIonPage"
import WeaverIonContent from "../../../../../../common/components/WeaverIonWrappers/WeaverIonContent"
import { BadgeLevel, badgeLevelWorkHistoryThreshold } from "../BadgeUtils"
import { useWeaverFlags } from "../../../../../../api/thirdParty/launchDarkly/useWeaverFlags"
import { alwaysArray } from "../../../../../../common/utils"
import { budgetRangeLabels } from "../../budgetRanges.i18n"

const calculatedDescription = (award: BadgeLevel): string  => {

  if (award === BadgeLevel.Bronze) {
    return `You have added 1 past project in the`
  }
  return `You have added ${award === BadgeLevel.Silver ? '3' : '5' } past projects in the`
}

const ContractorProfileBudgetRangeAwardBadgePage: React.FC = () => {
  const { budgetRange, workHistoryId } = useParamsFromPageConfig<{ budgetRange: BudgetRange, workHistoryId: string, awardedBadge: BadgeLevel }>()

  /**
   * useAwardTieredBadge will check eligibility and return BadgeLevel.None if the user already has the badge or
   * does not have the correct amount of completed work histories to receive a badge.
   */
  const awardedBadge = useAwardTieredBadge(budgetRange, workHistoryId)
  const shouldRedirectUser = awardedBadge === BadgeLevel.None

  const weaverFlags = useWeaverFlags()
  const multipleWorkHistoryFlagEnabled = weaverFlags["MW-2096-multiple-work-histories"].enabled
  const goToUnlockContractorBudgetRangeMenu = useRouteTo(pageConfig_Profile_ContractorLeadPreferences_ContractorBudgetRanges_UnlockContractorBudgetRangeMenu.path)

  // If I've already been awarded the badge, skip this page
  const hasContractorProfileBudgetRangeBadge = multipleWorkHistoryFlagEnabled ? false : useHasContractorProfileBudgetRangeBadge(budgetRange)

  useEffect(() => {
    if (hasContractorProfileBudgetRangeBadge) {
      goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    }
  }, [ hasContractorProfileBudgetRangeBadge, multipleWorkHistoryFlagEnabled ])

  useEffect(() => {
    if (multipleWorkHistoryFlagEnabled && shouldRedirectUser) {
      goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
    }
  }, [ shouldRedirectUser, multipleWorkHistoryFlagEnabled ])

  // If I shouldn't award the badge, show an error
  const shouldAwardContractorProfileBudgetRangeBadge = useShouldAwardContractorProfileBudgetRangeBadge(budgetRange)

  // Build the click function
  const profile = useMyContractorProfile()
  const isNotReadyToRenderView = profile.getContractorProfileQuery.isLoading

  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const awardContractorProfileBudgetRangeBadgeMutation = useAwardContractorProfileBudgetRangeBadgeMutation(gqlDataSource)

  const triggerEvent_WorkHistory_References_Badge_Awarded = useAnalyticsEvent('WorkHistory_References_Badge_Awarded')
  const callAnalyticsGroup = useCallAnalyticsGroup()

  const onClick = async () => {
    //Protect against user clicking back on browser and Ionic caching disrupting the useEffect guards above
    if (multipleWorkHistoryFlagEnabled && shouldRedirectUser) return goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()

    await awardContractorProfileBudgetRangeBadgeMutation.mutateAsync({ budgetRange, isMultiBadge: multipleWorkHistoryFlagEnabled, awardedBadge: awardedBadge, workHistoryId })
    await profile.getContractorProfileQuery.refetch()

    await triggerEvent_WorkHistory_References_Badge_Awarded({
      budgetRange: budgetRange,
      badgeLevel: multipleWorkHistoryFlagEnabled ? awardedBadge : undefined,
      badgeLevelWorkHistoryThreshold: multipleWorkHistoryFlagEnabled ? badgeLevelWorkHistoryThreshold[awardedBadge] : undefined,
    })

    // We should tell Segment that this group has a new Badge by adding it to the list it already has
    if (weaverFlags['MW-2143-add-segment-team-property-budgetRangeBadges']) {
      const contractorTeamId = profile.getContractorProfileQuery.data?.getContractorProfile?.id
      const contractorBudgetRanges: BudgetRange[] = [
        ...alwaysArray(profile.getContractorProfileQuery.data?.getContractorProfile?.budgetRangeBadges).map(badge => badge.budgetRange),
        budgetRange,
      ]

      await callAnalyticsGroup(contractorTeamId, {
        budgetRangeBadges: contractorBudgetRanges,
      })
    }

    goToUnlockContractorBudgetRangeMenu({ budgetRange, workHistoryId })()
  }

  const description = calculatedDescription(awardedBadge)

  // If I've not already been awarded the badge, show the animation and release the endophines!
  return !multipleWorkHistoryFlagEnabled
    ? isNotReadyToRenderView || hasContractorProfileBudgetRangeBadge
      ?  (
        <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.hidden`}>
          <WeaverIonContent>
            {null}
          </WeaverIonContent>
        </WeaverIonPage>
      )
      : !shouldAwardContractorProfileBudgetRangeBadge
        ? (
          <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.invalid`}>
            <WeaverIonContent>
              <IonAlert
                isOpen={true}
                header={'Oops! Cannot award badge!'}
              />
            </WeaverIonContent>
          </WeaverIonPage>
        )
        : (
          <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.show`}>
            <WeaverIonContent>
              <ResponsiveContentWrapper>
                <AnimatedBudgetRangeBadgePage
                  title="You&apos;ve unlocked"
                  subtitle={`${budgetRangeLabels[budgetRange]} LEADS`}
                  description='You have added a reference project in the'
                  actionButtonProps={{
                    children: <>Continue</>,
                    expand: 'block',
                    size: 'large',
                  }}
                  budgetRange={budgetRange}
                  onClick={onClick}
                />
              </ResponsiveContentWrapper>
            </WeaverIonContent>
          </WeaverIonPage>
        )
    : isNotReadyToRenderView
      ?  <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.hidden`}>
        <WeaverIonContent>
          {null}
        </WeaverIonContent>
      </WeaverIonPage>
      : (
        <WeaverIonPage id={`ContractorProfileBudgetRangeAwardBadgePage.show`}>
          <WeaverIonContent>
            <ResponsiveContentWrapper>
              <AnimatedBudgetRangeBadgePage
                title="You&apos;ve unlocked"
                subtitle={`${budgetRangeLabels[budgetRange]} ${awardedBadge}`}
                description={description}
                badgeLevel={awardedBadge}
                actionButtonProps={{
                  children: <>Continue</>,
                  expand: 'block',
                  size: 'large',
                }}
                budgetRange={budgetRange}
                onClick={onClick}
              />
            </ResponsiveContentWrapper>
          </WeaverIonContent>
        </WeaverIonPage>
      )
}

export default ContractorProfileBudgetRangeAwardBadgePage
