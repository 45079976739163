import React from 'react'
import { IonAvatar, IonBadge, IonIcon, IonItem, IonLabel, IonSkeletonText, IonText } from '@ionic/react'
import { allIonIcons } from '../../../common/components/IonIconTyped/icons'
import { GetContractorProfileWorkHistory, getReferenceCount, getWorkHistoryStatus } from '../ContractorLeadPreferences/ContractorBudgetRanges/datasource'
import { formatAddressToStreetPostcodeCity } from '../../../common/utils/addresses'
import { DateTime } from 'luxon'
import { projectTypeLabels } from '../../onboarding/team/onboarding.i18n'
import { DisplayUploadedFileAsPhoto } from '../ContractorLeadPreferences/ContractorBudgetRanges/UnlockContractorBudgetRange/WorkHistoryPhotos/DisplayUploadedFileAsPhoto'
import { WorkHistoryStatus, workHistoryStatusLabel } from './WorkHistoryItem.i18n'
import classNames from 'classnames'
import { moneyAsTextBySignificantFigures } from '../../../common/utils/budgetTransformation'

import Styles from './WorkHistoryItem.module.scss'

type WorkHistoryItemProps = {
  workHistory: GetContractorProfileWorkHistory,
  primaryClickHandler?: () => void,
  destructiveClickHandler?: () => void,
  backgroundClassName?: string,
  showOnlyVerifiedStatus?: boolean,
}

export const WorkHistoryItem: React.FC<WorkHistoryItemProps> = ({ workHistory, primaryClickHandler, destructiveClickHandler, backgroundClassName, showOnlyVerifiedStatus }) => {

  const workHistoryStatus = getWorkHistoryStatus(workHistory)
  const hasConstructionDates = workHistory.constructionStartedDate && workHistory.constructionCompletedDate

  return (
    <IonItem lines='none' className={classNames({ [Styles.workHistoryItem]: true, [Styles[backgroundClassName ? backgroundClassName : '']]: !!backgroundClassName })}>
      <IonAvatar slot="start" className={[ Styles.avatar, "ion-margin-top" ].join(" ")}>
        <IonIcon color="medium" icon={allIonIcons.home} />
      </IonAvatar>
      {destructiveClickHandler && <IonLabel slot='end' className={Styles.trashIconContainer} onClick={destructiveClickHandler}>
        <IonIcon icon={allIonIcons.trashBin} className={Styles.trashIcon} />
      </IonLabel>}
      <IonLabel className={Styles.ionItemBody} onClick={primaryClickHandler}>
        <section className={Styles.addressTextContainer}>
          <h5 className={Styles.addressText}>{formatAddressToStreetPostcodeCity(workHistory.projectAddress)}</h5>
        </section>

        <section className={[ Styles.constructionDatesAndValueContainer, Styles.workHistoryItemSection ].join(' ')}>
          {hasConstructionDates && <>
            <p className={Styles.ionItemSubtitle}>{ workHistory?.constructionStartedDate && DateTime.fromISO(workHistory?.constructionStartedDate).toFormat('MMM yyyy')} - {workHistory?.constructionCompletedDate && DateTime.fromISO(workHistory?.constructionCompletedDate).toFormat('MMM yyyy')}</p>
            <p className={Styles.dotDivider}>•</p>
          </>}
          <p className={Styles.projectCost}>{workHistory.constructionValue ? moneyAsTextBySignificantFigures(workHistory.constructionValue): ""}</p>
        </section>

        <section className={Styles.projectTypeListContainer}>
          <p className={Styles.projectTypeList}>{workHistory.projectTypes?.map(projectType => projectTypeLabels[projectType]).join(', ')}</p>
        </section>

        <section className={Styles.validReferenceContainer}>
          <IonText color="primary"><p className={Styles.verifiedReferenceCount}>{getReferenceCount(workHistory)}</p></IonText>
        </section>

        <section className={Styles.photosContainer}>
          {workHistory.photos?.slice(0,6).map((photo) => {
            return <DisplayUploadedFileAsPhoto photoSizeSmall={true} allowFullscreenImage={false} key={photo.id} uploadedFileId={photo.id} />
          })}
        </section>

        {/** Only show status on unlock budget range screen, on Profiles only show when verified */}
        {showOnlyVerifiedStatus
          ? workHistoryStatus === WorkHistoryStatus.VERIFIED
            ?<IonBadge className={classNames({
              [Styles.workHistoryStatus]: true,
              [Styles.verified]: workHistoryStatus === WorkHistoryStatus.VERIFIED,
            })}>{workHistoryStatusLabel[workHistoryStatus]}</IonBadge>
            :null
          : <IonBadge className={classNames({
            [Styles.workHistoryStatus]: true,
            [Styles.verified]: workHistoryStatus === WorkHistoryStatus.VERIFIED,
            [Styles.waiting]: workHistoryStatus === WorkHistoryStatus.WAITING,
            [Styles.inProgress]: workHistoryStatus === WorkHistoryStatus.IN_PROGRESS,
          })}>{workHistoryStatusLabel[workHistoryStatus]}</IonBadge>
        }
      </IonLabel>
    </IonItem>
  )
}

export const SkeletonWorkHistoryItem = () => {
  return <IonItem lines='none'  className={Styles.workHistoryItem}>
    <IonAvatar slot="start" className={[ Styles.avatar, "ion-margin-top" ].join(" ")}>
      <IonIcon color="medium" icon={allIonIcons.home} />
    </IonAvatar>
    <IonLabel className={Styles.ionItemBody}>
      <h5><IonSkeletonText animated={true} ></IonSkeletonText></h5>

      <section className={[ Styles.constructionDatesAndValueContainer, Styles.workHistoryItemSection ].join(' ')}>
        <p className={Styles.ionItemSubtitle}><IonSkeletonText animated={true} /></p>
        <p className={Styles.dotDivider}><IonSkeletonText animated={true} /></p>
        <p><IonSkeletonText animated={true} /></p>
      </section>

      <section className={Styles.projectTypeListContainer}>
        <p className={Styles.projectTypeList}><IonSkeletonText animated={true} /></p>
      </section>

      <section className={Styles.validReferenceContainer}>
        <IonText color="primary"><p className={Styles.verifiedReferenceCount}><IonSkeletonText animated={true} /></p></IonText>
      </section>
    </IonLabel>
  </IonItem>
}
