import React from "react"
import { useQueryClient } from '@tanstack/react-query'
import { useGraphQLDataSource } from "../../../../api/graphql"
import { useMyIndividualActiveTeam } from "../../../../api/providers/MyIndividualProvider/MyIndividualProvider"
import { alwaysArray } from "../../../../common/utils"
import { BudgetRange, GetContractorProfileQuery, ProgressionStatus, Team, TeamType, useGetContractorProfileQuery } from "../../../../graphql/generated"
import { WorkHistoryStatus } from "../../PublicContractorProfile/WorkHistoryItem.i18n"
import { budgetRangesConfigs } from "./budgetRanges"
import { DateTime } from "luxon"

interface WorkHistoryWithCompletedConstructionDate extends GetContractorProfileWorkHistory {
  constructionCompletedDate: string,
}

type WorkHistoryTimeline = Record<string, WorkHistoryWithCompletedConstructionDate[]>

export const useMyContractorProfile = () => {
  const myTeam = useMyIndividualActiveTeam()
  return useContractorProfile(myTeam)
}

const useContractorProfile = (team?: Pick<Team, 'id' | 'type'>) => {
  const isTeamOfTypeContractor = team?.type === TeamType.Contractor
  const hasTeamId = !!team?.id

  const thirtyMinutesInMilliseconds = 30 * 60 * 1000
  const gqlDataSource = useGraphQLDataSource({ api: 'core' })
  const getContractorProfileQuery = useGetContractorProfileQuery(gqlDataSource, { contractorTeamId: team?.id ?? '' }, {
    enabled: isTeamOfTypeContractor && hasTeamId,
    staleTime: thirtyMinutesInMilliseconds,
  })

  return {
    isTeamOfTypeContractor,
    getContractorProfileQuery,
  }
}

export type GetContractorProfileWorkHistory = NonNullable<NonNullable<GetContractorProfileQuery['getContractorProfile']>['workHistory']>[number]

export const useInvalidateMyContractorProfile = () => {
  const myTeam = useMyIndividualActiveTeam()
  const isMyTeamOfTypeContractor = myTeam?.type === TeamType.Contractor

  const queryClient = useQueryClient()
  return () => {
    if (isMyTeamOfTypeContractor) {
      return queryClient.invalidateQueries([ 'getContractorProfile', { contractorTeamId: myTeam.id } ])
    } else {
      return queryClient.invalidateQueries([ 'getContractorProfile' ])
    }
  }
}

// Helpers
const doesWorkHistoryMatchBudgetRange = (budgetRange: BudgetRange) => (workHistory: GetContractorProfileWorkHistory) => {
  const { fromInclusive, toExclusive } = budgetRangesConfigs[budgetRange]
  const { amountInPence } = workHistory.constructionValue ?? { amountInPence: 0 }
  return amountInPence >= fromInclusive && amountInPence < toExclusive
}

export const getFirstWorkHistoryByBudgetRange = (useMyContractorProfileResponse: ReturnType<typeof useContractorProfile>, budgetRange: BudgetRange): GetContractorProfileWorkHistory | undefined => {
  if (!useMyContractorProfileResponse.isTeamOfTypeContractor) return undefined

  const contractorWorkHistorys = alwaysArray(useMyContractorProfileResponse.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const workHistory = contractorWorkHistorys.find(doesWorkHistoryMatchBudgetRange(budgetRange))

  console.log('[ContractorBudgetRanges.datasource.getFirstWorkHistoryByBudgetRange]', { contractorWorkHistorys, workHistory, budgetRange })
  return workHistory
}

export const getAllNonArchivedWorkHistoriesByBudgetRange = (useMyContractorProfileResponse: ReturnType<typeof useContractorProfile>, budgetRange: BudgetRange): GetContractorProfileWorkHistory[] | undefined | [] => {
  if (!useMyContractorProfileResponse.isTeamOfTypeContractor) return undefined

  const contractorWorkHistorys = alwaysArray(useMyContractorProfileResponse.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const workHistory = contractorWorkHistorys.filter(doesWorkHistoryMatchBudgetRange(budgetRange)).filter(workHistoryItem => !workHistoryItem.isArchived)

  console.debug('[ContractorBudgetRanges.datasource.getAllNonArchivedWorkHistoriesByBudgetRange]', { contractorWorkHistorys, workHistory, budgetRange })
  return workHistory
}

export const getWorkHistoryById = (useMyContractorProfileResponse: ReturnType<typeof useContractorProfile>, workHistoryId: string): GetContractorProfileWorkHistory | undefined => {
  if (!useMyContractorProfileResponse.isTeamOfTypeContractor) return undefined

  const contractorWorkHistorys = alwaysArray(useMyContractorProfileResponse.getContractorProfileQuery.data?.getContractorProfile?.workHistory)
  const workHistory = contractorWorkHistorys.find(each => each.id === workHistoryId)

  console.log('[ContractorBudgetRanges.datasource.getWorkHistoryById]', { contractorWorkHistorys, workHistory, workHistoryId })
  return workHistory
}

export const getProgressionStatusForWorkHistoryItem = (workHistoryItem: GetContractorProfileWorkHistory): ProgressionStatus => {
  const workHistoryHasWaitingOnWeaverReferences = alwaysArray(workHistoryItem.references)
    .filter(reference => reference.status === ProgressionStatus.WaitingOnWeaver).length > 0
  const workHistoryHasCompletedReferences = alwaysArray(workHistoryItem.references)
    .filter(reference => reference.status === ProgressionStatus.Completed).length > 0

  const workHistoryHasAnyPhotos = alwaysArray(workHistoryItem.photos).length > 0

  // Is the WorkHistory item complete?
  if (workHistoryHasCompletedReferences && workHistoryHasAnyPhotos) return ProgressionStatus.Completed

  // Is the WorkHistory item Waiting on Weaver?
  if (workHistoryHasWaitingOnWeaverReferences && workHistoryHasAnyPhotos) return ProgressionStatus.WaitingOnWeaver

  // As the WorkHistory item exists, in all other cases, we are Waiting on User
  return ProgressionStatus.WaitingOnUser
}

const getProgressionStatusForWorkHistory = (workHistory?: GetContractorProfileWorkHistory[] | null): ProgressionStatus =>
  alwaysArray(workHistory).reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

const reduceWorkHistoryToSingleProgressionStatus = (resultingStatus: ProgressionStatus, workHistoryItem: GetContractorProfileWorkHistory): ProgressionStatus => {
  // If I'm already Completed, fast-forward
  if (resultingStatus === ProgressionStatus.Completed) return resultingStatus

  // Upgrade the resulting status where the current item status is greater
  const itemStatus = getProgressionStatusForWorkHistoryItem(workHistoryItem)
  switch (resultingStatus) {
    case ProgressionStatus.WaitingOnWeaver:
      return [ ProgressionStatus.Completed ].includes(itemStatus) ? itemStatus : resultingStatus
    case ProgressionStatus.WaitingOnUser:
      return [ ProgressionStatus.Completed, ProgressionStatus.WaitingOnWeaver ].includes(itemStatus) ? itemStatus : resultingStatus
    case ProgressionStatus.NotStarted:
      return [ ProgressionStatus.Completed, ProgressionStatus.WaitingOnWeaver, ProgressionStatus.WaitingOnUser ].includes(itemStatus) ? itemStatus : resultingStatus
    default: return itemStatus
  }
}

export const convertToLockedBudgetRangeStatuses = (workHistory?: GetContractorProfileWorkHistory[] | null): Record<BudgetRange, ProgressionStatus> => {
  const contractorWorkHistorys = alwaysArray(workHistory)

  const getProgressionStatusForBudgetRange = (budgetRange: BudgetRange): ProgressionStatus =>
    contractorWorkHistorys
      .filter(doesWorkHistoryMatchBudgetRange(budgetRange))
      .reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

  const statuses: Record<BudgetRange, ProgressionStatus> = {
    [BudgetRange.F30T100]: getProgressionStatusForBudgetRange(BudgetRange.F30T100),
    [BudgetRange.F100T500]: getProgressionStatusForBudgetRange(BudgetRange.F100T500),
    [BudgetRange.F500T1000]: getProgressionStatusForBudgetRange(BudgetRange.F500T1000),
    [BudgetRange.F1000T3000]: getProgressionStatusForBudgetRange(BudgetRange.F1000T3000),
  }

  console.log('[ContractorBudgetRanges.datasource.convertToLockedBudgetRangeStatuses]', { contractorWorkHistorys, statuses })

  return statuses
}

const unlockedBudgetRangeAcceptableStatuses = [ ProgressionStatus.WaitingOnWeaver, ProgressionStatus.Completed ]

const hasUnlockedBudgetRange = (workHistory: GetContractorProfileWorkHistory[] | undefined | null, budgetRange: BudgetRange | undefined): boolean | undefined => {
  if (workHistory == null || budgetRange == null) return undefined

  const progressionStatus = workHistory
    .filter(doesWorkHistoryMatchBudgetRange(budgetRange))
    .reduce(reduceWorkHistoryToSingleProgressionStatus, ProgressionStatus.NotStarted)

  return unlockedBudgetRangeAcceptableStatuses.includes(progressionStatus)
}

export const getWorkHistoryStatus = (workHistory: GetContractorProfileWorkHistory) => {
  const hasVerifiedReference = workHistory.references?.some(reference => reference.status === ProgressionStatus.Completed )
  const isWaitingOnWeaver = workHistory.references?.some(reference => reference.status === ProgressionStatus.WaitingOnWeaver )
  const hasUploadedImages = workHistory.photos?.length ?? 0

  if (isWaitingOnWeaver && hasUploadedImages) return WorkHistoryStatus.WAITING

  return (hasVerifiedReference && hasUploadedImages) ? WorkHistoryStatus.VERIFIED : WorkHistoryStatus.IN_PROGRESS
}

export const getReferenceCount = (workHistory: GetContractorProfileWorkHistory) => {
  const referenceCount = workHistory.references?.length

  if (!referenceCount) return null

  return referenceCount > 1 ? `${referenceCount} references` : `1 reference`
}

export const getWorkHistoryTimeline = (workHistories: GetContractorProfileWorkHistory[] | undefined | null) => {
  if (!workHistories){
    return {
      sortedTimelineData: null,
      filteredWorkHistoryWithNoCompletedDate: null,
      yearsSortedByNewest: null,
    }
  }

  const nonArchivedWorkHistories = workHistories.filter((workHistory) => !workHistory.isArchived)
  const { sortedWorkHistoriesByCompletedDate, filteredWorkHistoryWithNoCompletedDate } = filterAndSortWorkHistoriesWithCompletedDate(nonArchivedWorkHistories)

  const sortedTimelineData = sortedWorkHistoriesByCompletedDate.reduce((acc, workHistory) => {
    const yearOfWork = DateTime.fromISO(workHistory.constructionCompletedDate).year

    if (yearOfWork && !acc[yearOfWork]) {
      return { ...acc, [yearOfWork]: [ workHistory ] }
    }

    return { ...acc, [yearOfWork]: [ ...acc[yearOfWork], workHistory ] }
  },{} as WorkHistoryTimeline)

  const yearsSortedByNewest = Object.keys(sortedTimelineData).sort((a,b) => Number(b) - Number(a))

  return { sortedTimelineData, filteredWorkHistoryWithNoCompletedDate, yearsSortedByNewest }
}

const filterAndSortWorkHistoriesWithCompletedDate = (workHistories: GetContractorProfileWorkHistory[]) => {
  const filteredWorkHistoryWithCompletedDate = workHistories.filter((workHistory): workHistory is WorkHistoryWithCompletedConstructionDate  => !!workHistory.constructionCompletedDate)

  const filteredWorkHistoryWithNoCompletedDate = workHistories.filter((workHistory): workHistory is WorkHistoryWithCompletedConstructionDate  => !workHistory.constructionCompletedDate)

  const sortedWorkHistoriesByCompletedDate = filteredWorkHistoryWithCompletedDate.sort((a,b) => (a.constructionCompletedDate.localeCompare(b.constructionCompletedDate)))

  return { sortedWorkHistoriesByCompletedDate, filteredWorkHistoryWithNoCompletedDate }
}

const getVerifiedWeaverProjects = (workHistory: GetContractorProfileWorkHistory[]) =>
  workHistory.filter(workHistory =>
    workHistory.references?.some(reference =>
      reference.status === ProgressionStatus.Completed ))

/**
 * Filter work history that is less than one year old
 *
 * We are intending only to collect month and year which
 * means we are not sure exactly the true completion day falls.
 * By limiting to less than 13 we are giving contractors the benefit of the doubt.
 */
const getRecentlyCompletedProjects = (workHistory: GetContractorProfileWorkHistory[]) => {
  const MONTHS_TOTAL = 13

  const todaysDateFormatted = DateTime.now()
  const completedDates = workHistory.map(item => DateTime.fromISO(item.constructionCompletedDate ?? ""))
  const durationSinceProjectsCompletionTillToday = completedDates.map(date => todaysDateFormatted.diff(date, [ 'months' ]).toObject())
  const projectsLessThanOneYearOld = durationSinceProjectsCompletionTillToday.filter(item => (item?.months !== undefined && item.months < MONTHS_TOTAL))

  return projectsLessThanOneYearOld
}

const getAllVerifiedReferences = (workHistory: GetContractorProfileWorkHistory[]) => {
  const allVerifiedReferences = workHistory.flatMap(history => history.references)
    .filter(reference => reference?.status === ProgressionStatus.Completed)

  return allVerifiedReferences
}

export const getWeaverStats = (workHistory: GetContractorProfileWorkHistory[] | undefined | null) => {

  const filteredNonArchivedWorkHistory = alwaysArray(workHistory).filter(workHistoryItem => !workHistoryItem.isArchived)

  const verifiedWeaverProjects = getVerifiedWeaverProjects(filteredNonArchivedWorkHistory)
  const recentlyCompletedProjects = getRecentlyCompletedProjects(filteredNonArchivedWorkHistory)
  const allVerifiedReferences = getAllVerifiedReferences(verifiedWeaverProjects)

  const pastProjectsCopy = !filteredNonArchivedWorkHistory.length
    ? `0 past projects`
    : filteredNonArchivedWorkHistory.length === 1
      ? `${filteredNonArchivedWorkHistory.length} past project`
      : `${filteredNonArchivedWorkHistory.length} past projects`

  const recentCompletedProjectCountCopy = !recentlyCompletedProjects.length
    ? null
    : recentlyCompletedProjects.length === 1
      ? `${recentlyCompletedProjects.length} project completed in the last 12 months`
      : `${recentlyCompletedProjects.length} projects completed in the last 12 months`

  const referenceCountCopy = !allVerifiedReferences.length
    ? null
    : allVerifiedReferences.length === 1
      ? `${allVerifiedReferences.length} verified reference`
      : `${allVerifiedReferences.length} verified references`

  return { pastProjectsCopy, recentCompletedProjectCountCopy, referenceCountCopy }
}
